import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { Subscription } from 'rxjs';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

export function requiredFileType(types: Array<string>): any {
	return (control: UntypedFormControl) => {
		const file = control.value;
		console.log(file);
		// @ts-ignore

		if (file && file[0]) {
			const extension = file[0].name.split('.')[1].toLowerCase();

			if (!types.includes(extension.toLowerCase())) {
				return {
					requiredFileType: true,
				};
			}

			return null;
		}

		return null;
	};
}

@Component({
	selector: 'app-import-user-dialog',
	templateUrl: './import-user-dialog.component.html',
	styleUrls: ['./import-user-dialog.component.css'],
})
export class ImportUserDialogComponent implements OnInit, OnDestroy {
	private subscription: Subscription | undefined;
	private API_URL_V2 = environment.API_URL;

	public form!: UntypedFormGroup;
	public isValidFormSubmitted: any | undefined;
	public error: any;
	public progress = 0;
	public id: any;

	faDown = faAngleDown;

	constructor(
		public dialogRef: MatDialogRef<ImportUserDialogComponent>,
		// @ts-ignore
		@Inject(MAT_DIALOG_DATA) data,
		private apiService: ApiService,
		private fb: UntypedFormBuilder,
		private errorHandler: ErrorHandlerService,
		private http: HttpClient,
		private snackBar: MatSnackBar
	) {
		this.id = data.id ? data.id : null;
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy() {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initForm(): void {
		this.form = this.fb.group({
			file: new UntypedFormControl(null, [Validators.required, requiredFileType(['csv'])]),
		});
	}

	submitForm(): void {
		this.isValidFormSubmitted = false;
		if (this.form.invalid) {
			return;
		}

		const formData = new FormData();
		const files = this.form.get('file').value;
		// @ts-ignore
		formData.append('attachment', files[0]);

		this.http
			.post(`${this.API_URL_V2}admin/conference/conferences/${this.id}/actions/import-attendees`, formData, {
				reportProgress: true,
				observe: 'events',
			})
			.subscribe(
				(event) => {
					if (event.type === HttpEventType.UploadProgress) {
						// @ts-ignore
						this.progress = Math.round((100 * event.loaded) / event.total);
					}

					if (event.type === HttpEventType.Response) {
					}
				},
				(err) => {
					this.errorHandler.errorHandler(err, 'Upload Attendees');
					this.error = err.error;
				},
				() => {
					this.openSnackBar('Teilnehmer werden importiert… Sie können diese Seite verlassen und zu einem späteren Zeitpunkt zurückkehren.');

					this.onChange(1);
					this.isValidFormSubmitted = true;
					this.form.reset();
				}
			);
	}

	onChange(id: number): void {
		this.dialogRef.close(id);
	}

	onClose(): void {
		this.dialogRef.close();
	}

	// Display Snackbar
	openSnackBar(message: string): any {
		this.snackBar.open(message, 'OK', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
		});
	}
}
