<div class="relative max-h-modal sm:w-full sm:max-w-lg">
	<form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()" novalidat>
		<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
			<div *ngIf="error" class="absolute left-0 right-0 top-0 rounded border border-solid border-red-500 bg-red-400 px-3 py-2 text-xs font-semibold text-white">
				<div class="mb-1 flex flex-row flex-wrap items-center">
					<fa-icon class="mr-1" [icon]="faBug"></fa-icon>
					<span class="font-semibold">Fehler:</span>
				</div>
				<p>{{ error.message }}</p>
				<code class="font-normal text-gray-300" *ngIf="error.errors">
					{{ error.errors | json }}
				</code>
			</div>

			<div *ngIf="isValidFormSubmitted" class="border-red-600 absolute left-0 right-0 top-0 rounded border border-solid bg-green-500 px-3 py-2 text-xs font-semibold text-white">
				<div class="flex flex-row flex-wrap items-center">
					<fa-icon class="mr-1" [icon]="faSuccess"></fa-icon>
					<span class="font-semibold">Datei erfolgreich hinzugefügt!</span>
				</div>
			</div>

			<div class="sticky top-0 z-10 w-full bg-white">
				<h3 class="mb-2 text-lg font-medium leading-6 text-gray-900" id="modal-title">Custom Element anlegen</h3>
			</div>
			<div class="relative max-h-modalInner overflow-y-auto overflow-x-hidden">
				<div class="rounded-md bg-gray-100 p-4">
					<ng-container *ngIf="element && element === 'abap_editor'">
						<h2 class="mb-4 text-base font-semibold text-gray-700">ABAP Editor (Demo)</h2>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="abap_editor_headline"> Überschrift </label>
							<input
								formControlName="abap_editor_headline"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
								id="abap_editor_headline"
								type="text"
								placeholder="Überschrift eintragen"
							/>
						</div>
					</ng-container>
					<ng-container *ngIf="element && element === 'conference'">
						<h2 class="mb-4 text-base font-semibold text-gray-700">Konferenz Banner</h2>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="conference_id"> Konferenz ID </label>
							<input
								formControlName="conference_id"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
								id="conference_id"
								type="text"
								placeholder="ID eintragen"
							/>
						</div>
					</ng-container>
					<ng-container *ngIf="element && element === 'commercial'">
						<h2 class="mb-4 text-base font-semibold text-gray-700">Werbe Banner</h2>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="commerical_text"> Text </label>
							<input
								formControlName="commerical_text"
								id="commerical_text"
								type="text"
								placeholder="Text eintragen"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							/>
						</div>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="commerical_btn_label"> Button Label </label>
							<input
								formControlName="commerical_btn_label"
								id="commerical_btn_label"
								type="text"
								placeholder="Label eintragen"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							/>
						</div>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="commerical_btn_url"> Button Url </label>
							<input
								formControlName="commerical_btn_url"
								id="commerical_btn_url"
								type="text"
								placeholder="Url eintragen"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							/>
						</div>
					</ng-container>
					<ng-container *ngIf="element && element === 'author'">
						<h2 class="mb-4 text-base font-semibold text-gray-700">Autoren gesucht Banner</h2>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="author_text"> Überschrift </label>
							<input
								formControlName="author_text"
								id="author_text"
								type="text"
								placeholder="Text eintragen"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							/>
						</div>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="author_btn_label"> Button Label </label>
							<input
								formControlName="author_btn_label"
								id="author_btn_label"
								type="text"
								placeholder="Label eintragen"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							/>
						</div>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="author_btn_url"> Button Url </label>
							<input
								formControlName="author_btn_url"
								id="author_btn_url"
								type="text"
								placeholder="Url eintragen"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							/>
						</div>
					</ng-container>
					<ng-container *ngIf="element && element === 'youtube'">
						<h2 class="mb-4 text-base font-semibold text-gray-700">Youtube Video</h2>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="youtube_headline"> Überschrift </label>
							<input
								formControlName="youtube_headline"
								id="youtube_headline"
								type="text"
								placeholder="Überschrift eintragen"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							/>
						</div>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="youtube_embed"> Embed ID </label>
							<input
								formControlName="youtube_embed"
								id="youtube_embed"
								type="text"
								placeholder="ID eintragen"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							/>
							<p class="mt-1 text-xs font-semibold italic text-gray-650">
								Die Video ID erhält man indem man auf Youtube im Video auf Teilen klickt, dort wie die Url angezeigt z.B. "https://youtu.be/AanxvqA5nRY" , die ID ist der Teil nach
								dem letzten / "AanxvqA5nRY".
							</p>
						</div>
					</ng-container>
					<ng-container *ngIf="element && element === 'vimeo'">
						<h2 class="mb-4 text-base font-semibold text-gray-700">Vimeo Video</h2>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="vimeo_headline"> Überschrift </label>
							<input
								formControlName="vimeo_headline"
								id="vimeo_headline"
								type="text"
								placeholder="Überschrift eintragen"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							/>
						</div>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="vimeo_embed"> Embed ID </label>
							<input
								formControlName="vimeo_embed"
								id="vimeo_embed"
								type="text"
								placeholder="ID eintragen"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							/>
							<p class="mt-1 text-xs font-semibold italic text-gray-650">
								Die Video ID erhält man indem man auf Vimeo im Video auf Teilen klickt, dort wie die Url angezeigt z.B. "https://vimeo.com/730181516" , die ID ist der Teil nach dem
								letzten / "730181516".
							</p>
						</div>
					</ng-container>
					<ng-container *ngIf="element && element === 'download'">
						<h2 class="mb-4 text-base font-semibold text-gray-700">Download Box</h2>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="download_text"> Text </label>
							<input
								formControlName="download_text"
								id="download_text"
								type="text"
								placeholder="Text eintragen"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							/>
						</div>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="download_btn_label"> Button Label </label>
							<input
								formControlName="download_btn_label"
								id="download_btn_label"
								type="text"
								placeholder="Label eintragen"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							/>
						</div>
						<div class="mb-6 w-full">
							<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-650" for="download_btn_url"> Button Url </label>
							<input
								formControlName="download_btn_url"
								id="download_btn_url"
								type="text"
								placeholder="Url eintragen"
								class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							/>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="sticky bottom-0 bg-white px-4 pb-4 pt-3 sm:flex sm:flex-row-reverse sm:px-6 sm:pb-6">
			<button
				type="submit"
				class="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
			>
				Element anlegen und kopieren
			</button>
			<div
				type="button"
				class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
				(click)="onClose()"
			>
				Abbrechen
			</div>
		</div>
	</form>
</div>
