<ng-container *transloco="let t">
	<div class="py- flex w-full flex-row flex-wrap items-center justify-between border-t border-solid border-gray-600 px-4 py-4" *ngIf="meta">
		<div class="flex-1">
			<div class="inline-flex w-auto flex-row rounded-md shadow">
				<button
					(click)="paginate('start')"
					*ngIf="meta.current_page > 1"
					class="flex h-9 w-9 appearance-none items-center justify-center rounded-l-md border border-r border-solid border-gray-300 bg-white leading-none transition-colors duration-200 ease-in-out hover:border-blue-500 hover:bg-blue-500 hover:text-white"
					title="{{ t('UI.first_page') }}"
				>
					<fa-icon class="h-4 w-4" [icon]="faStart"></fa-icon>
				</button>
				<button
					(click)="paginate('prev')"
					*ngIf="meta.current_page > 1"
					class="flex h-9 w-9 appearance-none items-center justify-center rounded-r-md border border-solid border-gray-300 bg-white leading-none transition-colors duration-200 ease-in-out hover:border-blue-500 hover:bg-blue-500 hover:text-white"
					title="{{ t('UI.prev_page') }}"
				>
					<fa-icon class="h-4 w-4" [icon]="faPrevious"></fa-icon>
				</button>
			</div>
		</div>
		<div class="flex w-full justify-center p-4 sm:w-auto sm:flex-1">
			<p
				class="text-center text-sm text-gray-700"
				[innerHTML]="
					t('UI.pagination', {
						from: meta.from,
						to: meta.to,
						total: meta.total,
					})
				"
			></p>
		</div>
		<div class="flex flex-1 flex-row justify-end">
			<div class="flex flex-row rounded-md shadow">
				<button
					(click)="paginate('next')"
					*ngIf="meta.current_page < meta.last_page"
					class="flex h-9 w-9 appearance-none items-center justify-center rounded-l-md border border-r border-solid border-gray-300 bg-white leading-none transition-colors duration-200 ease-in-out hover:border-blue-500 hover:bg-blue-500 hover:text-white"
					title="{{ t('UI.next_page') }}"
				>
					<fa-icon class="h-4 w-4" [icon]="faNext"></fa-icon>
				</button>
				<button
					(click)="paginate('end')"
					*ngIf="meta.current_page < meta.last_page"
					class="flex h-9 w-9 appearance-none items-center justify-center rounded-r-md border border-solid border-gray-300 bg-white leading-none transition-colors duration-200 ease-in-out hover:border-blue-500 hover:bg-blue-500 hover:text-white"
					title="{{ t('UI.last_page') }}"
				>
					<fa-icon class="h-4 w-4" [icon]="faEnd"></fa-icon>
				</button>
			</div>
		</div>
	</div>
</ng-container>
