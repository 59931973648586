import { Pipe, PipeTransform } from '@angular/core';
import {
	faCalendarAlt,
	faFileAlt,
	faFileArchive,
	faFileCsv,
	faFileExcel,
	faFileImage,
	faFileMusic,
	faFilePdf,
	faFilePowerpoint,
	faFileVideo,
	faFileWord,
} from '@fortawesome/pro-solid-svg-icons';

@Pipe({
	name: 'mimeTypeFallback',
})
export class MimeTypeFallbackPipe implements PipeTransform {
	faPdf = faFilePdf;
	faExcel = faFileExcel;
	faEbook = faFileAlt;
	faWord = faFileWord;
	faPowerPoint = faFilePowerpoint;
	faMusic = faFileMusic;
	faVideo = faFileVideo;
	faZip = faFileArchive;
	faCsv = faFileCsv;
	faCalendar = faCalendarAlt;
	faImage = faFileImage;

	transform(value: unknown, ...args: unknown[]): unknown {
		switch (value) {
			case 'pdf':
			case 'application/pdf':
				return this.faPdf;
			case 'application/msexcel':
				return this.faExcel;
			case 'application/vnd.openxmlformats-officedocument. spreadsheetml.sheet':
				return this.faExcel;
			case 'application/epub+zip':
				return this.faEbook;
			case 'application/msword':
				return this.faWord;
			case 'application/vnd.openxmlformats-officedocument. wordprocessingml.document':
				return this.faWord;
			case 'application/mspowerpoint':
				return this.faPowerPoint;
			case 'application/gzip':
				return this.faZip;
			case 'application/zip':
				return this.faZip;
			case 'video/mpeg':
			case 'video/mp4':
			case 'video/ogg':
			case 'video/quicktime':
			case 'video/vnd.vivo':
			case 'video/x-msvideo':
			case 'video/x-sgi-movie':
			case 'video/3gpp':
				return this.faVideo;
			case 'audio/basic':
			case 'audio/echospeech':
			case 'audio/mpeg':
			case 'audio/mp4':
			case 'audio/ogg':
			case 'audio/wav':
			case 'audio/x-mpeg':
			case 'audio/x-midi':
				return this.faMusic;
			case 'text/comma-separated-values':
				return this.faCsv;
			case 'text/calendar':
				return this.faCalendar;
			case 'image/bmp':
			case 'image/x-bmp':
			case 'image/x-ms-bmp':
			case 'image/gif':
			case 'image/jpeg':
			case 'image/png':
			case 'image/tiff':
			case 'image/svg+xml':
			case 'image/vnd.wap.wbmp':
			case 'image/x-icon':
				return this.faImage;
			default:
				return this.faEbook;
		}
	}
}
