import { Injectable } from '@angular/core';
import { DirtyQuery } from '../store/dirty/dirty.query';
import { DirtyStore } from '../store/dirty/dirty.store';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
	providedIn: 'root',
})
export class DirtyFormService {
	constructor(
		private dirtyQuery: DirtyQuery,
		private dirtyStore: DirtyStore
	) {}

	setFormDirty(form: UntypedFormGroup): void {
		form.valueChanges.subscribe((val) => {
			const isEmpty = this.check(val);
			if (isEmpty) {
				this.dirtyStore.update({ dirty: false });
				return;
			}

			this.dirtyStore.update({ dirty: true });
		});
	}

	setFormClean(): void {
		this.dirtyStore.update({ dirty: false });
	}

	check(object): any {
		return Object.values(object).every((v) => (v && typeof v === 'object' ? this.check(v) : v === '' || v === null));
	}
}
