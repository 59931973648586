import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { DirtyQuery } from '../store/dirty/dirty.query';
import { MatDialog } from '@angular/material/dialog';
import { LeaveDialogComponent } from '../dialogs/leave-dialog/leave-dialog.component';
import { DirtyFormService } from '../services/dirty-form.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DirtyCheckGuard {
	constructor(
		private dirtyQuery: DirtyQuery,
		public dialog: MatDialog,
		private dirtyForm: DirtyFormService
	) {}

	canDeactivate(component: any, route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
		const isDirty = this.dirtyQuery.isDirty();

		if (isDirty === false) {
			this.dirtyForm.setFormClean();
			return true;
		}

		const dialogRef = this.dialog.open(LeaveDialogComponent, {
			minWidth: '280px',
		});
		return dialogRef.afterClosed().pipe(
			map((data) => {
				if (!data) {
					return false;
				} else {
					this.dirtyForm.setFormClean();
					return true;
				}
			})
		);
	}
}
