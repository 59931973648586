import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { SessionQuery } from './state';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard {
	constructor(
		private router: Router,
		private sessionQuery: SessionQuery
	) {}

	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const roles = this.sessionQuery.hasRoles();
		let hasRole = false;

		if (this.sessionQuery.isLoggedIn()) {
			if (next.data.role) {
				if (roles && roles.length > 0) {
					hasRole = next.data.role.some((item) => roles.includes(item));
				}

				if (hasRole) {
					return true;
				} else {
					this.router
						.navigate(['/dashboard/not-allowed'], {
							queryParams: { returnUrl: state.url },
						})
						.then();
					return true;
				}
			} else {
				return true;
			}
		} else {
			this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } }).then();
			return false;
		}
	}
}
