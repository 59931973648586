<div class="file-drop-area">
	<span class="fake-btn">Datei wählen</span>
	<span class="file-msg">{{ fileArr ? fileArr.join(', ') : 'oder per drag and drop hinzufügen' }}</span>
	<input class="file-input" type="file" multiple />
</div>
<ng-container *ngIf="format">
	<p class="py-1 text-xs text-gray-650">{{ format }}</p>
</ng-container>
<p class="py-1 text-xs text-gray-650" *ngIf="showNote">
	Hinweis: Es können maximal 100 Dateien auf einmal hochgeladen werden. Bitte immer nur Dateien mit korrekter Dateiendung (z.B. .jpg, .pdf) hochladen.
</p>
<ng-container *ngIf="error">
	<p class="my-1 rounded-md bg-red-500 p-4 text-xs font-semibold text-white">Max. Dateigröße von {{ size | filesize }} überschritten.</p>
</ng-container>
