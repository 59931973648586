<ng-container *transloco="let t">
	<div class="relative max-h-modal min-h-modal overflow-y-hidden sm:w-full sm:max-w-lg">
		<form *ngIf="form" [formGroup]="form" (ngSubmit)="submit()" novalidate>
			<div class="min-h-modal overflow-y-hidden bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
				<div class="sticky top-0 z-10 w-full bg-white">
					<h3 class="mb-4 text-lg font-medium leading-6 text-gray-900" id="modal-title">
						{{ t('UI.add_subscription') }}
					</h3>
				</div>
				<div class="relative max-h-modalInner min-h-modal overflow-x-hidden overflow-y-hidden">
					<div class="mb-2 w-full">
						<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700" for="folderGroups"> Subscription Plans </label>
						<ng-container *ngIf="folderGroupsData">
							<p-multiSelect
								[options]="folderGroupsData"
								id="folderGroups"
								formControlName="folderGroups"
								placeholder="{{ t('UI.select_subscription_plan') }}"
								optionLabel="itemName"
								optionValue="id"
								display="chip"
								selectionLimit="1"
								[virtualScroll]="true"
								[virtualScrollItemSize]="43"
							></p-multiSelect>
						</ng-container>
					</div>
					<div class="w-full">
						<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700" for="expiresAt">
							{{ t('UI.expiration_date') }}
						</label>
						<input
							formControlName="expiresAt"
							class="mb-3 block w-full appearance-none rounded border border-gray-300 bg-gray-300 px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
							id="expiresAt"
							type="datetime-local"
							placeholder="Ablaufdatum eintragen"
						/>
						<p class="text-xs italic text-gray-600" [innerHTML]="t('UI.date_info')"></p>
					</div>
				</div>
			</div>
			<div class="sticky bottom-0 bg-white px-4 pb-4 pt-3 sm:flex sm:flex-row-reverse sm:px-6 sm:pb-6">
				<button
					type="submit"
					class="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
				>
					{{ t('UI.save') }}
				</button>
				<div
					typeof="button"
					class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
					(click)="onClose()"
				>
					{{ t('UI.cancel') }}
				</div>
			</div>
		</form>
	</div>
</ng-container>
