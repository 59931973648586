import { Component, OnInit } from '@angular/core';
import {
	faArrowsRepeat1,
	faChartSimple,
	faEnvelope,
	faGraduationCap,
	faNetworkWired,
	faPhotoVideo,
	faShoppingCart,
	faUserEdit,
	faUserFriends,
	faUsers,
} from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'app-apps',
	templateUrl: './apps.component.html',
	styleUrls: ['./apps.component.scss'],
})
export class AppsComponent implements OnInit {
	faNetwork = faNetworkWired;
	faCustomer = faUserFriends;
	faUsers = faUsers;
	faAuthor = faUserEdit;
	faCart = faShoppingCart;
	faMedia = faPhotoVideo;
	faMail = faEnvelope;
	faLearning = faGraduationCap;
	faSubscriptions = faArrowsRepeat1;
	faAnalytics = faChartSimple;

	public nav: Array<any>;

	constructor() {}

	ngOnInit(): void {
		this.initNav();
	}

	initNav(): void {
		this.nav = [
			{
				id: 1,
				name: 'Produkte',
				path: '/dashboard/products',
				icon: this.faCart,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
			},
			{
				id: 2,
				name: 'Lernpfade',
				path: '/dashboard/learning-path',
				icon: this.faLearning,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
			},
			{
				id: 3,
				name: 'Benutzer',
				path: '/dashboard/users',
				icon: this.faUsers,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
			},
			{
				id: 4,
				name: 'Subscription Plans',
				path: '/dashboard/plans',
				icon: this.faSubscriptions,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin'],
			},
			{
				id: 5,
				name: 'Analytics',
				path: '/dashboard/analytics',
				icon: this.faAnalytics,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin'],
			},
			{
				id: 6,
				name: 'Infoday',
				path: '/dashboard/infoday',
				icon: this.faNetwork,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin'],
			},
			{
				id: 7,
				name: 'Kunden',
				path: '/dashboard/customer',
				icon: this.faCustomer,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
			},
			{
				id: 8,
				name: 'Autoren',
				path: '/dashboard/authors',
				icon: this.faAuthor,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
			},
			{
				id: 9,
				name: 'Medien',
				path: '/dashboard/media',
				icon: this.faMedia,
				active: 'bg-blue-600',
				roles: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
			},
		];
	}
}
