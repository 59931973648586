<div class="relative max-h-modal sm:w-full sm:max-w-lg">
	<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
		<div class="sticky top-0 z-10 w-full bg-white">
			<h3 class="mb-2 text-lg font-medium leading-6 text-gray-900" id="modal-title">Subscription Plan wählen</h3>
			<input
				#search
				type="text"
				class="mb-3 block w-full appearance-none rounded border border-gray-500 bg-gray-300 px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
				placeholder="Medien suchen"
			/>
			<div class="flex w-full flex-row items-center bg-gray-200">
				<div class="imge-content flex flex-row items-center">
					<div class="px-4 py-2 text-xs leading-tight text-gray-650">ID</div>
					<div class="p-2 text-xs leading-tight text-gray-650">Name</div>
				</div>
			</div>
		</div>
		<div class="relative max-h-modalInner overflow-y-auto overflow-x-hidden">
			<div class="flex w-full flex-row flex-wrap" *ngIf="data">
				<cdk-virtual-scroll-viewport itemSize="35" class="user divide-y divide-gray-400 bg-white">
					<div class="user__item" *cdkVirtualFor="let element of data; trackBy: trackByFn">
						<div class="cursor-pointer whitespace-nowrap px-4 py-2 text-xs text-gray-800 hover:text-blue-500" title="Plan wählen" (click)="selectPlan(element.id)">
							{{ element.id }} - {{ element.name }}
						</div>
					</div>
				</cdk-virtual-scroll-viewport>
				<div class="flex w-full flex-row flex-wrap items-center justify-between pb-4" *ngIf="meta">
					<div>
						<button (click)="pagination('prev')" *ngIf="meta.current_page > 1" class="flex h-8 w-8 appearance-none items-center justify-center" title="Vorherige Pläne">
							<fa-icon class="w-4-h4" [icon]="faPrevious"></fa-icon>
						</button>
					</div>
					<div class="p-4">
						<p class="text-xs">
							Pläne: <span class="font-bold">{{ meta.from }}</span> - <span class="font-bold">{{ meta.to }}</span> | Gesamt:
							<span class="font-bold">{{ meta.total }}</span>
						</p>
					</div>
					<div>
						<button (click)="pagination('next')" *ngIf="meta.current_page < meta.last_page" class="flex h-8 w-8 appearance-none items-center justify-center" title="Weitere Pläne">
							<fa-icon class="w-4-h4" [icon]="faNext"></fa-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="sticky bottom-0 bg-white px-4 pb-4 pt-3 sm:flex sm:flex-row-reverse sm:px-6 sm:pb-6">
		<button
			type="button"
			class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
			(click)="onClose()"
		>
			Abbrechen
		</button>
	</div>
</div>
