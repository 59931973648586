<ng-container *transloco="let t">
	<div class="relative max-h-modal w-full">
		<form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
			<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
				<div *ngIf="error" class="absolute left-0 right-0 top-0 rounded border border-solid border-red-500 bg-red-400 px-3 py-2 text-xs font-semibold text-white">
					<div class="mb-1 flex flex-row flex-wrap items-center">
						<fa-icon class="mr-1" [icon]="faBug"></fa-icon>
						<span class="font-semibold">{{ t('UI.error') }}:</span>
					</div>
					<p>{{ error.message }}</p>
					<code class="font-normal text-gray-300" *ngIf="error.errors">
						{{ error.errors | json }}
					</code>
				</div>

				<div *ngIf="isValidFormSubmitted" class="border-red-600 absolute left-0 right-0 top-0 rounded border border-solid bg-green-500 px-3 py-2 text-xs font-semibold text-white">
					<div class="flex flex-row flex-wrap items-center">
						<fa-icon class="mr-1" [icon]="faSuccess"></fa-icon>
						<span class="font-semibold">{{ t('UI.added_file_success') }}</span>
					</div>
				</div>

				<div class="sticky top-0 z-10 w-full bg-white">
					<h3 class="mb-2 text-lg font-medium leading-6 text-gray-900" id="modal-title">
						{{ t('UI.upload_file') }}
					</h3>
				</div>
				<div class="relative max-h-modalInner overflow-y-auto overflow-x-hidden">
					<div class="rounded-md bg-gray-100 p-4">
						<div class="mb-6 w-full">
							<p class="mb-1 text-gray-600" appAccessControl [accessRole]="['Super Admin', 'Admin']">
								<a class="text-xs font-medium text-gray-600 hover:text-blue-500" routerLink="/dashboard/help/attachments"
									><fa-icon [icon]="faHelp"></fa-icon> Hier finden Sie alle zulässigen Dateiformate.</a
								>
							</p>
							<app-file-upload formControlName="image" [progress]="0" [format]="format" [size]="size" [showNote]="multiUpload"></app-file-upload>
						</div>
						<div class="flex flex-row flex-wrap">
							<div class="mb-6 w-full">
								<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700" for="visibility">
									{{ t('UI.visibility') }}<sup class="text-red-500">*</sup>
								</label>
								<select
									formControlName="visibility"
									id="visibility"
									class="mb-3 block w-full cursor-pointer appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
								>
									<option value="public">{{ t('UI.public') }}</option>
									<option value="private">{{ t('UI.private') }}</option>
									<option value="hidden">{{ t('UI.hidden') }}</option>
								</select>
								<div
									*ngIf="visibility.errors && isValidFormSubmitted != null && !isValidFormSubmitted"
									class="rounded border border-solid border-red-500 bg-red-400 px-2 py-1 text-xs font-semibold text-white"
								>
									{{ t('UI.form_field_error') }}
								</div>
							</div>
							<div class="mb-6 w-full">
								<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700" for="name"> Name </label>
								<input
									formControlName="name"
									class="mb-3 block w-full appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
									id="name"
									type="text"
									placeholder="Bildname eintragen"
								/>
								<p class="mt-1 text-xs text-gray-650">
									{{ t('UI.file_name_note') }}
								</p>
							</div>
							<div class="mb-6 w-full">
								<div>
									<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700" for="folder">
										{{ t('UI.dir') }}
									</label>
									<ng-container *ngIf="folders && folders.length; else loadingFolders">
										<select
											formControlName="folder"
											id="folder"
											class="mb-3 block w-full cursor-pointer appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
										>
											<option value="" class="opacity-75" selected>
												{{ t('UI.select_dir') }}
											</option>
											<ng-container *ngIf="folders">
												<option *ngFor="let folder of folders" [value]="folder.id">
													{{ folder.name }}
												</option>
											</ng-container>
										</select>
									</ng-container>
									<ng-template #loadingFolders>
										<div class="flex flex-row items-center text-blue-500">
											<fa-icon [icon]="faSpinner" spin="true"></fa-icon>
											<span class="ml-2 text-sm font-semibold text-gray-650">{{ t('UI.loading_folders') }}</span>
										</div>
									</ng-template>
								</div>
							</div>
							<div class="mb-6 w-full">
								<div appAccessControl [accessRole]="['Super Admin', 'Admin']">
									<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700" for="partner"> Partner </label>
									<select
										formControlName="partner"
										id="partner"
										class="mb-3 block w-full cursor-pointer appearance-none rounded border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
									>
										<option value="" class="opacity-75" selected>Partner wählen</option>
										<ng-container *ngIf="partner">
											<option [value]="part.id" *ngFor="let part of partner">
												{{ part.name }}
											</option>
										</ng-container>
									</select>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="sticky bottom-0 bg-white px-4 pb-4 pt-3 sm:flex sm:flex-row-reverse sm:px-6 sm:pb-6">
				<button
					type="submit"
					class="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
				>
					<ng-container *ngIf="isUploading; else noUpload">
						<i class="mr-2"><fa-icon class="animate-spin" [icon]="faSpinner"></fa-icon></i>
						<span>{{ t('UI.file_is_uploading') }}</span>
					</ng-container>
					<ng-template #noUpload>
						<span>{{ t('UI.start_upload') }}</span>
					</ng-template>
				</button>
				<div
					typeof="button"
					class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
					(click)="onClose()"
				>
					{{ t('UI.cancel') }}
				</div>
			</div>
		</form>
	</div>
</ng-container>
