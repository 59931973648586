import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { faArrowLeft, faArrowRight, faCheckCircle, faPlusCircle, faTimesCircle, faUser } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'app-select-account-dialog',
	templateUrl: './select-account-dialog.component.html',
	styleUrls: ['./select-account-dialog.component.css'],
})
export class SelectAccountDialogComponent implements OnInit, OnDestroy, AfterViewInit {
	private subscription: Subscription;
	private subscriptionSearch: Subscription;

	public id: number;
	public title: string;

	public loading: boolean;
	public data: Array<any>;
	public meta: any;
	public links: any;
	public showRoles: boolean = false;
	public selectedRoles: Array<string> = [];
	public ids: Array<number> = [];
	public accounts: any;

	faNext = faArrowRight;
	faPrevious = faArrowLeft;
	faUser = faUser;
	faInactive = faTimesCircle;
	faActive = faCheckCircle;
	faAdd = faPlusCircle;

	@ViewChild('search') search: ElementRef;

	constructor(
		public dialogRef: MatDialogRef<SelectAccountDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data: any,
		private apiService: ApiService
	) {
		this.showRoles = data?.showRoles ? data.showRoles : false;
		this.accounts = data?.accounts ? data.accounts : [];
		if (this.accounts.length > 0) {
			this.ids = this.accounts.map((x: any) => x.id);
		}
	}

	ngOnInit(): void {
		this.initData(this.initDataParams());
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		if (this.subscriptionSearch) {
			this.subscriptionSearch.unsubscribe();
		}
	}

	ngAfterViewInit(): void {
		this.searchData();
	}

	initDataParams(): string {
		return `?page=1&include=partners`;
	}

	// Get data
	initData(page: string): void {
		this.subscription = this.apiService.getData(`accounts/select${page}`).subscribe(
			(res) => {
				this.data = res.data;
				this.meta = res.meta;
				this.links = res.links;
			},
			(err) => {
				console.log(err);
			},
			() => {}
		);
	}

	// Search data
	searchData(): void {
		this.subscriptionSearch = fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				filter(Boolean),
				debounceTime(250),
				distinctUntilChanged(),
				tap(() => {
					this.initData(`?page=1&search=${this.search.nativeElement.value}`);
				})
			)
			.subscribe();
	}

	// Get new data by page
	pagination(type: string): void {
		if ('prev' === type) {
			const newPage = this.meta.current_page - 1;
			if (newPage < 1) {
				return;
			}
			this.initData(`?page=${newPage}`);
		} else if ('next' === type) {
			const newPage = this.meta.current_page + 1;
			if (newPage >= this.meta.total) {
				return;
			}
			this.initData(`?page=${newPage}`);
		} else if ('start' === type) {
			const newPage = 1;
			this.initData(`?page=${newPage}`);
		} else {
			const newPage = this.meta.last_page;
			this.initData(`?page=${newPage}`);
		}
	}

	selectAccount(id: any, givenName: string, familyName: string, email: string, index?: number): void {
		let isInArray = false;
		if (this.ids.length) {
			if (this.ids.includes(id)) {
				isInArray = true;
			}
		}

		if (this.showRoles && index !== undefined) {
			const data = document.getElementById('user-' + index);
			const roles = [].slice.call(data.querySelectorAll('input[type="radio"]:checked'));
			if (roles.length > 0) {
				// @ts-ignore
				const selectedRoles = Array.from(roles).map((x) => x.value);
				if (selectedRoles.length > 0) {
					this.selectedRoles = selectedRoles;
				}
			}
			this.onSave({
				id,
				givenName,
				familyName,
				email,
				isInArray,
				selectedRoles: this.selectedRoles,
			});
		} else {
			this.onSave({ id, givenName, familyName, email, isInArray });
		}
	}

	onSave(data: any): void {
		this.dialogRef.close(data);
	}

	onClose(): void {
		this.dialogRef.close();
	}

	// Performance improvement
	trackByFn(index, item): number {
		return index;
	}
}
