import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-calculate-logistics-dialog',
	templateUrl: './calculate-logistics-dialog.component.html',
	styleUrls: ['./calculate-logistics-dialog.component.css'],
})
export class CalculateLogisticsDialogComponent implements OnInit {
	public origin: string;
	public date: string;

	constructor(
		public dialogRef: MatDialogRef<CalculateLogisticsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data
	) {}

	ngOnInit(): void {}

	onDelete(date: string, costs: string): void {
		if (!date || !costs) {
			return;
		}
		this.dialogRef.close({ date, costs });
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
