import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from '../../shared/session/session-guard';
import { DirtyCheckGuard } from '../../shared/guard/dirty.check-guard.guard';

export const routes: Routes = [
	{
		path: '',
		component: DashboardComponent,
		children: [
			{
				path: '',
				redirectTo: 'overview',
				pathMatch: 'full',
			},
			{
				path: 'overview',
				loadChildren: () => import('../../pages/overview/overview.module').then((m) => m.OverviewModule),
				canActivate: [AuthGuard],
				title: 'Dashboard - PLM – Espresso Tutorials GmbH',
			},
			{
				path: 'infoday',
				loadChildren: () => import('../../pages/infoday/infoday.module').then((m) => m.InfodayModule),
				canActivate: [AuthGuard],
				title: 'Infoday - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Konferenzen',
					},
				},
			},
			{
				path: 'infoday/add-conference',
				loadChildren: () => import('../../pages/infoday/add-conference/add-conference.module').then((m) => m.AddConferenceModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Konferenz hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Konferenz hinzufügen',
					},
				},
			},
			{
				path: 'infoday/edit-conference/:id',
				loadChildren: () => import('../../pages/infoday/edit-conference/edit-conference.module').then((m) => m.EditConferenceModule),
				canActivate: [AuthGuard],
				title: 'Konferenz bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Konferenz bearbeiten',
					},
				},
			},
			{
				path: 'infoday/detail-conference/:id',
				loadChildren: () => import('../../pages/infoday/detail-conference/detail-conference.module').then((m) => m.DetailConferenceModule),
				canActivate: [AuthGuard],
				title: 'Konferenz Details - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Konferenz Details',
					},
				},
			},
			{
				path: 'infoday/sessions/add-session',
				loadChildren: () => import('../../pages/infoday/sessions/add-session/add-session.module').then((m) => m.AddSessionModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Vortrag hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Vortrag hinzufügen',
					},
				},
			},
			{
				path: 'infoday/sessions/add-session/:conference',
				loadChildren: () => import('../../pages/infoday/sessions/add-session/add-session.module').then((m) => m.AddSessionModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Vortrag hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Vortrag hinzufügen',
					},
				},
			},
			{
				path: 'infoday/sessions/edit-session/:id/:conference',
				loadChildren: () => import('../../pages/infoday/sessions/edit-session/edit-session.module').then((m) => m.EditSessionModule),
				canActivate: [AuthGuard],
				title: 'Vortrag bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Vortrag bearbeiten',
					},
				},
			},
			{
				path: 'infoday/sessions/rating/:id/:conference',
				loadChildren: () => import('../../pages/infoday/sessions/rating/rating.module').then((m) => m.RatingModule),
				canActivate: [AuthGuard],
				title: 'Vortrag Bewertungen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Vortrag Bewertungen',
					},
				},
			},
			{
				path: 'infoday/sponsors/add-sponsor',
				loadChildren: () => import('../../pages/infoday/sponsors/sponsor-add/sponsor-add.module').then((m) => m.SponsorAddModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Sponsor hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Sponsor hinzufügen',
					},
				},
			},
			{
				path: 'infoday/sponsors/add-sponsor/:conference',
				loadChildren: () => import('../../pages/infoday/sponsors/sponsor-add/sponsor-add.module').then((m) => m.SponsorAddModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Sponsor hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Sponsor hinzufügen',
					},
				},
			},
			{
				path: 'infoday/sponsors/edit-sponsor/:id/:conference',
				loadChildren: () => import('../../pages/infoday/sponsors/sponsor-edit/sponsor-edit.module').then((m) => m.SponsorEditModule),
				canActivate: [AuthGuard],
				title: 'Sponsor bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Sponsor bearbeiten',
					},
				},
			},
			{
				path: 'infoday/sponsors/detail-sponsor/:id/:conference',
				loadChildren: () => import('../../pages/infoday/sponsors/detail-sponsor/detail-sponsor.module').then((m) => m.DetailSponsorModule),
				canActivate: [AuthGuard],
				title: 'Sponsor Details - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
				},
			},
			{
				path: 'infoday/conference/notification',
				loadChildren: () => import('../infoday/notifications/notification.module').then((m) => m.NotificationModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Call to Action - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Call to Actions',
					},
				},
			},
			{
				path: 'infoday/conference/notification/:id',
				loadChildren: () => import('../infoday/notifications/notification.module').then((m) => m.NotificationModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Call to Action - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Call to Action',
					},
				},
			},
			{
				path: 'infoday/sponsors/contact-person',
				loadChildren: () => import('../../pages/infoday/sponsors/contact-person/contact-person.module').then((m) => m.ContactPersonModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Kontaktpersonen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kontaktpersonen',
					},
				},
			},
			{
				path: 'infoday/sponsors/contact-person/add-contact-person',
				loadChildren: () => import('../../pages/infoday/sponsors/contact-person/contact-person-add/contact-person-add.module').then((m) => m.ContactPersonAddModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Kontakt hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kontaktperson hinzufügen',
					},
				},
			},
			{
				path: 'infoday/sponsors/contact-person/add-contact-person/:sponsor',
				loadChildren: () => import('../../pages/infoday/sponsors/contact-person/contact-person-add/contact-person-add.module').then((m) => m.ContactPersonAddModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Kontakt hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kontaktperson hinzufügen',
					},
				},
			},
			{
				path: 'infoday/sponsors/contact-person/edit-contact-person/:id',
				loadChildren: () => import('../../pages/infoday/sponsors/contact-person/contact-person-edit/contact-person-edit.module').then((m) => m.ContactPersonEditModule),
				canActivate: [AuthGuard],
				title: 'Kontakt bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kontaktperson bearbeiten',
					},
				},
			},
			{
				path: 'infoday/sponsors/contact-person/edit-contact-person/:id/:sponsor/:conference',
				loadChildren: () => import('../../pages/infoday/sponsors/contact-person/contact-person-edit/contact-person-edit.module').then((m) => m.ContactPersonEditModule),
				canActivate: [AuthGuard],
				title: 'Kontakt bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kontaktperson bearbeiten',
					},
				},
			},
			{
				path: 'infoday/stats/:type/:id',
				loadChildren: () => import('../../pages/infoday/stats/stats.module').then((m) => m.StatsModule),
				canActivate: [AuthGuard],
				title: 'Statistiken - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Statistiken',
					},
				},
			},
			{
				path: 'infoday/conference-landing-pages',
				loadChildren: () => import('../../pages/infoday/landingpage-conference/landingpage-conference.module').then((m) => m.LandingpageConferenceModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Landingpages - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Landingpages',
					},
				},
			},
			{
				path: 'infoday/conference-landing-pages/add-landing-page',
				loadChildren: () =>
					import('../../pages/infoday/landingpage-conference/add-landingpage-conference/add-landingpage-conference.module').then((m) => m.AddLandingpageConferenceModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Landingpage hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Landingpage hinzufügen',
					},
				},
			},
			{
				path: 'infoday/conference-landing-pages/edit-landing-page/:id',
				loadChildren: () =>
					import('../../pages/infoday/landingpage-conference/edit-landingpage-conference/edit-landingpage-conference.module').then((m) => m.EditLandingpageConferenceModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Landingpage bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Landingpage bearbeiten',
					},
				},
			},
			{
				path: 'partner',
				loadChildren: () => import('../../pages/partner/partner.module').then((m) => m.PartnerModule),
				canActivate: [AuthGuard],
				title: 'Partner - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Partner',
					},
				},
			},
			{
				path: 'partner/detail-partner/:id',
				loadChildren: () => import('../../pages/partner/detail-partner/detail-partner.module').then((m) => m.DetailPartnerModule),
				canActivate: [AuthGuard],
				title: 'Partner Details - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Partner Details',
					},
				},
			},
			{
				path: 'partner/edit-partner/:id',
				loadChildren: () => import('../../pages/partner/edit-partner/edit-partner.module').then((m) => m.EditPartnerModule),
				canActivate: [AuthGuard],
				title: 'Partner bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Partner Details',
					},
				},
			},
			{
				path: 'partner/add-partner',
				loadChildren: () => import('../../pages/partner/add-partner/add-partner.module').then((m) => m.AddPartnerModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Partner hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Partner hinzufügen',
					},
				},
			},
			{
				path: 'customer',
				loadChildren: () => import('../../pages/customer/customer.module').then((m) => m.CustomerModule),
				canActivate: [AuthGuard],
				title: 'Kunden - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
					breadcrumb: {
						label: 'Kunden',
					},
				},
			},
			{
				path: 'customer/add-customer',
				loadChildren: () => import('../../pages/customer/add-customer/add-customer.module').then((m) => m.AddCustomerModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Kunde hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
					breadcrumb: {
						label: 'Kunde hinzufügen',
					},
				},
			},
			{
				path: 'customer/edit-customer/:id',
				loadChildren: () => import('../../pages/customer/edit-customer/edit-customer.module').then((m) => m.EditCustomerModule),
				canActivate: [AuthGuard],
				title: 'Kunde bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
					breadcrumb: {
						label: 'Kunde bearbeiten',
					},
				},
			},
			{
				path: 'customer/own-content',
				loadChildren: () => import('../../pages/customer/own-content/own-content.module').then((m) => m.OwnContentModule),
				canActivate: [AuthGuard],
				title: 'Kundeneigene Inhalte - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
					breadcrumb: {
						label: 'Kundeneigene Inhalte',
					},
				},
			},
			{
				path: 'customer/own-content/:id',
				loadChildren: () => import('../../pages/customer/own-content/own-content-details/own-content-details.module').then((m) => m.OwnContentDetailsModule),
				canActivate: [AuthGuard],
				title: 'Kundeneigener Inhalt - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
					breadcrumb: {
						label: 'Kundeneigener Inhalt',
					},
				},
			},
			{
				path: 'push-notifications',
				loadChildren: () => import('../../pages/push-notifications/push-notifications.module').then((m) => m.PushNotificationsModule),
				canActivate: [AuthGuard],
				title: 'Push Notifications - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Push Notifications',
					},
				},
			},
			{
				path: 'push-notifications/add',
				loadChildren: () => import('../../pages/push-notifications/add-push/add-push.module').then((m) => m.AddPushModule),
				canActivate: [AuthGuard],
				title: 'Anlegen - Push Notification - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Anlegen',
					},
				},
			},
			{
				path: 'push-notifications/edit/:id',
				loadChildren: () => import('../../pages/push-notifications/edit-push/edit-push.module').then((m) => m.EditPushModule),
				canActivate: [AuthGuard],
				title: 'Bearbeiten - Push Notification - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Bearbeiten',
					},
				},
			},
			{
				path: 'settings',
				loadChildren: () => import('../../pages/settings/settings.module').then((m) => m.SettingsModule),
				canActivate: [AuthGuard],
				title: 'Einstellungen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
					breadcrumb: {
						label: 'Einstellungen',
					},
				},
			},
			{
				path: 'settings/isbn',
				loadChildren: () => import('../../pages/settings/isbn/isbn.module').then((m) => m.IsbnModule),
				canActivate: [AuthGuard],
				title: 'ISBN - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'ISBN',
					},
				},
			},
			{
				path: 'settings/feedback',
				loadChildren: () => import('../../pages/settings/feedback/feedback.module').then((m) => m.FeedbackModule),
				canActivate: [AuthGuard],
				title: 'Umfragen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Umfragen',
					},
				},
			},
			{
				path: 'settings/feedback/detail/:id',
				loadChildren: () => import('../../pages/settings/feedback/detail-feedback/detail-feedback.module').then((m) => m.DetailFeedbackModule),
				canActivate: [AuthGuard],
				title: 'Umfrage - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Umfrage',
					},
				},
			},
			{
				path: 'settings/feedback/add-feedback',
				loadChildren: () => import('../../pages/settings/feedback/add-feedback/add-feedback.module').then((m) => m.AddFeedbackModule),
				canActivate: [AuthGuard],
				title: 'Umfrage erstellen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Umfrage erstellen',
					},
				},
			},
			{
				path: 'settings/2fa',
				loadChildren: () => import('../../pages/settings/two-factor-user/two-factor-user.module').then((m) => m.TwoFactorUserModule),
				canActivate: [AuthGuard],
				title: 'Zwei-Faktor-Authentifizierung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: '2FA',
					},
				},
			},
			{
				path: 'settings/categories',
				loadChildren: () => import('../../pages/settings/categories/categories.module').then((m) => m.CategoriesModule),
				canActivate: [AuthGuard],
				title: 'Kategorien - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kategorien',
					},
				},
			},
			{
				path: 'settings/roles',
				loadChildren: () => import('../../pages/settings/roles/roles.module').then((m) => m.RolesModule),
				canActivate: [AuthGuard],
				title: 'Rollen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin'],
					breadcrumb: {
						label: 'Rollen',
					},
				},
			},
			{
				path: 'settings/roles/edit-role/:id',
				loadChildren: () => import('../../pages/settings/roles/edit-role/edit-role.module').then((m) => m.EditRoleModule),
				canActivate: [AuthGuard],
				title: 'Rolle beabreiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin'],
					breadcrumb: {
						label: 'Rolle bearbeiten',
					},
				},
			},
			{
				path: 'settings/roles/add-role',
				loadChildren: () => import('../../pages/settings/roles/add-role/add-role.module').then((m) => m.AddRoleModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Rolle hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin'],
					breadcrumb: {
						label: 'Rolle hinzufügen',
					},
				},
			},
			/*
      {
        path: 'settings/permissions',
        loadChildren: () => import('../../pages/settings/permissions/permissions.module').then(m => m.PermissionsModule),
        canActivate: [ AuthGuard ],
        title: 'Berechtigungen - PLM – Espresso Tutorials GmbH',
        data: {
          role: [
            'Super Admin',
          ],
          breadcrumb: {
            label: 'Berechtigungen'
          }
        },
      },
      {
        path: 'settings/permissions/add-permission',
        loadChildren: () => import('../../pages/settings/permissions/add-permission/add-permission.module').then(m => m.AddPermissionModule),
        canActivate: [ AuthGuard ],
        canDeactivate: [ DirtyCheckGuard ],
        title: 'Berechtigung hinzufügen - PLM – Espresso Tutorials GmbH',
        data: {
          role: [
            'Super Admin',
          ],
          breadcrumb: {
            label: 'Berechtigung hinzufügen'
          }
        },
      },
      */
			{
				path: 'settings/languages',
				loadChildren: () => import('../../pages/settings/languages/languages.module').then((m) => m.LanguagesModule),
				canActivate: [AuthGuard],
				title: 'Sprachen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Sprachen',
					},
				},
			},
			{
				path: 'settings/languages/add-language',
				loadChildren: () => import('../../pages/settings/languages/add-language/add-language.module').then((m) => m.AddLanguageModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Sprache hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin'],
					breadcrumb: {
						label: 'Sprache hinzufügen',
					},
				},
			},
			{
				path: 'settings/languages/edit-language/:id',
				loadChildren: () => import('../../pages/settings/languages/edit-language/edit-language.module').then((m) => m.EditLanguageModule),
				canActivate: [AuthGuard],
				title: 'Sprache bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Sprache bearbeiten',
					},
				},
			},
			{
				path: 'settings/net-promoter-score',
				loadChildren: () => import('../../pages/settings/net-promoter-score/net-promoter-score.module').then((m) => m.NetPromoterScoreModule),
				canActivate: [AuthGuard],
				title: 'Net Promoter Score - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'NPS',
					},
				},
			},
			{
				path: 'settings/net-promoter-score/add-net-promoter-score',
				loadChildren: () => import('../../pages/settings/net-promoter-score/add-net-promoter-score/add-net-promoter-score.module').then((m) => m.AddNetPromoterScoreModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Net Promoter Score hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'NPS hinzufügen',
					},
				},
			},
			{
				path: 'settings/net-promoter-score/edit-net-promoter-score/:id',
				loadChildren: () => import('../../pages/settings/net-promoter-score/edit-net-promoter-score/edit-net-promoter-score.module').then((m) => m.EditNetPromoterScoreModule),
				canActivate: [AuthGuard],
				title: 'Net Promoter Score bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'NPS bearbeiten',
					},
				},
			},
			{
				path: 'settings/net-promoter-score/details/:id',
				loadChildren: () =>
					import('../../pages/settings/net-promoter-score/detail-net-promoter-score/detail-net-promoter-score.module').then((m) => m.DetailNetPromoterScoreModule),
				canActivate: [AuthGuard],
				title: 'Net Promoter Score Details - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'NPS Auswertung',
					},
				},
			},
			{
				path: 'settings/configurations',
				loadChildren: () => import('../../pages/settings/configurations/configurations.module').then((m) => m.ConfigurationsModule),
				canActivate: [AuthGuard],
				title: 'Konfigurationen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
					breadcrumb: {
						label: 'Konfigurationen',
					},
				},
			},
			{
				path: 'settings/rating-criteria',
				loadChildren: () => import('../../pages/settings/rating-criteria/rating-criteria.module').then((m) => m.RatingCriteriaModule),
				canActivate: [AuthGuard],
				title: 'Bewertungskriterien - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Bewertungskriterien',
					},
				},
			},
			{
				path: 'settings/rating-criteria/edit-rating-criteria/:id',
				loadChildren: () => import('../../pages/settings/rating-criteria/edit-rating-criteria/edit-rating-criteria.module').then((m) => m.EditRatingCriteriaModule),
				canActivate: [AuthGuard],
				title: 'Bewertungskriterien bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Bewertungskriterien bearbeiten',
					},
				},
			},
			{
				path: 'settings/rating-criteria/add-rating-criteria',
				loadChildren: () => import('../../pages/settings/rating-criteria/add-rating-criteria/add-rating-criteria.module').then((m) => m.AddRatingCriteriaModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Bewertungskriterien hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Bewertungskriterien hinzufügen',
					},
				},
			},
			{
				path: 'settings/billing-informations',
				loadChildren: () => import('../../pages/settings/billing-information/billing-information.module').then((m) => m.BillingInformationModule),
				canActivate: [AuthGuard],
				title: 'Zahlungsinformationen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Zahlungsinformationen',
					},
				},
			},
			{
				path: 'settings/billing-informations/add-billing-information',
				loadChildren: () => import('../../pages/settings/billing-information/add-billing-information/add-billing-information.module').then((m) => m.AddBillingInformationModule),
				canActivate: [AuthGuard],
				title: 'Zahlungsinformationen hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Zahlungsinformationen hinzufügen',
					},
				},
			},
			{
				path: 'settings/billing-informations/edit-billing-information/:id',
				loadChildren: () => import('../../pages/settings/billing-information/edit-billing-information/edit-billing-information.module').then((m) => m.EditBillingInformationModule),
				canActivate: [AuthGuard],
				title: 'Zahlungsinformationen bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Zahlungsinformationen bearbeiten',
					},
				},
			},
			{
				path: 'settings/redirects',
				loadChildren: () => import('../../pages/settings/redirects/redirects.module').then((m) => m.RedirectsModule),
				canActivate: [AuthGuard],
				title: 'Weiterleitungen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'RedWeiterleitungen',
					},
				},
			},
			{
				path: 'settings/redirects/add-redirect',
				loadChildren: () => import('../../pages/settings/redirects/redirects-add/redirects-add.module').then((m) => m.RedirectsAddModule),
				canActivate: [AuthGuard],
				title: 'Weiterleitung hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Weiterleitung hinzufügen',
					},
				},
			},
			{
				path: 'settings/redirects/edit-redirect/:id',
				loadChildren: () => import('../../pages/settings/redirects/redirects-edit/redirects-edit.module').then((m) => m.RedirectsEditModule),
				canActivate: [AuthGuard],
				title: 'Weiterleitung bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Weiterleitung bearbeiten',
					},
				},
			},
			{
				path: 'analytics',
				loadChildren: () => import('../../pages/analytics/analytics.module').then((m) => m.AnalyticsModule),
				canActivate: [AuthGuard],
				title: 'Analytics - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner'],
					breadcrumb: {
						label: 'Analytics',
					},
				},
			},
			{
				path: 'analytics/logins',
				loadChildren: () => import('../../pages/analytics/logins/logins.module').then((m) => m.LoginsModule),
				canActivate: [AuthGuard],
				title: 'Logins - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Logins',
					},
				},
			},
			{
				path: 'analytics/flows',
				loadChildren: () => import('../../pages/analytics/flows/flows.module').then((m) => m.FlowsModule),
				canActivate: [AuthGuard],
				title: 'User Flows - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'User Flows',
					},
				},
			},
			{
				path: 'analytics/flows/flow/:id',
				loadChildren: () => import('../../pages/analytics/flows/flow/flow.module').then((m) => m.FlowModule),
				canActivate: [AuthGuard],
				title: 'User Flow Details - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'User Flow',
					},
				},
			},
			{
				path: 'plans',
				loadChildren: () => import('../plans/plans.module').then((m) => m.PlansModule),
				canActivate: [AuthGuard],
				title: 'Subscription Plans - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Subscription Pläne',
					},
				},
			},
			{
				path: 'plans/add-plan',
				loadChildren: () => import('../plans/add-plan/add-plan.module').then((m) => m.AddPlanModule),
				canActivate: [AuthGuard],
				title: 'Subscription Plan hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Subscription Plan hinzufügen',
					},
				},
			},
			{
				path: 'plans/edit-plan/:id',
				loadChildren: () => import('../plans/edit-plan/edit-plan.module').then((m) => m.EditPlanModule),
				canActivate: [AuthGuard],
				title: 'Subscription Plan bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Subscription Plan bearbeiten',
					},
				},
			},
			{
				path: 'plans/groups',
				loadChildren: () => import('../plans/groups/groups.module').then((m) => m.GroupsModule),
				canActivate: [AuthGuard],
				title: 'Subscription Plan Gruppen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Subscription Plan Gruppen',
					},
				},
			},
			{
				path: 'plans/groups/add',
				loadChildren: () => import('../plans/groups/add-group/add-group.module').then((m) => m.AddGroupModule),
				canActivate: [AuthGuard],
				title: 'Subscription Plan Gruppe hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Subscription Plan Gruppe hinzufügen',
					},
				},
			},
			{
				path: 'plans/groups/add/:id',
				loadChildren: () => import('../plans/groups/add-group/add-group.module').then((m) => m.AddGroupModule),
				canActivate: [AuthGuard],
				title: 'Subscription Plan Gruppe - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Subscription Plan Gruppe bearbeiten',
					},
				},
			},
			{
				path: 'customer/ips',
				loadChildren: () => import('../customer/ips/ips.module').then((m) => m.IpsModule),
				canActivate: [AuthGuard],
				title: 'IP Bereiche - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'IP Bereiche',
					},
				},
			},
			{
				path: 'customer/ips/add-ip',
				loadChildren: () => import('../customer/ips/add-ip/add-ip.module').then((m) => m.AddIpModule),
				canActivate: [AuthGuard],
				title: 'IP Bereich hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'IP Bereich hinzufügen',
					},
				},
			},
			{
				path: 'customer/ips/add-ip/:id',
				loadChildren: () => import('../customer/ips/add-ip/add-ip.module').then((m) => m.AddIpModule),
				canActivate: [AuthGuard],
				title: 'IP Bereich hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'IP Bereich hinzufügen',
					},
				},
			},
			{
				path: 'customer/ips/edit-ip/:id',
				loadChildren: () => import('../customer/ips/edit-ip/edit-ip.module').then((m) => m.EditIpModule),
				canActivate: [AuthGuard],
				title: 'IP Bereich bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'IP Bereich bearbeiten',
					},
				},
			},
			{
				path: 'customer/landing-pages',
				loadChildren: () => import('../customer/landing-page/landing-page.module').then((m) => m.LandingPageModule),
				canActivate: [AuthGuard],
				title: 'Landingpages - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Landingpages',
					},
				},
			},
			{
				path: 'customer/landing-pages/add-landing-page',
				loadChildren: () => import('../customer/landing-page/add-landing-page/add-landing-page.module').then((m) => m.AddLandingPageModule),
				canActivate: [AuthGuard],
				title: 'Landingpage hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Landingpage hinzufügen',
					},
				},
			},
			{
				path: 'customer/landing-pages/edit-landing-page/:id',
				loadChildren: () => import('../customer/landing-page/edit-landing-page/edit-landing-page.module').then((m) => m.EditLandingPageModule),
				canActivate: [AuthGuard],
				title: 'Landingpage bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Landingpage bearbeiten',
					},
				},
			},
			{
				path: 'authors',
				loadChildren: () => import('../../pages/authors/authors.module').then((m) => m.AuthorsModule),
				canActivate: [AuthGuard],
				title: 'Autoren - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Autoren',
					},
				},
			},
			{
				path: 'authors/export-authors',
				loadChildren: () => import('../../pages/authors/export-authors/export-authors.module').then((m) => m.ExportAuthorsModule),
				canActivate: [AuthGuard],
				title: 'Autoren exportieren - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Autoren exportieren',
					},
				},
			},
			{
				path: 'authors/edit-author/:id',
				loadChildren: () => import('../../pages/authors/edit-author/edit-author.module').then((m) => m.EditAuthorModule),
				canActivate: [AuthGuard],
				title: 'Autor bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Autor bearbeiten',
					},
				},
			},
			{
				path: 'products',
				loadChildren: () => import('../../pages/products/products.module').then((m) => m.ProductsModule),
				canActivate: [AuthGuard],
				title: 'Produkte - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Produkte',
					},
				},
			},
			{
				path: 'products/export',
				loadChildren: () => import('../../pages/products/export-products/export-products.module').then((m) => m.ExportProductsModule),
				canActivate: [AuthGuard],
				title: 'Produkte exportieren - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Produkte exportieren',
					},
				},
			},
			{
				path: 'products/details/:id',
				loadChildren: () => import('../../pages/products/details/details.module').then((m) => m.DetailsModule),
				canActivate: [AuthGuard],
				title: 'Produkt Details - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Produkt Details',
					},
				},
			},
			{
				path: 'products/category',
				loadChildren: () => import('../../pages/products/category/category.module').then((m) => m.CategoryModule),
				canActivate: [AuthGuard],
				title: 'Produkt Kategorie - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kategorie',
					},
				},
			},
			{
				path: 'products/category/add-category',
				loadChildren: () => import('../../pages/products/category/add-category/add-category.module').then((m) => m.AddCategoryModule),
				canActivate: [AuthGuard],
				title: 'Produkt Kategorie hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kategorie hinzufügen',
					},
				},
			},
			{
				path: 'products/category/edit-category/:id',
				loadChildren: () => import('../../pages/products/category/edit-category/edit-category.module').then((m) => m.EditCategoryModule),
				canActivate: [AuthGuard],
				title: 'Produkt Kategorie bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kategorie bearbeiten',
					},
				},
			},
			{
				path: 'products/type',
				loadChildren: () => import('../../pages/products/type/type.module').then((m) => m.TypeModule),
				canActivate: [AuthGuard],
				title: 'Produkt Typ - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Typen',
					},
				},
			},
			{
				path: 'products/type/add-type',
				loadChildren: () => import('../../pages/products/type/add-type/add-type.module').then((m) => m.AddTypeModule),
				canActivate: [AuthGuard],
				title: 'Produkt Typ hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Typ hinzufügen',
					},
				},
			},
			{
				path: 'products/type/edit-type/:id',
				loadChildren: () => import('../../pages/products/type/edit-type/edit-type.module').then((m) => m.EditTypeModule),
				canActivate: [AuthGuard],
				title: 'Produkt Typ bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Typ bearbeiten',
					},
				},
			},
			{
				path: 'products/add-product',
				loadChildren: () => import('../../pages/products/add-product/generell-information/generell-information.module').then((m) => m.GenerellInformationModule),
				canActivate: [AuthGuard],
				title: 'Produkt hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Produkt hinzufügen',
					},
				},
			},
			{
				path: 'products/add-product/edit-info/:id',
				loadChildren: () => import('../../pages/products/add-product/edit-generell-information/edit-generell-information.module').then((m) => m.EditGenerellInformationModule),
				canActivate: [AuthGuard],
				title: 'Produkt beabreiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Produkt bearbeiten',
					},
				},
			},
			{
				path: 'products/add-product/meta/:id',
				loadChildren: () => import('../../pages/products/add-product/meta-information/meta-information.module').then((m) => m.MetaInformationModule),
				canActivate: [AuthGuard],
				title: 'Produkt Meta hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Produkt Meta hinzufügen',
					},
				},
			},
			{
				path: 'products/add-product/media/:id',
				loadChildren: () => import('../../pages/products/add-product/product-data/product-data.module').then((m) => m.ProductDataModule),
				canActivate: [AuthGuard],
				title: 'Produkt Medien hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Produkt Medien hinzufügen',
					},
				},
			},
			{
				path: 'products/add-product/native-reader/:id',
				loadChildren: () => import('../../pages/products/add-product/native-reader/native-reader.module').then((m) => m.NativeReaderModule),
				canActivate: [AuthGuard],
				title: 'Native Reader - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Native Reader',
					},
				},
			},
			{
				path: 'products/add-product/additional-material/:id',
				loadChildren: () => import('../../pages/products/add-product/additional-material/additional-material.module').then((m) => m.AdditionalMaterialModule),
				canActivate: [AuthGuard],
				title: 'Produkt weitere Inhalte - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Weitere Inhalte',
					},
				},
			},
			{
				path: 'products/add-product/webshop/:id',
				loadChildren: () => import('../../pages/products/add-product/webshop/webshop.module').then((m) => m.WebshopModule),
				canActivate: [AuthGuard],
				title: 'Webshop Einstellungen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Webshop',
					},
				},
			},
			{
				path: 'products/add-product/billing/:id',
				loadChildren: () => import('../../pages/products/add-product/billing-information/billing-information.module').then((m) => m.BillingInformationModule),
				canActivate: [AuthGuard],
				title: 'Produkt Rechnungsinformationen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Rechnungsinformationen',
					},
				},
			},
			{
				path: 'products/segments',
				loadChildren: () => import('../../pages/products/segments/segments.module').then((m) => m.SegmentsModule),
				canActivate: [AuthGuard],
				title: 'Produkt Segmente - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Segmente',
					},
				},
			},
			{
				path: 'products/segments/add-segment',
				loadChildren: () => import('../../pages/products/segments/add-segment/add-segment.module').then((m) => m.AddSegmentModule),
				canActivate: [AuthGuard],
				title: 'Produkt Segment hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Segment hinzufügen',
					},
				},
			},
			{
				path: 'products/segments/edit-segment/:id',
				loadChildren: () => import('../../pages/products/segments/edit-segment/edit-segment.module').then((m) => m.EditSegmentModule),
				canActivate: [AuthGuard],
				title: 'Produkt Segment bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Segment bearbeiten',
					},
				},
			},
			{
				path: 'products/details/ratings/:id',
				loadChildren: () => import('../../pages/products/ratings/ratings.module').then((m) => m.RatingsModule),
				canActivate: [AuthGuard],
				title: 'Produkt Bewertung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Bewertung',
					},
				},
			},
			{
				path: 'products/stats',
				loadChildren: () => import('../../pages/products/stats/stats.module').then((m) => m.StatsModule),
				canActivate: [AuthGuard],
				title: 'Produkt Statistik - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Statistik',
					},
				},
			},
			{
				path: 'media',
				loadChildren: () => import('../../pages/attachments/attachments.module').then((m) => m.AttachmentsModule),
				canActivate: [AuthGuard],
				title: 'Medien - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Medien',
					},
				},
			},
			{
				path: 'media/detail/:id',
				loadChildren: () => import('../../pages/attachments/detail-view/detail-view.module').then((m) => m.DetailViewModule),
				canActivate: [AuthGuard],
				title: 'Medien Details - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Medien Details',
					},
				},
			},
			{
				path: 'media/folder/:id',
				loadChildren: () => import('../../pages/attachments/folder/folder.module').then((m) => m.FolderModule),
				canActivate: [AuthGuard],
				title: 'Medien Ordner - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Ordner',
					},
				},
			},
			{
				path: 'help',
				loadChildren: () => import('../../pages/help/help.module').then((m) => m.HelpModule),
				canActivate: [AuthGuard],
				title: 'Hilfe - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner'],
					breadcrumb: {
						label: 'Hilfe',
					},
				},
			},
			{
				path: 'help/plm',
				loadChildren: () => import('../../pages/help/plm/plm.module').then((m) => m.PlmModule),
				canActivate: [AuthGuard],
				title: 'Hilfe PLM - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner'],
					breadcrumb: {
						label: 'PLM',
					},
				},
			},
			{
				path: 'help/partner',
				loadChildren: () => import('../../pages/help/partner/partner.module').then((m) => m.PartnerModule),
				canActivate: [AuthGuard],
				title: 'Hilfe Partner - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Partner',
					},
				},
			},
			{
				path: 'help/conferences',
				loadChildren: () => import('../../pages/help/conferences/conferences.module').then((m) => m.ConferencesModule),
				canActivate: [AuthGuard],
				title: 'Hilfe Konferenzen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner'],
					breadcrumb: {
						label: 'Konferenzen',
					},
				},
			},
			{
				path: 'help/attachments',
				loadChildren: () => import('../../pages/help/attachments/attachments.module').then((m) => m.AttachmentsModule),
				canActivate: [AuthGuard],
				title: 'Hilfe Medien - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner'],
					breadcrumb: {
						label: 'Medien',
					},
				},
			},
			{
				path: 'help/mails',
				loadChildren: () => import('../../pages/help/mails/mails.module').then((m) => m.MailsModule),
				canActivate: [AuthGuard],
				title: 'Hilfe Mails - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner'],
					breadcrumb: {
						label: 'E-Mails',
					},
				},
			},
			{
				path: 'mails',
				loadChildren: () => import('../../pages/mails/mails.module').then((m) => m.MailsModule),
				canActivate: [AuthGuard],
				title: 'Mails - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
					breadcrumb: {
						label: 'E-Mails',
					},
				},
			},
			{
				path: 'users',
				loadChildren: () => import('../../pages/users/users.module').then((m) => m.UsersModule),
				canActivate: [AuthGuard],
				title: 'Benutzer - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
					breadcrumb: {
						label: 'Benutzer',
					},
				},
			},
			{
				path: 'users/details/:id',
				loadChildren: () => import('../../pages/users/detail-user/detail-user.module').then((m) => m.DetailUserModule),
				canActivate: [AuthGuard],
				title: 'Benutzer Details - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Benutzer Details',
					},
				},
			},
			{
				path: 'users/add-user',
				loadChildren: () => import('../../pages/users/add-user/add-user.module').then((m) => m.AddUserModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Benutzer hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
					breadcrumb: {
						label: 'Benutzer hinzufügen',
					},
				},
			},
			{
				path: 'users/add-user/:role',
				loadChildren: () => import('../../pages/users/add-user/add-user.module').then((m) => m.AddUserModule),
				canActivate: [AuthGuard],
				canDeactivate: [DirtyCheckGuard],
				title: 'Benutzer hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Benutzer hinzufügen',
					},
				},
			},
			{
				path: 'users/edit-user/:id',
				loadChildren: () => import('../../pages/users/edit-user/edit-user.module').then((m) => m.EditUserModule),
				canActivate: [AuthGuard],
				title: 'Benutzer bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin', 'Partner - KUT - Admin'],
					breadcrumb: {
						label: 'Benutzer bearbeiten',
					},
				},
			},
			{
				path: 'not-allowed',
				loadChildren: () => import('../../pages/page-not-allowed/page-not-allowed.module').then((m) => m.PageNotAllowedModule),
				canActivate: [AuthGuard],
				title: '403 / Keine Berechtigung - PLM – Espresso Tutorials GmbH',
				data: {
					breadcrumb: {
						label: '403 / Keine Berechtigung',
					},
				},
			},
			{
				path: 'learning-path',
				loadChildren: () => import('../../pages/learning-path/learning-path.module').then((m) => m.LearningPathModule),
				canActivate: [AuthGuard],
				title: 'Lernpfade - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Lernpfade',
					},
				},
			},
			{
				path: 'learning-path/add',
				loadChildren: () => import('../../pages/learning-path/add-learning-path/add-learning-path.module').then((m) => m.AddLearningPathModule),
				canActivate: [AuthGuard],
				title: 'Lernpfad hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Lernpfad hinzufügen',
					},
				},
			},
			{
				path: 'learning-path/edit/:id',
				loadChildren: () => import('../../pages/learning-path/edit-learning-path/edit-learning-path.module').then((m) => m.EditLearningPathModule),
				canActivate: [AuthGuard],
				title: 'Lernpfad bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 't4t - Admin', 'ttz - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Lernpfad bearbeiten',
					},
				},
			},
			{
				path: 'revisions/:type/:id',
				loadChildren: () => import('../../pages/history/history.module').then((m) => m.HistoryModule),
				canActivate: [AuthGuard],
				title: 'Historie - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Historie',
					},
				},
			},
			{
				path: 'arm',
				loadChildren: () => import('../../pages/arm/arm.module').then((m) => m.ArmModule),
				canActivate: [AuthGuard],
				title: 'ARM - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'ARM',
					},
				},
			},
			{
				path: 'arm/word',
				loadChildren: () => import('../../pages/arm/word/word.module').then((m) => m.WordModule),
				canActivate: [AuthGuard],
				title: 'Word Vorlagen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Word Vorlagen',
					},
				},
			},
			{
				path: 'arm/word/add-word',
				loadChildren: () => import('../../pages/arm/word/add-word/add-word.module').then((m) => m.AddWordModule),
				canActivate: [AuthGuard],
				title: 'Word Vorlage hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Word Vorlage hinzufügen',
					},
				},
			},
			{
				path: 'arm/word/edit-word/:id',
				loadChildren: () => import('../../pages/arm/word/edit-word/edit-word.module').then((m) => m.EditWordModule),
				canActivate: [AuthGuard],
				title: 'Word Vorlage bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Word Vorlage bearbeiten',
					},
				},
			},
			{
				path: 'arm/videos',
				loadChildren: () => import('../../pages/arm/videos/videos.module').then((m) => m.VideosModule),
				canActivate: [AuthGuard],
				title: 'How-To-Videos - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Videos',
					},
				},
			},
			{
				path: 'arm/videos/add-video',
				loadChildren: () => import('../../pages/arm/videos/add-video/add-video.module').then((m) => m.AddVideoModule),
				canActivate: [AuthGuard],
				title: 'How-To-Video hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Video hinzufügen',
					},
				},
			},
			{
				path: 'arm/videos/edit-video/:id',
				loadChildren: () => import('../../pages/arm/videos/edit-video/edit-video.module').then((m) => m.EditVideoModule),
				canActivate: [AuthGuard],
				title: 'How-To-Video bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Video bearbeiten',
					},
				},
			},
			{
				path: 'certificates',
				loadChildren: () => import('../../pages/certificates/certificates.module').then((m) => m.CertificatesModule),
				canActivate: [AuthGuard],
				title: 'Zertifikate/QA-Tests - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Zertifikate/QA-Tests',
					},
				},
			},
			{
				path: 'certificates/add',
				loadChildren: () => import('../../pages/certificates/add-certificate/add-certificate.module').then((m) => m.AddCertificateModule),
				canActivate: [AuthGuard],
				title: 'Zertifikat hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Zertifikat hinzufügen',
					},
				},
			},
			{
				path: 'certificates/edit/:id',
				loadChildren: () => import('../../pages/certificates/edit-certificate/edit-certificate.module').then((m) => m.EditCertificateModule),
				canActivate: [AuthGuard],
				title: 'Zertifikat bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin', 'Partner', 't4t - Admin', 'Partner - Admin'],
					breadcrumb: {
						label: 'Zertifikat bearbeiten',
					},
				},
			},
			{
				path: 'commercials',
				loadChildren: () => import('../../pages/commercials/commercials.module').then((m) => m.CommercialsModule),
				canActivate: [AuthGuard],
				title: 'Werbung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Werbung',
					},
				},
			},
			{
				path: 'commercials/add',
				loadChildren: () => import('../../pages/commercials/add-commercial/add-commercial.module').then((m) => m.AddCommercialModule),
				canActivate: [AuthGuard],
				title: 'Werbung hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Werbung hinzufügen',
					},
				},
			},
			{
				path: 'commercials/edit/:id',
				loadChildren: () => import('../../pages/commercials/edit-commercial/edit-commercial.module').then((m) => m.EditCommercialModule),
				canActivate: [AuthGuard],
				title: 'Werbung bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Werbung bearbeiten',
					},
				},
			},
			{
				path: 'billing',
				loadChildren: () => import('../../pages/billing/billing.module').then((m) => m.BillingModule),
				canActivate: [AuthGuard],
				title: 'Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Autoren-Abrechnung',
					},
				},
			},
			{
				path: 'billing/settings',
				loadChildren: () => import('../../pages/billing/settings/settings.module').then((m) => m.SettingsModule),
				canActivate: [AuthGuard],
				title: 'Einstellungen - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Einstellungen',
					},
				},
			},
			{
				path: 'billing/accounting',
				loadChildren: () => import('../../pages/billing/accounting/accounting.module').then((m) => m.AccountingModule),
				canActivate: [AuthGuard],
				title: 'Abrechnung - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Abrechnung',
					},
				},
			},
			{
				path: 'billing/documents',
				loadChildren: () => import('../../pages/billing/documents/documents.module').then((m) => m.DocumentsModule),
				canActivate: [AuthGuard],
				title: 'Belege - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Belege',
					},
				},
			},
			{
				path: 'billing/documents/:id',
				loadChildren: () => import('../../pages/billing/documents/details/details.module').then((m) => m.DetailsModule),
				canActivate: [AuthGuard],
				title: 'Beleg Details - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Beleg Details',
					},
				},
			},
			{
				path: 'billing/exports',
				loadChildren: () => import('../../pages/billing/exports/exports.module').then((m) => m.ExportsModule),
				canActivate: [AuthGuard],
				title: 'Exports - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Exports',
					},
				},
			},
			{
				path: 'billing/imports',
				loadChildren: () => import('../../pages/billing/import/import.module').then((m) => m.ImportModule),
				canActivate: [AuthGuard],
				title: 'Imports - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Imports',
					},
				},
			},
			{
				path: 'billing/line-items',
				loadChildren: () => import('../../pages/billing/line-items/line-items.module').then((m) => m.LineItemsModule),
				canActivate: [AuthGuard],
				title: 'Einzelposten - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Einzelposten',
					},
				},
			},
			{
				path: 'billing/credit-lines',
				loadChildren: () => import('../../pages/billing/credit-lines/credit-lines.module').then((m) => m.CreditLinesModule),
				canActivate: [AuthGuard],
				title: 'Belegpositionen - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Belegpositionen',
					},
				},
			},
			{
				path: 'billing/line-items/add',
				loadChildren: () => import('../../pages/billing/line-items/add-line-item/add-line-item.module').then((m) => m.AddLineItemModule),
				canActivate: [AuthGuard],
				title: 'Einzelposten hinzufügen - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Einzelposten hinzufügen',
					},
				},
			},
			{
				path: 'billing/line-items/edit/:id',
				loadChildren: () => import('../../pages/billing/line-items/edit-line-item/edit-line-item.module').then((m) => m.EditLineItemModule),
				canActivate: [AuthGuard],
				title: 'Einzelposten Details - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Details',
					},
				},
			},
			{
				path: 'billing/settings/billing-countries',
				loadChildren: () => import('../../pages/billing/settings/billing-countries/billing-countries.module').then((m) => m.BillingCountriesModule),
				canActivate: [AuthGuard],
				title: 'Länder - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Länder',
					},
				},
			},
			{
				path: 'billing/settings/billing-countries/edit/:id',
				loadChildren: () => import('../../pages/billing/settings/billing-countries/edit-billing-countries/edit-billing-countries.module').then((m) => m.EditBillingCountriesModule),
				canActivate: [AuthGuard],
				title: 'Land bearbeiten - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Land bearbeiten',
					},
				},
			},
			{
				path: 'billing/settings/billing-countries/edit',
				loadChildren: () => import('../../pages/billing/settings/billing-countries/edit-billing-countries/edit-billing-countries.module').then((m) => m.EditBillingCountriesModule),
				canActivate: [AuthGuard],
				title: 'Land hinzufügen - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Land hinzufügen',
					},
				},
			},
			{
				path: 'billing/settings/billing-distribution-channels',
				loadChildren: () =>
					import('../../pages/billing/settings/billing-distribution-channels/billing-distribution-channels.module').then((m) => m.BillingDistributionChannelsModule),
				canActivate: [AuthGuard],
				title: 'Vertriebskanäle - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Vertriebskanäle',
					},
				},
			},
			{
				path: 'billing/settings/billing-distribution-channels/edit/:id',
				loadChildren: () =>
					import('../../pages/billing/settings/billing-distribution-channels/edit-billing-distribution-channels/edit-billing-distribution-channels.module').then(
						(m) => m.EditBillingDistributionChannelsModule
					),
				canActivate: [AuthGuard],
				title: 'Vertriebskanal bearbeiten - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Vertriebskanal bearbeiten',
					},
				},
			},
			{
				path: 'billing/settings/billing-distribution-channels/edit',
				loadChildren: () =>
					import('../../pages/billing/settings/billing-distribution-channels/edit-billing-distribution-channels/edit-billing-distribution-channels.module').then(
						(m) => m.EditBillingDistributionChannelsModule
					),
				canActivate: [AuthGuard],
				title: 'Vertriebskanal hinzufügen - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Vertriebskanal hinzufügen',
					},
				},
			},
			{
				path: 'billing/settings/billing-line-item-categories',
				loadChildren: () => import('../../pages/billing/settings/billing-line-item-categories/billing-line-item-categories.module').then((m) => m.BillingLineItemCategoriesModule),
				canActivate: [AuthGuard],
				title: 'Einzelpostenkategorien - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Einzelpostenkategorien',
					},
				},
			},
			{
				path: 'billing/settings/billing-line-item-categories/edit/:id',
				loadChildren: () =>
					import('../../pages/billing/settings/billing-line-item-categories/edit-billing-line-item-categories/edit-billing-line-item-categories.module').then(
						(m) => m.EditBillingLineItemCategoriesModule
					),
				canActivate: [AuthGuard],
				title: 'Einzelpostenkategorie bearbeiten - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Einzelpostenkategorie bearbeiten',
					},
				},
			},
			{
				path: 'billing/settings/billing-line-item-categories/edit',
				loadChildren: () =>
					import('../../pages/billing/settings/billing-line-item-categories/edit-billing-line-item-categories/edit-billing-line-item-categories.module').then(
						(m) => m.EditBillingLineItemCategoriesModule
					),
				canActivate: [AuthGuard],
				title: 'Einzelpostenkategorie hinzufügen - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Einzelpostenkategorie hinzufügen',
					},
				},
			},
			{
				path: 'billing/settings/billing-functional-area',
				loadChildren: () => import('../../pages/billing/settings/billing-functional-area/billing-functional-area.module').then((m) => m.BillingFunctionalAreaModule),
				canActivate: [AuthGuard],
				title: 'Funktionsbereiche - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Funktionsbereiche',
					},
				},
			},
			{
				path: 'billing/settings/billing-functional-area/edit/:id',
				loadChildren: () =>
					import('../../pages/billing/settings/billing-functional-area/edit-billing-functional-area/edit-billing-functional-area.module').then(
						(m) => m.EditBillingFunctionalAreaModule
					),
				canActivate: [AuthGuard],
				title: 'Funktionsbereich bearbeiten - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Funktionsbereich bearbeiten',
					},
				},
			},
			{
				path: 'billing/settings/billing-functional-area/edit',
				loadChildren: () =>
					import('../../pages/billing/settings/billing-functional-area/edit-billing-functional-area/edit-billing-functional-area.module').then(
						(m) => m.EditBillingFunctionalAreaModule
					),
				canActivate: [AuthGuard],
				title: 'Funktionsbereich hinzufügen - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Funktionsbereich hinzufügen',
					},
				},
			},
			{
				path: 'billing/settings/billing-cost-category',
				loadChildren: () => import('../../pages/billing/settings/billing-cost-category/billing-cost-category.module').then((m) => m.BillingCostCategoryModule),
				canActivate: [AuthGuard],
				title: 'Kostenkategorien - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kostenkategorien',
					},
				},
			},
			{
				path: 'billing/settings/billing-cost-category/edit/:id',
				loadChildren: () =>
					import('../../pages/billing/settings/billing-cost-category/edit-billing-cost-category/edit-billing-cost-category.module').then((m) => m.EditBillingCostCategoryModule),
				canActivate: [AuthGuard],
				title: 'Kostenkategorie bearbeiten - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kostenkategorie bearbeiten',
					},
				},
			},
			{
				path: 'billing/settings/billing-cost-category/edit',
				loadChildren: () =>
					import('../../pages/billing/settings/billing-cost-category/edit-billing-cost-category/edit-billing-cost-category.module').then((m) => m.EditBillingCostCategoryModule),
				canActivate: [AuthGuard],
				title: 'Kostenkategorie hinzufügen - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kostenkategorie hinzufügen',
					},
				},
			},
			{
				path: 'billing/settings/billing-agent',
				loadChildren: () => import('../../pages/billing/settings/billing-agent/billing-agent.module').then((m) => m.BillingAgentModule),
				canActivate: [AuthGuard],
				title: 'Vermittler - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Vermittler',
					},
				},
			},
			{
				path: 'billing/settings/billing-agent/edit/:id',
				loadChildren: () => import('../../pages/billing/settings/billing-agent/edit-billing-agent/edit-billing-agent.module').then((m) => m.EditBillingAgentModule),
				canActivate: [AuthGuard],
				title: 'Vermittler bearbeiten - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Vermittler bearbeiten',
					},
				},
			},
			{
				path: 'billing/settings/billing-agent/edit',
				loadChildren: () => import('../../pages/billing/settings/billing-agent/edit-billing-agent/edit-billing-agent.module').then((m) => m.EditBillingAgentModule),
				canActivate: [AuthGuard],
				title: 'Vermittler hinzufügen - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Vermittler hinzufügen',
					},
				},
			},
			{
				path: 'billing/settings/billing-accounts',
				loadChildren: () => import('../../pages/billing/settings/billing-accounts/billing-accounts.module').then((m) => m.BillingAccountsModule),
				canActivate: [AuthGuard],
				title: 'Konten - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Konten',
					},
				},
			},
			{
				path: 'billing/settings/billing-accounts/edit/:id',
				loadChildren: () => import('../../pages/billing/settings/billing-accounts/edit-billing-accounts/edit-billing-accounts.module').then((m) => m.EditBillingAccountsModule),
				canActivate: [AuthGuard],
				title: 'Konto bearbeiten - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Konto bearbeiten',
					},
				},
			},
			{
				path: 'billing/settings/billing-accounts/edit',
				loadChildren: () => import('../../pages/billing/settings/billing-accounts/edit-billing-accounts/edit-billing-accounts.module').then((m) => m.EditBillingAccountsModule),
				canActivate: [AuthGuard],
				title: 'Konto hinzufügen - Autoren-Abrechnung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Konto hinzufügen',
					},
				},
			},
			{
				path: 'tools',
				loadChildren: () => import('../../pages/tools/tools.module').then((m) => m.ToolsModule),
				canActivate: [AuthGuard],
				title: 'Tools - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Tools',
					},
				},
			},
			{
				path: 'tools/cover-generator',
				loadChildren: () => import('../../pages/tools/cover-generator/cover-generator.module').then((m) => m.CoverGeneratorModule),
				canActivate: [AuthGuard],
				title: 'Cover & Thumbnail generieren - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Cover & Thumbnail generieren',
					},
				},
			},
			{
				path: 'tools/resizer',
				loadChildren: () => import('../../pages/tools/resizer/resizer.module').then((m) => m.ResizerModule),
				canActivate: [AuthGuard],
				title: 'Bildgröße anpassen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Bildgröße anpassen',
					},
				},
			},
			{
				path: 'tools/csv-converter',
				loadChildren: () => import('../../pages/tools/csv-converter/csv-converter.module').then((m) => m.CsvConverterModule),
				canActivate: [AuthGuard],
				title: 'CSV konvertieren - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'CSV konvertieren',
					},
				},
			},
			{
				path: 'tools/description-generator',
				loadChildren: () => import('../../pages/tools/description-generator/description-generator.module').then((m) => m.DescriptionGeneratorModule),
				canActivate: [AuthGuard],
				title: 'Beschreibung generieren - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Beschreibung generieren',
					},
				},
			},
			{
				path: 'tools/tickets',
				loadChildren: () => import('../../pages/tools/shortcut/shortcut.module').then((m) => m.ShortcutModule),
				canActivate: [AuthGuard],
				title: 'Tickets - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Tickets',
					},
				},
			},
			{
				path: 'tools/tickets/details/:id',
				loadChildren: () => import('../../pages/tools/shortcut/details/details.module').then((m) => m.DetailsModule),
				canActivate: [AuthGuard],
				title: 'Ticket Details - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Ticket Details',
					},
				},
			},
			{
				path: 'tools/tickets/add-ticket',
				loadChildren: () => import('../../pages/tools/shortcut/add-ticket/add-ticket.module').then((m) => m.AddTicketModule),
				canActivate: [AuthGuard],
				title: 'Ticket hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Ticket hinzufügen',
					},
				},
			},
			{
				path: 'tools/badge-generator',
				loadChildren: () => import('../../pages/tools/badge-generator/badge-generator.module').then((m) => m.BadgeGeneratorModule),
				canActivate: [AuthGuard],
				title: 'Zertifikate Badge generieren - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Zertifikate Badge generieren',
					},
				},
			},
			{
				path: 'tools/newsletter-image-generator',
				loadChildren: () => import('../../pages/tools/newsletter-image-generator/newsletter-image-generator.module').then((m) => m.NewsletterImageGeneratorModule),
				canActivate: [AuthGuard],
				title: 'Newsletter Bild generieren - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Newsletter Bild generieren',
					},
				},
			},
			{
				path: 'courses',
				loadChildren: () => import('../../pages/courses/courses.module').then((m) => m.CoursesModule),
				canActivate: [AuthGuard],
				title: 'Kurse - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kurse',
					},
				},
			},
			{
				path: 'courses/add',
				loadChildren: () => import('../../pages/courses/add-course/add-course.module').then((m) => m.AddCourseModule),
				canActivate: [AuthGuard],
				title: 'Kurs hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kurs hinzufügen',
					},
				},
			},
			{
				path: 'courses/edit/:id',
				loadChildren: () => import('../../pages/courses/edit-course/edit-course.module').then((m) => m.EditCourseModule),
				canActivate: [AuthGuard],
				title: 'Kurs bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Kurs bearbeiten',
					},
				},
			},
			{
				path: 'courses/feedback/:id',
				loadChildren: () => import('../../pages/courses/feedback/feedback.module').then((m) => m.FeedbackModule),
				canActivate: [AuthGuard],
				title: 'Kurs Feedback - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Feedback',
					},
				},
			},
			{
				path: 'courses/statistics',
				loadChildren: () => import('../../pages/courses/statistics/statistics.module').then((m) => m.StatisticsModule),
				canActivate: [AuthGuard],
				title: 'Kurse Statistiken - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Statistiken',
					},
				},
			},
			{
				path: 'webshop',
				loadChildren: () => import('../../pages/webshop/webshop.module').then((m) => m.WebshopModule),
				canActivate: [AuthGuard],
				title: 'Webshop - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Webshop',
					},
				},
			},
			{
				path: 'webshop/posts',
				loadChildren: () => import('../../pages/webshop/posts/posts.module').then((m) => m.PostsModule),
				canActivate: [AuthGuard],
				title: 'Beiträge - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Beiträge',
					},
				},
			},
			{
				path: 'webshop/posts/add',
				loadChildren: () => import('../../pages/webshop/posts/post/post.module').then((m) => m.PostModule),
				canActivate: [AuthGuard],
				title: 'Beitrag hinzufügen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Beitrag hinzufügen',
					},
				},
			},
			{
				path: 'webshop/posts/edit/:id',
				loadChildren: () => import('../../pages/webshop/posts/post/post.module').then((m) => m.PostModule),
				canActivate: [AuthGuard],
				title: 'Beitrag bearbeiten - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Beitrag bearbeiten',
					},
				},
			},
			{
				path: 'webshop/orders',
				loadChildren: () => import('../../pages/webshop/orders/orders.module').then((m) => m.OrdersModule),
				canActivate: [AuthGuard],
				title: 'Bestellungen - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Bestellungen',
					},
				},
			},
			{
				path: 'webshop/orders/:id',
				loadChildren: () => import('../../pages/webshop/orders/order/order.module').then((m) => m.OrderModule),
				canActivate: [AuthGuard],
				title: 'Bestellung - PLM – Espresso Tutorials GmbH',
				data: {
					role: ['Super Admin', 'Admin'],
					breadcrumb: {
						label: 'Bestellung',
					},
				},
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class DashboardRoutingModule {}
