<div class="relative sm:w-full sm:max-w-lg">
	<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
		<div class="sm:flex sm:items-start">
			<div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-400 sm:mx-0 sm:h-10 sm:w-10">
				<svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
					/>
				</svg>
			</div>
			<div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
				<h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title">Duplizieren</h3>
				<div class="mt-2">
					<p class="text-sm text-gray-800">{{ this.title }} wirklich <span class="font-semibold text-red-500">duplizieren</span>?</p>
				</div>
			</div>
		</div>
	</div>
	<div class="bg-white px-4 pb-4 pt-3 sm:flex sm:flex-row-reverse sm:px-6 sm:pb-6">
		<button
			type="button"
			class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
			(click)="onDuplicate(id)"
		>
			Ja, duplizieren
		</button>
		<button
			type="button"
			class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
			(click)="onClose()"
		>
			Abbrechen
		</button>
	</div>
</div>
