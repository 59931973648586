import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'wysiwyg',
})
export class WysiwygPipe implements PipeTransform {
	transform(value: string, ...args: unknown[]): unknown {
		// value = value.replace(/(?:\r\n|\r|\n)/g, '<br><br>');
		value = value.replace('```', '');
		return value.replace(/`+/g, '');
	}
}
