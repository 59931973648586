<div class="relative sm:w-full sm:max-w-lg">
	<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
		<h3 class="mb-2 text-lg font-medium leading-6 text-gray-900" id="modal-title">Informationen</h3>
		<ng-container *ngIf="subscriptions && subscriptions?.length > 0">
			<ul class="mb-4">
				<li class="mb-2 border-b border-solid border-gray-500 pb-2 text-sm text-gray-700" *ngFor="let sub of subscriptions">
					<span class="block"><strong>ID:</strong> {{ sub.id }}</span>
					<span class="block"><strong>Sub. Plan:</strong> {{ sub.plan.id }} - {{ sub.plan.name }}</span>
					<span class="block"><strong>Gültig bis:</strong> {{ sub.expiresAt | date }}</span>
				</li>
			</ul>
		</ng-container>
		<div class="bg-white sm:flex sm:flex-row-reverse">
			<button
				type="button"
				class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
				(click)="onClose()"
			>
				Schließen
			</button>
		</div>
	</div>
</div>
