<div class="relative sm:w-full sm:max-w-lg">
	<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
		<div class="sm:flex sm:items-start">
			<div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-400 sm:mx-0 sm:h-10 sm:w-10">
				<svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
					/>
				</svg>
			</div>
			<div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
        @if (type === 'paid') {
          <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title">Als bezahlt markieren?</h3>
          <div class="mt-2">
            <p class="text-sm text-gray-800">
              Bestellung wirklich als
              <span class="font-semibold text-red-500">bezahlt</span>
              markieren?
            </p>
          </div>
        }
        @if (type === 'shipped') {
          <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title-shipping">Bestellung verschickt</h3>
          <div class="mt-2 w-full">
            <label class="text-sm text-gray-800 block mb-1" for="trackingNumber">
              Sendungsverfolgungs-Nummer:
            </label>
            <input id="trackingNumber" class="w-full rounded-md bg-white border border-solid border-gray-600 text-sm text-gray-700 placeholder-gray-650 focus:border-blue-500" type="text" placeholder="Sendungsverfolgungs-Nummer">
          </div>
        }
        @if (type === 'refund') {
          <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title-refund">Erstattung durchführen</h3>
          <div class="mt-2 w-full">
            <p class="font-semibold text-gray-700 text-sm leading-none">Produkte:</p>
            <ul class="space-y-2 pb-4 pt-2">
              @for (item of items; track item.id) {
                <li class="flex flex-row items-center">
                  <label class="flex flex-row flex-1 items-center">
                    <input id="checked-{{item.id}}" class="w-4" type="checkbox" (change)="onSelectItem(item.id)">
                    <span class="w-56 truncate px-2 text-sm">
                      @if (item.purchasable.onlineTraining) {
                        {{item.purchasable.onlineTraining.product.title}}
                      } @else {
                        {{item.purchasable.title}}
                      }
                    </span>
                  </label>
                  <input id="quantity-{{item.id}}" class="w-16 rounded-md bg-white border border-solid border-gray-600 text-gray-700 placeholder-gray-650 focus:border-blue-500 text-xs" type="number" (change)="onSelectItem(item.id)" [max]="item.quantity" min="0">
                </li>
              }
            </ul>
            <p class="font-semibold text-gray-700 text-sm leading-none mb-2">Grund:</p>
            <select id="refund-reason" class="w-full cursor-pointer appearance-none rounded-lg border border-gray-500 bg-gray-300 px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none">
              <option [value]="'requested_by_customer'">Kundenwunsch</option>
              <option [value]="'fraudulent'">Betrug</option>
              <option [value]="'duplicate'">Doppelte Bestellung</option>
            </select>
          </div>
        }
			</div>
		</div>
	</div>
	<div class="bg-white px-4 pb-4 pt-3 sm:flex sm:flex-row-reverse sm:px-6 sm:pb-6">
		<button
			type="button"
			class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
			(click)="onDelete(id)"
		>
      @if (type === 'paid') {
        Ja, als bezahlt markieren
      }
      @if (type === 'shipped') {
        Ja, als verschickt markieren
      }
      @if (type === 'refund') {
        Ja, jetzt Erstattung durchführen
      }
		</button>
		<button
			type="button"
			class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
			(click)="onClose()"
		>
			Abbrechen
		</button>
	</div>
</div>
