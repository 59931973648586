<div class="absolute bottom-0 left-0 right-0 top-0 z-10 flex h-full min-h-screen w-full items-center justify-center bg-white opacity-75" *ngIf="loading">
	<div class="lds-spinner">
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</div>
</div>
