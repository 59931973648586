<app-header [title]=""></app-header>
<div class="flex w-full flex-row flex-wrap">
	<div class="sidebar relative h-sidebar bg-gray-1000">
		<button
			(click)="updateStyles($event)"
			class="sidebar-toogle fixed right-0 z-10 hidden h-5 w-5 origin-center transform appearance-none items-center justify-center rounded-full bg-gray-400 text-xs leading-none shadow transition-all duration-200 ease-in-out hover:bg-orange-500 hover:text-white md:flex"
			matTooltip="{{ tooltip }}"
			matTooltipPosition="right"
		>
			<fa-icon class="pointer-events-none" [icon]="faLeft"></fa-icon>
		</button>
		<div class="sidebar fixed bottom-0 top-0 mt-sidebar flex h-sidebar flex-col overflow-y-auto bg-gray-1000 p-4">
			<div class="relative w-full">
				<nav>
					<ul class="mb-6">
						<li class="mb-1 relative" [ngClass]="{'group': item.submenu?.length}" *ngFor="let item of nav" appAccessControl [accessRole]="item.roles">
              <a
                class="group flex items-center rounded-lg px-4 py-2 font-normal opacity-100 hover:bg-blue-600"
                [routerLink]="item.path"
                [routerLinkActive]="item.active"
              >
                <fa-icon class="mr-4 text-gray-650 group-hover:text-white" [icon]="item.icon"></fa-icon
                ><span class="label hidden text-gray-200 group-hover:text-white md:block">{{ item.name }}</span>
              </a>
              @if (item.submenu && item.submenu.length) {
                <div class="submenu hidden group-hover:block">
                  <ul class="relative bg-gray-1100 z-10 rounded-lg">
                    @for (subitem of item.submenu; track subitem.id) {
                      <li>
                        <a
                          class="group flex items-center rounded-lg px-4 py-2 font-normal opacity-100 hover:bg-blue-600"
                          [routerLink]="subitem.path"
                          [routerLinkActive]="subitem.active"
                        >
                          <fa-icon class="mr-4 text-gray-650 group-hover:text-white" [icon]="subitem.icon"></fa-icon
                          ><span class="label hidden text-gray-200 group-hover:text-white md:block">{{ subitem.name }}</span>
                        </a>
                      </li>
                    }
                  </ul>
                </div>
              }
						</li>
						<li>
							<hr class="my-2 h-px w-full border-0 bg-gray-700" />
						</li>
						<li class="mb-1" *ngFor="let item of navPlm" appAccessControl [accessRole]="item.roles">
							<a
								class="group flex items-center rounded-lg bg-transparent px-4 py-2 font-normal opacity-100 hover:bg-blue-600"
								[routerLink]="item.path"
								[routerLinkActive]="item.active"
							>
								<fa-icon class="mr-4 text-gray-650 group-hover:text-white" [icon]="item.icon"></fa-icon
								><span class="label hidden text-gray-200 group-hover:text-white md:block">{{ item.name }}</span>
							</a>
						</li>
					</ul>
				</nav>
			</div>
			<div class="mt-auto w-full px-4 py-2">
				<button class="flex appearance-none flex-row items-center border-0 bg-transparent text-sm font-medium text-white" (click)="logout()">
					<fa-icon class="mr-1" [icon]="faLogout"></fa-icon><span class="label hidden md:block">Logout</span>
				</button>
			</div>
		</div>
	</div>
	<div class="content">
		<router-outlet></router-outlet>
	</div>
</div>
