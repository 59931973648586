<aside class="fixed h-full min-h-screen w-sidebarActions bg-gray-800">
	<nav class="flex w-full justify-center pb-2 pt-4">
		<ul class="list-none">
			<li *ngFor="let nav of subnav" class="mb-2" appAccessControl [accessRole]="nav.roles">
				<ng-container *ngIf="nav.notLinked; else link">
					<span class="flex h-6 w-6 items-center justify-center text-gray-600 hover:text-white" [ngClass]="nav.class" matTooltip="{{ nav.label }}" matTooltipPosition="right">
						<fa-icon [icon]="nav.icon"></fa-icon>
					</span>
				</ng-container>
				<ng-template #link>
					<a
						class="flex h-6 w-6 items-center justify-center text-gray-600 hover:text-white"
						[ngClass]="nav.class"
						[routerLink]="nav.route"
						routerLinkActive="´text-white0"
						matTooltip="{{ nav.label }}"
						matTooltipPosition="right"
					>
						<fa-icon [icon]="nav.icon"></fa-icon>
					</a>
				</ng-template>
			</li>
		</ul>
	</nav>
</aside>
