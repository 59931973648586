import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Session } from '../../interfaces/session';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SessionDataService {
	private apiUrl = environment.API_URL;

	constructor(private http: HttpClient) {}

	logIn(email: string, password: string, twoFactorIdentifier?: string, twoFactorCode?: string): Observable<Session> {
		const url = `${this.apiUrl}auth/login`;
		return this.http.post<any>(url, {
			email,
			password,
			platform: 'plm',
			twoFactorIdentifier,
			twoFactorCode,
		});
	}
}
