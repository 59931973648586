<div class="relative sm:w-full sm:max-w-lg">
	<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
		<div class="sm:flex sm:items-start">
			<div class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-400 sm:mx-0 sm:h-10 sm:w-10">
				<svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
					/>
				</svg>
			</div>
			<div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
				<h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title">Belege herunterladen</h3>
				<div class="mt-4">
					<div class="mb-6 w-full">
						<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700" for="from"> Von<sup class="text-red-500">*</sup> </label>
						<input
							#from
							id="from"
							type="month"
							min="2010-01"
							placeholder="Datum eintragen"
							class="mb-3 block w-full appearance-none rounded-lg border border-gray-300 bg-gray-300 px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
						/>
					</div>
					<div class="mb-6 w-full">
						<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700" for="until"> Bis<sup class="text-red-500">*</sup> </label>
						<input
							#until
							id="until"
							type="month"
							min="2010-01"
							placeholder="Datum eintragen"
							class="mb-3 block w-full appearance-none rounded-lg border border-gray-300 bg-gray-300 px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
						/>
					</div>
					<p class="text-sm text-gray-800">Möchten Sie die definierten Belege wirklich herunterladen?</p>
				</div>
			</div>
		</div>
	</div>
	<div class="bg-white px-4 pb-4 pt-3 sm:flex sm:flex-row-reverse sm:px-6 sm:pb-6">
		<button
			type="button"
			class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
			(click)="onDownload(from.value, until.value)"
		>
			Ja, herunterladen
		</button>
		<button
			type="button"
			class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
			(click)="onClose()"
		>
			Abbrechen
		</button>
	</div>
</div>
