import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'statusColor',
})
export class StatusColorPipe implements PipeTransform {
	transform(value: string): string {
		switch (value) {
			case 'deleted':
			case 'canceled':
			case 'inactive':
			case 'finished':
			case 'cancelled':
				return 'bg-red-500 text-white';
			case 'outdated':
			case 'postponed':
			case 'refunded':
				return 'bg-orange-500 text-white';
			case 'in_progress':
			case 'preview':
			case 'started':
			case 'draft':
			case 'open':
			case 'pending':
				return 'bg-yellow-500 text-gray-700';
			case 'payed':
			case 'completed':
			default:
				return 'bg-green-500 text-white';
		}
	}
}
