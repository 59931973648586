import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-duplicate-content-dialog',
	templateUrl: './duplicate-content-dialog.component.html',
	styleUrls: ['./duplicate-content-dialog.component.css'],
})
export class DuplicateContentDialogComponent implements OnInit {
	public id: number;
	public title: string;

	constructor(
		public dialogRef: MatDialogRef<DuplicateContentDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data
	) {
		this.id = data.id;
		this.title = data.title;
	}

	ngOnInit(): void {}

	onDuplicate(id: number): void {
		this.dialogRef.close(id);
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
