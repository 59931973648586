<div class="relative bg-gray-1100 sm:w-full sm:max-w-lg">
	<div class="bg-gray-1100">
		<header class="bordrr-solid border-b border-gray-700 px-4 pb-4 pt-4 sm:px-6">
			<h2 class="text-sm font-medium text-gray-600">Was möchten Sie machen?</h2>
		</header>
		<main class="relative max-h-modalInner overflow-y-auto overflow-x-hidden">
			<nav>
				<ul>
					<li class="w-full">
						<button
							(click)="selectToDo('/dashboard/products')"
							class="flex w-full cursor-pointer appearance-none items-center border-0 bg-gray-1100 px-3 px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white focus:outline-none active:bg-gray-800 active:text-white sm:px-6"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
								<path
									d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 013.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0121 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 017.5 16.125V3.375z"
								/>
								<path
									d="M15 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0017.25 7.5h-1.875A.375.375 0 0115 7.125V5.25zM4.875 6H6v10.125A3.375 3.375 0 009.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V7.875C3 6.839 3.84 6 4.875 6z"
								/>
							</svg>
							<span class="ml-3 flex-auto truncate">Produkte anzeigen</span>
						</button>
					</li>
					<li class="w-full">
						<button
							(click)="selectToDo('/dashboard/products/add-product')"
							class="flex w-full cursor-pointer appearance-none items-center border-0 bg-gray-1100 px-3 px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white focus:outline-none active:bg-gray-800 active:text-white sm:px-6"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
								<path
									fill-rule="evenodd"
									d="M5.625 1.5H9a3.75 3.75 0 013.75 3.75v1.875c0 1.036.84 1.875 1.875 1.875H16.5a3.75 3.75 0 013.75 3.75v7.875c0 1.035-.84 1.875-1.875 1.875H5.625a1.875 1.875 0 01-1.875-1.875V3.375c0-1.036.84-1.875 1.875-1.875zM12.75 12a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V18a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V12z"
									clip-rule="evenodd"
								/>
								<path d="M14.25 5.25a5.23 5.23 0 00-1.279-3.434 9.768 9.768 0 016.963 6.963A5.23 5.23 0 0016.5 7.5h-1.875a.375.375 0 01-.375-.375V5.25z" />
							</svg>
							<span class="ml-3 flex-auto truncate">Produkte anlegen</span>
						</button>
					</li>
					<li class="w-full">
						<button
							(click)="selectToDo('/dashboard/users')"
							class="flex w-full cursor-pointer appearance-none items-center border-0 bg-gray-1100 px-3 px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white focus:outline-none active:bg-gray-800 active:text-white sm:px-6"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
								<path
									d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z"
								/>
							</svg>
							<span class="ml-3 flex-auto truncate">Benutzer anzeigen</span>
						</button>
					</li>
					<li class="w-full">
						<button
							(click)="selectToDo('/dashboard/users/add-user')"
							class="flex w-full cursor-pointer appearance-none items-center border-0 bg-gray-1100 px-3 px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white focus:outline-none active:bg-gray-800 active:text-white sm:px-6"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
								<path
									d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z"
								/>
							</svg>
							<span class="ml-3 flex-auto truncate">Benutzer anlegen</span>
						</button>
					</li>
					<li class="w-full">
						<button
							(click)="selectToDo('/dashboard/infoday')"
							class="flex w-full cursor-pointer appearance-none items-center border-0 bg-gray-1100 px-3 px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white focus:outline-none active:bg-gray-800 active:text-white sm:px-6"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
								<path
									d="M12.75 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM7.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM8.25 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM9.75 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM10.5 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM12.75 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM14.25 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 17.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 15.75a.75.75 0 100-1.5.75.75 0 000 1.5zM15 12.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM16.5 13.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
								/>
								<path
									fill-rule="evenodd"
									d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
									clip-rule="evenodd"
								/>
							</svg>
							<span class="ml-3 flex-auto truncate">Konferenzen anzeigen</span>
						</button>
					</li>
					<li class="w-full">
						<button
							(click)="selectToDo('/dashboard/infoday/add-conference')"
							class="flex w-full cursor-pointer appearance-none items-center border-0 bg-gray-1100 px-3 px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white focus:outline-none active:bg-gray-800 active:text-white sm:px-6"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
								<path
									fill-rule="evenodd"
									d="M7.502 6h7.128A3.375 3.375 0 0118 9.375v9.375a3 3 0 003-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 00-.673-.05A3 3 0 0015 1.5h-1.5a3 3 0 00-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6zM13.5 3A1.5 1.5 0 0012 4.5h4.5A1.5 1.5 0 0015 3h-1.5z"
									clip-rule="evenodd"
								/>
								<path
									fill-rule="evenodd"
									d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V9.375zm9.586 4.594a.75.75 0 00-1.172-.938l-2.476 3.096-.908-.907a.75.75 0 00-1.06 1.06l1.5 1.5a.75.75 0 001.116-.062l3-3.75z"
									clip-rule="evenodd"
								/>
							</svg>
							<span class="ml-3 flex-auto truncate">Konferenzen anlegen</span>
						</button>
					</li>
					<li class="w-full">
						<button
							(click)="selectToDo('/dashboard/customer')"
							class="flex w-full cursor-pointer appearance-none items-center border-0 bg-gray-1100 px-3 px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white focus:outline-none active:bg-gray-800 active:text-white sm:px-6"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
								<path
									fill-rule="evenodd"
									d="M8.25 6.75a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0zM15.75 9.75a3 3 0 116 0 3 3 0 01-6 0zM2.25 9.75a3 3 0 116 0 3 3 0 01-6 0zM6.31 15.117A6.745 6.745 0 0112 12a6.745 6.745 0 016.709 7.498.75.75 0 01-.372.568A12.696 12.696 0 0112 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 01-.372-.568 6.787 6.787 0 011.019-4.38z"
									clip-rule="evenodd"
								/>
								<path
									d="M5.082 14.254a8.287 8.287 0 00-1.308 5.135 9.687 9.687 0 01-1.764-.44l-.115-.04a.563.563 0 01-.373-.487l-.01-.121a3.75 3.75 0 013.57-4.047zM20.226 19.389a8.287 8.287 0 00-1.308-5.135 3.75 3.75 0 013.57 4.047l-.01.121a.563.563 0 01-.373.486l-.115.04c-.567.2-1.156.349-1.764.441z"
								/>
							</svg>
							<span class="ml-3 flex-auto truncate">Kunden anzeigen</span>
						</button>
					</li>
					<li class="w-full">
						<button
							(click)="selectToDo('/dashboard/customer/add-customer')"
							class="flex w-full cursor-pointer appearance-none items-center border-0 bg-gray-1100 px-3 px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white focus:outline-none active:bg-gray-800 active:text-white sm:px-6"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
								<path
									d="M6.25 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM3.25 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM19.75 7.5a.75.75 0 00-1.5 0v2.25H16a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0v-2.25H22a.75.75 0 000-1.5h-2.25V7.5z"
								/>
							</svg>
							<span class="ml-3 flex-auto truncate">Kunde anlegen</span>
						</button>
					</li>
					<li class="w-full">
						<button
							(click)="selectToDo('/dashboard/authors')"
							class="flex w-full cursor-pointer appearance-none items-center border-0 bg-gray-1100 px-3 px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white focus:outline-none active:bg-gray-800 active:text-white sm:px-6"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
								<path
									d="M4.5 6.375a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zM14.25 8.625a3.375 3.375 0 116.75 0 3.375 3.375 0 01-6.75 0zM1.5 19.125a7.125 7.125 0 0114.25 0v.003l-.001.119a.75.75 0 01-.363.63 13.067 13.067 0 01-6.761 1.873c-2.472 0-4.786-.684-6.76-1.873a.75.75 0 01-.364-.63l-.001-.122zM17.25 19.128l-.001.144a2.25 2.25 0 01-.233.96 10.088 10.088 0 005.06-1.01.75.75 0 00.42-.643 4.875 4.875 0 00-6.957-4.611 8.586 8.586 0 011.71 5.157v.003z"
								/>
							</svg>
							<span class="ml-3 flex-auto truncate">Autoren anzeigen</span>
						</button>
					</li>
					<li class="w-full">
						<button
							(click)="selectToDo('/dashboard/plans')"
							class="flex w-full cursor-pointer appearance-none items-center border-0 bg-gray-1100 px-3 px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white focus:outline-none active:bg-gray-800 active:text-white sm:px-6"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
								<path
									fill-rule="evenodd"
									d="M7.502 6h7.128A3.375 3.375 0 0118 9.375v9.375a3 3 0 003-3V6.108c0-1.505-1.125-2.811-2.664-2.94a48.972 48.972 0 00-.673-.05A3 3 0 0015 1.5h-1.5a3 3 0 00-2.663 1.618c-.225.015-.45.032-.673.05C8.662 3.295 7.554 4.542 7.502 6zM13.5 3A1.5 1.5 0 0012 4.5h4.5A1.5 1.5 0 0015 3h-1.5z"
									clip-rule="evenodd"
								/>
								<path
									fill-rule="evenodd"
									d="M3 9.375C3 8.339 3.84 7.5 4.875 7.5h9.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 013 20.625V9.375zm9.586 4.594a.75.75 0 00-1.172-.938l-2.476 3.096-.908-.907a.75.75 0 00-1.06 1.06l1.5 1.5a.75.75 0 001.116-.062l3-3.75z"
									clip-rule="evenodd"
								/>
							</svg>
							<span class="ml-3 flex-auto truncate">Subscription Pläne anzeigen</span>
						</button>
					</li>
					<li class="w-full">
						<button
							(click)="selectToDo('/dashboard/push-notifications')"
							class="flex w-full cursor-pointer appearance-none items-center border-0 bg-gray-1100 px-3 px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white focus:outline-none active:bg-gray-800 active:text-white sm:px-6"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
								<path
									d="M5.85 3.5a.75.75 0 00-1.117-1 9.719 9.719 0 00-2.348 4.876.75.75 0 001.479.248A8.219 8.219 0 015.85 3.5zM19.267 2.5a.75.75 0 10-1.118 1 8.22 8.22 0 011.987 4.124.75.75 0 001.48-.248A9.72 9.72 0 0019.266 2.5z"
								/>
								<path
									fill-rule="evenodd"
									d="M12 2.25A6.75 6.75 0 005.25 9v.75a8.217 8.217 0 01-2.119 5.52.75.75 0 00.298 1.206c1.544.57 3.16.99 4.831 1.243a3.75 3.75 0 107.48 0 24.583 24.583 0 004.83-1.244.75.75 0 00.298-1.205 8.217 8.217 0 01-2.118-5.52V9A6.75 6.75 0 0012 2.25zM9.75 18c0-.034 0-.067.002-.1a25.05 25.05 0 004.496 0l.002.1a2.25 2.25 0 11-4.5 0z"
									clip-rule="evenodd"
								/>
							</svg>
							<span class="ml-3 flex-auto truncate">Push Notifications anzeigen</span>
						</button>
					</li>
					<li class="w-full">
						<button
							(click)="selectToDo('/dashboard/push-notifications/add')"
							class="flex w-full cursor-pointer appearance-none items-center border-0 bg-gray-1100 px-3 px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white focus:outline-none active:bg-gray-800 active:text-white sm:px-6"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
								<path
									fill-rule="evenodd"
									d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z"
									clip-rule="evenodd"
								/>
							</svg>
							<span class="ml-3 flex-auto truncate">Push Notification anlegen</span>
						</button>
					</li>
					<li class="w-full">
						<button
							(click)="selectToDo('/dashboard/media')"
							class="flex w-full cursor-pointer appearance-none items-center border-0 bg-gray-1100 px-3 px-4 py-2 text-left text-sm text-gray-600 hover:bg-gray-800 hover:text-white focus:bg-gray-800 focus:text-white focus:outline-none active:bg-gray-800 active:text-white sm:px-6"
						>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="h-6 w-6">
								<path
									fill-rule="evenodd"
									d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
									clip-rule="evenodd"
								/>
							</svg>
							<span class="ml-3 flex-auto truncate">Medien anzeigen</span>
							<!--
              <span class="ml-3 flex-none text-xs font-semibold text-gray-400"><kbd class="font-sans">⌘</kbd><kbd class="font-sans">N</kbd></span>
              -->
						</button>
					</li>
				</ul>
			</nav>
		</main>
	</div>
</div>
