<article class="group block w-full transform cursor-pointer rounded-md bg-white shadow-lg transition-all duration-700 ease-in-out hover:-translate-y-1 hover:shadow-2xl">
	<div class="relative h-48 w-full bg-blue-200">
		<div class="absolute right-0 top-0 mr-4 mt-4 rounded-full bg-gray-300 px-6 py-1 text-xs font-medium text-gray-700 shadow">
			{{ content.language }}
		</div>
	</div>
	<div class="flex flex-col p-4 md:p-6">
		<h2 class="mb-2 text-lg font-medium leading-tight text-gray-700">
			{{ content.title }}
		</h2>
		<p class="text-xs text-gray-600">Referent: Max Mustermann</p>
		<p class="text-xs text-gray-600">Firma: Espresso Tutorials GmbH</p>
		<button
			class="mt-8 block w-full transform appearance-none rounded bg-blue-500 px-8 py-2 text-sm text-white shadow transition-all duration-700 ease-in-out group-hover:bg-orange-500"
		>
			Anmelden
		</button>
	</div>
</article>
