import { inject, InjectionToken } from '@angular/core';
import { environment } from '../../../environments/environment';

const ENVIRONMENT = new InjectionToken<any>('app env', {
	providedIn: 'root',
	factory: () => environment,
});

export const API_URL = new InjectionToken<string>('api url', {
	providedIn: 'root',
	factory: () => {
		const env = inject(ENVIRONMENT);

		return env.API_URL + 'admin/';
	},
});

export const API_URL_PUBLIC = new InjectionToken<string>('api url public', {
	providedIn: 'root',
	factory: () => {
		const env = inject(ENVIRONMENT);

		return env.API_URL;
	},
});

export const FUNCTIONS_URL = new InjectionToken<string>('functions url', {
	providedIn: 'root',
	factory: () => {
		const env = inject(ENVIRONMENT);

		return env.FUNCTIONS_URL;
	},
});

export const REDIRECT_URL = new InjectionToken<string>('redirect url', {
	providedIn: 'root',
	factory: () => {
		const env = inject(ENVIRONMENT);

		return env.REDIRECT_URL;
	},
});
