<div class="relative max-h-modal sm:w-full sm:max-w-lg">
	<form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()" novalidate>
		<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
			<div class="sticky top-0 z-10 w-full bg-white">
				<h3 class="mb-2 text-lg font-medium leading-6 text-gray-900" id="modal-title">Fragen importieren</h3>
			</div>
			<div class="relative max-h-modalInner overflow-y-auto overflow-x-hidden">
				<p class="mb-4 text-sm">
					Sie können Fragen gesammelt via CSV importieren und dem Zertifikat automatisiert hinzufügen. Bitte nutzen Sie die von uns bereitgestellte Beispiel CSV oder das angegebene
					Schema für Ihre CSV um einen korrekten Import durchzuführen. Es ist zwingend erforderlich, dass die CSV im UTF-8 Format formatiert und Kommasepariert ist.
				</p>
				<p class="mb-1 text-sm font-semibold">Benötigter Aufbau CSV:</p>
				<div class="flex flex-row flex-wrap bg-gray-100">
					<div class="w-1/7 border-r border-solid border-gray-500 p-2 text-center text-xxs text-gray-650">Frage</div>
					<div class="w-1/7 border-r border-solid border-gray-500 p-2 text-center text-xxs text-gray-650">Hinweis</div>
					<div class="w-1/7 border-r border-solid border-gray-500 p-2 text-center text-xxs text-gray-650">Antwort</div>
					<div class="w-1/7 border-r border-solid border-gray-500 p-2 text-center text-xxs text-gray-650">Korrekt</div>
					<div class="w-1/7 border-r border-solid border-gray-500 p-2 text-center text-xxs text-gray-650">Antwort</div>
					<div class="w-1/7 p-2 text-center text-xxs text-gray-650">Korrekt<sup>*</sup></div>
					<div class="w-1/7 p-2 text-center text-xxs text-gray-650">…</div>
				</div>
				<p class="mb-2 mt-1 text-xs text-gray-650">* Falls korrekt 1 eintragen, falls nicht leer lassen</p>
				<p class="mb-8 text-sm">
					<a class="text-blue-500 transition-all duration-200 hover:text-orange-500" href="assets/downloads/et-certs-import.csv" download target="_blank"
						>Beispiel csv herunterladen</a
					>
				</p>
				<p class="mb-1 text-sm font-semibold">CSV importieren</p>
				<div>
					<app-file-upload formControlName="file" [progress]="0"></app-file-upload>
				</div>
				<div class="py-2 pl-1">
					<label for="overwrite" class="flex cursor-pointer items-center text-sm leading-none text-gray-650">
						<input type="checkbox" id="overwrite" formControlName="overwrite" />
						<span class="ml-1">Vorhandene Fragen überschreiben?</span>
					</label>
				</div>
			</div>
		</div>
		<div class="sticky bottom-0 bg-white px-4 pb-4 pt-3 sm:flex sm:flex-row-reverse sm:px-6 sm:pb-6">
			<button
				type="submit"
				class="mt-3 inline-flex w-full justify-center rounded-md border border-blue-500 bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:border-orange-500 hover:bg-orange-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
			>
				Import starten
			</button>
			<div
				type="button"
				class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
				(click)="onClose()"
			>
				Abbrechen
			</div>
		</div>
	</form>
</div>
