import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { faFolder, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { environment } from '../../../../environments/environment';

@Component({
	selector: 'app-media-object',
	templateUrl: './media-object.component.html',
	styleUrls: ['./media-object.component.scss'],
})
export class MediaObjectComponent implements OnInit, AfterViewInit {
	public mediaUrl = environment.API_URL;

	@Input() mediaObject?: any;
	@Input() controls: boolean;
	@Input() reset?: boolean;
	// tslint:disable-next-line:no-output-native
	@Output() select = new EventEmitter();
	@Output() view = new EventEmitter();

	public placeholder = 'https://et-api-6u2ez.ondigitalocean.app/attachments/1';

	faTrash = faTrash;
	faFolder = faFolder;

	public isFolder: boolean;

	constructor() {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {}

	selectElement(event: any, id: string): any {
		const checkbox = document.getElementById(id);

		// @ts-ignore
		if (checkbox.checked) {
			// @ts-ignore
			checkbox.checked = false;
			this.select.emit({ typ: 'remove', id });
		} else {
			// @ts-ignore
			checkbox.checked = true;
			this.select.emit({ typ: 'add', id });
		}
	}

	selectElementDialog(event: any, type?: string): any {
		if (type === 'folder') {
			this.select.emit({
				id: this.mediaObject.id,
				type: 'folder',
				name: this.mediaObject.name,
			});
			this.isFolder = true;
		} else {
			this.select.emit({
				id: this.mediaObject.id,
				url: this.mediaObject.url,
				type: '',
			});
		}
	}

	viewDetail(mediaObject: any): any {
		this.view.emit({
			id: this.mediaObject.id,
			folder: this.mediaObject.directory,
			attachment: mediaObject,
		});
	}
}
