import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { DirtyStore, DirtyState } from './dirty.store';
import { Dirty } from '../../interfaces/dirty';

@Injectable({ providedIn: 'root' })
export class DirtyQuery extends QueryEntity<DirtyState, Dirty> {
	constructor(protected store: DirtyStore) {
		super(store);
	}

	isDirty(): any {
		return this.getValue().dirty;
	}
}
