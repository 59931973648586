import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { fromEvent, Subscription } from 'rxjs';
import { Product } from '../../interfaces/product';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-select-product-dialog',
	templateUrl: './select-product-dialog.component.html',
	styleUrls: ['./select-product-dialog.component.css'],
})
export class SelectProductDialogComponent implements OnInit, OnDestroy, AfterViewInit {
	public ids: any;
	public showSelection?: boolean = false;
	public products: Array<Product>;
	public searchStr: string;
	public selectedProducts: any;

	faAdd = faPlusCircle;

	private subscription: Subscription;
	private subscriptionSearch: Subscription;

	@ViewChild('search') search: ElementRef;

	constructor(
		public dialogRef: MatDialogRef<SelectProductDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private apiService: ApiService,
		private snackBar: MatSnackBar
	) {
		this.ids = data.ids;
		this.showSelection = data.showSelection;
	}

	ngOnInit(): void {
		this.initData();
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		if (this.subscriptionSearch) {
			this.subscriptionSearch.unsubscribe();
		}
	}

	ngAfterViewInit(): void {
		this.initSearch();
	}

	// Search data
	initSearch(): void {
		this.subscriptionSearch = fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				filter(Boolean),
				debounceTime(250),
				distinctUntilChanged(),
				tap(() => {
					this.searchStr = this.search.nativeElement.value;
				})
			)
			.subscribe();
	}

	initData(): void {
		this.subscription = this.apiService.getData('product/products/select?include=partner&status=active,outdated').subscribe(
			(rest) => {
				rest.data.forEach((el) => {
					el.title = `${el.id} - ${el.title}`;
				});
				this.products = rest.data;
				this.initList();
			},
			() => {}
		);
	}

	initList(): void {
		if (this.ids) {
			this.products.forEach((el: any) => {
				const id = (obj) => obj.id === el.id;
				el.checked = !!this.ids.some(id);
				if (!!this.ids.some(id)) {
					const result = this.ids.find((obj) => {
						return obj.id === el.id;
					});
					el.bookmark = result.bookmark ? result.bookmark : '';
				}
			});
			this.updateProds();
		}
	}

	updateProds(): void {
		const selectedProducts: any[] = [];
		this.products.forEach((el: any) => {
			if (el.checked === true) {
				selectedProducts.push(el);
			}
		});

		this.selectedProducts = selectedProducts;
	}

	addSection(value: any, id: any): void {
		this.selectedProducts.forEach((el: any) => {
			if (el.id === id) {
				el.bookmark = value;
				this.openSnackBar('Kaptitel hinzugefügt, bitte speichern Sie Ihre Änderungen.');
			}
		});
	}

	onSave(): void {
		this.dialogRef.close(this.selectedProducts);
	}

	onClose(): void {
		this.dialogRef.close();
	}

	// Display Snackbar
	openSnackBar(message: string): any {
		this.snackBar.open(message, 'OK', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
		});
	}
}
