<div class="px-4 pb-6">
	<nav id="breadcrumb">
		<ul class="flex flex-row items-center text-sm">
			<li class="mr-2">
				<a class="inline-flex items-center font-medium text-gray-700 transition-colors duration-200 ease-in-out hover:text-blue-500" routerLink="/dashboard" title="Dashboard">
					<i class="mr-2"><fa-icon [icon]="faHome"></fa-icon></i>
					<span>Dashboard</span>
				</a>
			</li>
			<ng-container *ngFor="let bc of breadcrumbs; let last = last">
				<li class="mr-2 text-gray-650"><fa-icon [icon]="faRight"></fa-icon></li>
				<li>
					<ng-container *ngIf="!last; else isLast">
						<a [routerLink]="bc.link" class="mr-2 font-medium text-gray-700 transition-colors duration-200 ease-in-out hover:text-blue-500" [title]="bc.label">
							{{ bc.label }}
						</a>
					</ng-container>
					<ng-template #isLast>
						<span class="font-medium">
							{{ bc.label }}
						</span>
					</ng-template>
				</li>
			</ng-container>
		</ul>
	</nav>
</div>
