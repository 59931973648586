import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { faAngleRight, faHouse } from '@fortawesome/pro-solid-svg-icons';
import { routes } from '../../../pages/dashboard/dashboard-routing.module';
import { Subscription } from 'rxjs';

export interface Breadcrumb {
	label: string;
	link: string;
}

@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
	breadcrumbs: Breadcrumb[] = [];
	faRight = faAngleRight;
	faHome = faHouse;

	private subscriptions: Subscription[] = [];

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
		this.router.events.subscribe((ev) => {
			if (ev instanceof NavigationEnd) {
				this.breadcrumbs = [];
				this.buildBreadcrumb(this.activatedRoute);
			}
		});
	}

	ngOnInit(): void {}

	ngOnDestroy(): void {
		if (this.subscriptions && this.subscriptions.length > 0) {
			this.subscriptions.forEach((subscription) => {
				subscription.unsubscribe();
			});
		}
	}

	buildBreadcrumb(currentAR: ActivatedRoute) {
		this.subscriptions.push(
			this.activatedRoute.parent.url.subscribe((urlPath) => {
				if (urlPath && urlPath.length > 1) {
					urlPath.forEach((el: UrlSegment, idx: number) => {
						{
							let path = el.path;
							if (urlPath[idx - 1]) {
								if (this.removeTrailingSlash(urlPath[idx - 1].path) !== this.removeTrailingSlash(path)) {
									path = `${urlPath[idx - 1].path}/${path}`;
								}
							}

							const result = routes[0].children.find((obj) => {
								return obj.path === path;
							});

							if (result) {
								this.breadcrumbs.push({
									label: result.data.breadcrumb.label,
									link: `/dashboard/${result.path}`,
								} as Breadcrumb);
							}
						}
					});
				}

				if (currentAR.snapshot.data.breadcrumb) {
					const lastBCLink = this.breadcrumbs.length !== 0 ? this.breadcrumbs[this.breadcrumbs.length - 1].link : '';
					this.breadcrumbs.push({
						label: currentAR.snapshot.data.breadcrumb.label,
						link: lastBCLink + '/' + currentAR!.routeConfig!.path,
					} as Breadcrumb);
				}

				if (currentAR.firstChild !== null) {
					this.buildBreadcrumb(currentAR.firstChild);
				}

				const uniqueIds = new Set();

				this.breadcrumbs = this.breadcrumbs.filter((element) => {
					const isDuplicate = uniqueIds.has(element.label);

					uniqueIds.add(element.label);

					if (!isDuplicate) {
						return true;
					}

					return false;
				});
				// console.log(this.breadcrumbs);
			})
		);
	}

	removeTrailingSlash(str): string {
		return str.endsWith('/') ? str.slice(0, -1) : str;
	}
}
