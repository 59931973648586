<div class="w-full bg-gray-300">
	<div class="flex w-full flex-row flex-wrap bg-white">
		<app-subnav *ngIf="subnav" [subnav]="subnav"></app-subnav>
		<main class="w-contentActions bg-white py-4 pl-12 md:pb-8">
			<div *ngIf="live" class="-mt-4 mb-2 border border-solid border-red-500 bg-red-400 px-3 py-2 text-xs font-semibold text-white">
				<span class="pr-1 font-bold">Achtung:</span> Sie befinden sich auf dem Production System!
			</div>
			<app-breadcrumb></app-breadcrumb>
			<ng-content></ng-content>
		</main>
	</div>
</div>
