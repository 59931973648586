import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'ratingCriteria',
})
export class RatingCriteriaPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		if (value === 'conference_session') {
			return 'Konferenz';
		} else if (value === 'library_product') {
			return 'Bibliothek';
		} else {
			return 'Keine';
		}
	}
}
