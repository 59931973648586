import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-leave-dialog',
	templateUrl: './leave-dialog.component.html',
	styleUrls: ['./leave-dialog.component.css'],
})
export class LeaveDialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<LeaveDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data
	) {}

	ngOnInit(): void {}

	onLeave(): void {
		this.dialogRef.close(true);
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
