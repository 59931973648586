import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Attachment } from '../../interfaces/attachment';
import { faArrowUpRightFromSquare, faDownload, faXmark } from '@fortawesome/pro-light-svg-icons';

@Component({
	selector: 'app-attachment-dialog',
	templateUrl: './attachment-dialog.component.html',
	styleUrls: ['./attachment-dialog.component.css'],
})
export class AttachmentDialogComponent implements OnInit, AfterViewInit {
	public attachment: Attachment;
	public dimensions: any;
	public loading: boolean;

	faClose = faXmark;
	faDownload = faDownload;
	faTab = faArrowUpRightFromSquare;

	constructor(
		public dialogRef: MatDialogRef<AttachmentDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private cd: ChangeDetectorRef
	) {
		this.attachment = data.attachment;
	}

	ngOnInit(): void {
		this.loading = true;
	}

	ngAfterViewInit(): void {
		this.dimensions = this.getImageDimensions();
		this.cd.detectChanges();
	}

	onClose(): void {
		this.dialogRef.close();
	}

	getImageDimensions(): any {
		const img = document.querySelector('.asset-image');
		let size = {};
		// @ts-ignore
		if (img && img.complete) {
			size = {
				// @ts-ignore
				width: img.naturalWidth,
				// @ts-ignore
				height: img.naturalHeight,
			};
			return size;
		} else {
			img.addEventListener('load', () => {
				size = {
					// @ts-ignore
					width: img.naturalWidth,
					// @ts-ignore
					height: img.naturalHeight,
				};
				return size;
			});
		}
	}
}
