import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { Integrations } from '@sentry/tracing';

if (environment.production) {
	enableProdMode();
}

Sentry.init({
	dsn: environment.production ? 'https://7e2fbeff30124de893e829ea5379bc87@o172773.ingest.sentry.io/5693510' : null,
	environment: environment.production ? 'prod' : 'dev',
	release: 'admin@' + environment.VERSION,
	ignoreErrors: ['Non-Error exception captured'],
	integrations: [
		new Integrations.BrowserTracing({
			tracingOrigins: ['localhost', 'admin.espresso-tutorials.com', environment.API_URL],
			routingInstrumentation: Sentry.routingInstrumentation,
		}),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: environment.TRACING,
});

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
