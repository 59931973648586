import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root',
})
export class VersioningService {
	private banners: any[] = [];
	private storageKey: string = 'dev';

	constructor(private cookieService: CookieService) {}

	getVersionKey(): string {
		if (environment.production) {
			this.storageKey = 'prod';
		}

		return this.storageKey;
	}

	setVersion(): void {
		this.cookieService.delete(`EtPlm_${this.getVersionKey()}_version`);
		this.cookieService.set(`EtPlm_${this.getVersionKey()}_version`, environment.VERSION, 365, '/', '', environment.production, 'Strict');
	}

	getActualVersion(): string {
		return environment.VERSION;
	}

	getVersion(): string {
		return this.cookieService.get(`EtPlm_${this.getVersionKey()}_version`);
	}

	deleteVersion(): void {
		this.cookieService.delete(`EtPlm_${this.getVersionKey()}_version`);
	}

	addVersionBanner(banner: any): void {
		this.banners.push(banner);
	}

	removeVersionBanner(id: string): void {
		this.banners = this.banners.filter((x) => x.id !== id);
	}

	open(id: string): void {
		const banner = this.banners.find((x) => x.id === id);
		banner.open();
	}

	close(id: string): void {
		const banner = this.banners.find((x) => x.id === id);
		banner.close();
	}

	async handleHardReload(url) {
		await fetch(url, {
			headers: {
				Pragma: 'no-cache',
				Expires: '-1',
				'Cache-Control': 'no-cache',
			},
		});
		window.location.href = url;
		window.location.reload();
	}
}
