import { Inject, Injectable } from '@angular/core';
import { SessionDataService, SessionQuery, SessionStore } from '../session/state';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { API_URL_PUBLIC } from '../injection/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PusherService } from './pusher.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		@Inject(API_URL_PUBLIC) private apiUrl: string,
		private authStore: SessionStore,
		private authDataService: SessionDataService,
		private router: Router,
		private http: HttpClient,
		private pusherService: PusherService,
		private sessionQuery: SessionQuery
	) {}

	logIn(email: string, password: string, twoFactorIdentifier?: string, twoFactorCode?: string): any {
		return this.authDataService.logIn(email, password, twoFactorIdentifier, twoFactorCode).pipe(
			tap((session) => {
				// @ts-ignore
				this.authStore.login(session);
			})
		);
	}

	logout(): any {
		const id = this.sessionQuery.getUserId();
		this.pusherService.unsubscribe(`notifications.${id}`);
		this.authStore.logout();
		this.router.navigateByUrl('').then();
	}

	/**
	 * Trigger forgot password mail
	 * @param email type: string
	 */
	forgotPassword(email: string): Observable<any> {
		const url = `${this.apiUrl}auth/forgot-password`;
		const mail = {
			email: email,
			platform: 'plm',
		};

		return this.http.post<any>(url, mail);
	}

	/**
	 * Trigger forgot password mail
	 * @param email type: string
	 * @param expires type: number
	 * @param token type: string
	 * @param password type: string
	 * @param password_confirmation type: string
	 */
	resetPassword(email: string, expires: number, token: string, password: string, password_confirmation: string): Observable<any> {
		const url = `${this.apiUrl}auth/reset-password`;
		return this.http
			.post<any>(url, {
				email,
				expires,
				token,
				password,
				password_confirmation,
			})
			.pipe(tap(() => {}));
	}
}
