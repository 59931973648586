import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SessionService } from '../../session/state';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
	loginForm: UntypedFormGroup;

	private subscription: Subscription;

	constructor(
		private authService: SessionService,
		private router: Router
	) {}

	ngOnInit(): void {
		this.loginForm = new UntypedFormGroup({
			email: new UntypedFormControl(''),
			password: new UntypedFormControl(''),
		});
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	submit(): void {
		if (this.loginForm.valid) {
			this.subscription = this.authService.login(this.loginForm.value).subscribe(() => {
				this.router.navigateByUrl('').then();
			});
		}
	}
}
