import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import * as storage from '../storage';

export type SessionState = {
	accessToken: string;
	roles: string;
	familyName: string;
	givenName: string;
	image: string;
	mail: string;
	id: string;
	language: string;
};

export function createInitialSessionState(): SessionState {
	return {
		accessToken: null,
		roles: null,
		familyName: null,
		givenName: null,
		image: null,
		mail: null,
		language: null,
		id: null,
		// name: null,
		// role: null,
		...storage.getSession(),
	};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session' })
export class SessionStore extends Store<SessionState> {
	constructor(private translateService: TranslocoService) {
		super(createInitialSessionState());
	}

	login(session: any): any {
		const accessToken = session.meta.accessToken;
		const roles = session.data.roles;
		const familyName = session.data.familyName;
		const givenName = session.data.givenName;
		const image = session.data.image?.url ? session.data.image?.url : '';
		const mail = session.data.email;
		const id = session.data.id;
		let language = session.data.language;
		if (!language || language !== 'de') {
			language = 'en';
		}

		this.translateService.setActiveLang(language);
		localStorage.setItem('plmAppV8_language', language);

		const creds = {
			accessToken,
			roles,
			familyName,
			givenName,
			image,
			mail,
			id,
			language,
		};

		this.update(creds);
		storage.saveSession(creds);
	}

	logout(): any {
		storage.clearSession();
		this.update(createInitialSessionState());
	}
}
