import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
	selector: 'app-terminal-dialog',
	templateUrl: './terminal-dialog.component.html',
	styleUrls: ['./terminal-dialog.component.scss'],
})
export class TerminalDialogComponent implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<TerminalDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private router: Router
	) {}

	ngOnInit(): void {}

	onLeave(): void {
		this.dialogRef.close(true);
	}

	onClose(): void {
		this.dialogRef.close();
	}

	async selectToDo(route: string): Promise<any> {
		await this.router.navigateByUrl(route);
		this.onClose();
	}
}
