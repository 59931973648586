import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { DatePipe } from '@angular/common';
import { SuccessHandlerService } from '../../services/success-handler.service';
import { faBug, faThumbsUp } from '@fortawesome/pro-solid-svg-icons';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-add-custom-reader-element',
	templateUrl: './add-custom-reader-element.component.html',
	styleUrls: ['./add-custom-reader-element.component.scss'],
})
export class AddCustomReaderElementComponent implements OnInit, OnDestroy {
	public element: string;

	public form: UntypedFormGroup;
	public isValidFormSubmitted = null;
	public error: any;
	public subscription: Subscription;

	faSuccess = faThumbsUp;
	faBug = faBug;

	constructor(
		public dialogRef: MatDialogRef<AddCustomReaderElementComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private fb: UntypedFormBuilder,
		private apiService: ApiService,
		private datePipe: DatePipe,
		private successHandlerService: SuccessHandlerService
	) {
		this.element = data.element ? data.element : null;
	}

	ngOnInit(): void {
		this.initForm();
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	initForm(): void {
		this.form = this.fb.group({
			abap_editor_headline: ['', []],
			newsletter_headline: ['', []],
			conference_id: ['', []],
			commerical_text: ['', []],
			commerical_btn_label: ['', []],
			commerical_btn_url: ['', []],
			author_text: ['', []],
			author_btn_label: ['', []],
			author_btn_url: ['', []],
			youtube_headline: ['', []],
			youtube_embed: ['', []],
			vimeo_headline: ['', []],
			vimeo_embed: ['', []],
			download_text: ['', []],
			download_btn_label: ['', []],
			download_btn_url: ['', []],
		});
	}

	submitForm(): void {
		let customElement;
		if (this.element && this.element === 'abap_editor') {
			customElement = `<section class="abap_editor"><p class="abap_editor_headline">${this.form.value.abap_editor_headline}</p><iframe class="editor" height="400px" width="100%" src="https://abapeditor.com/#/id/f96ac34e271a52756095e7cde2cb1c9078"></iframe></section>`;
			this.successHandlerService.successHandler('Element wurde erstellt und kopiert, Sie können es nun in den Editor einfügen.');
			this.dialogRef.close(customElement);
		} else if (this.element && this.element === 'conference') {
			this.subscription = this.apiService
				.getItem('conference/conferences', this.form.value.conference_id, '?include=partner,attendees,image,sessions,sessions.speakers,sponsors,sponsors.logo')
				.subscribe(
					(resp) => {
						customElement = `<section class="conference"><a class="conference__link" href="https://infoday.io" target="_blank" rel="noopener"><p class="conference__date">${this.datePipe.transform(
							resp.data.startsAt,
							'dd.MM.yy, hh:mm'
						)}${resp.data.endsAt ? ' - ' + this.datePipe.transform(resp.data.endsAt, 'dd.MM.yy, hh:mm') : ''}</p><p class="conference__headline">${resp.data.title}</p>${
							resp.data.description
						}</a></section>`;
						this.successHandlerService.successHandler('Element wurde erstellt und kopiert, Sie können es nun in den Editor einfügen.');
						this.dialogRef.close(customElement);
					},
					(err) => {
						console.log(err);
					}
				);
		} else if (this.element && this.element === 'commercial') {
			customElement = `<section class="commercial"><p class="commercial__text">${this.form.value.commerical_text}</p><p class="commercial__cta"><a href="${this.form.value.commerical_btn_url}" target="_blank" rel="noopener">${this.form.value.commerical_btn_label}</a></p></section>`;
			this.successHandlerService.successHandler('Element wurde erstellt und kopiert, Sie können es nun in den Editor einfügen.');
			this.dialogRef.close(customElement);
		} else if (this.element && this.element === 'author') {
			customElement = `<section class="author"><p><span class="author__text">${this.form.value.author_text}</span><span><a class="author__link" href="${this.form.value.author_btn_url}" target="_blank" rel="noopener">${this.form.value.author_btn_label}</a></span></p></section>`;
			this.successHandlerService.successHandler('Element wurde erstellt und kopiert, Sie können es nun in den Editor einfügen.');
			this.dialogRef.close(customElement);
		} else if (this.element && this.element === 'youtube') {
			customElement = `<section class="youtube"><p class="youtube__headline">${this.form.value.youtube_headline}</p><iframe src="https://www.youtube.com/embed/${this.form.value.youtube_embed}" loading="lazy" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></section>`;
			this.successHandlerService.successHandler('Element wurde erstellt und kopiert, Sie können es nun in den Editor einfügen.');
			this.dialogRef.close(customElement);
		} else if (this.element && this.element === 'vimeo') {
			customElement = `<section class="vimeo"><p class="vimeo__headline">${this.form.value.vimeo_headline}</p><iframe src="https://player.vimeo.com/video/${this.form.value.vimeo_embed}?dnt=1" loading="lazy" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></section>`;
			this.successHandlerService.successHandler('Element wurde erstellt und kopiert, Sie können es nun in den Editor einfügen.');
			this.dialogRef.close(customElement);
		} else if (this.element && this.element === 'download') {
			customElement = `<section class="download"><p><span class="download__text">${this.form.value.download_text}</span><span><a class="download__link" href="${this.form.value.download_btn_url}" target="_blank" rel="noopener">${this.form.value.download_btn_label}</a></span></p></section>`;
			this.successHandlerService.successHandler('Element wurde erstellt und kopiert, Sie können es nun in den Editor einfügen.');
			this.dialogRef.close(customElement);
		} else {
			this.successHandlerService.successHandler('Es wurde kein Element erstellt, bitte versuchen Sie es erneut.');
		}
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
