import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ShellComponent } from './shell/shell.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedAllModule } from '../shareAllmodule';
import { LoginComponent } from './login/login.component';
import { TranslocoRootModule } from '../../transloco/transloco-root.module';
import { CardComponent } from './card/card.component';
import { ListComponent } from './list/list.component';
import { SubnavComponent } from './subnav/subnav.component';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { AddSubscriptionDialogComponent } from '../dialogs/add-subscription-dialog/add-subscription-dialog.component';
import { LoadingComponent } from './loading/loading.component';
import { MediaObjectComponent } from './media-object/media-object.component';
import { SelectImageDialogComponent } from '../dialogs/select-image-dialog/select-image-dialog.component';
import { UserDialogComponent } from '../dialogs/user-dialog/user-dialog.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { UploadImageDialogComponent } from '../dialogs/upload-image-dialog/upload-image-dialog.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { AddFolderDialogComponent } from '../dialogs/add-folder-dialog/add-folder-dialog.component';
import { SelectProductDialogComponent } from '../dialogs/select-product-dialog/select-product-dialog.component';
import { FormsModule } from '@angular/forms';
import { SelectAccountDialogComponent } from '../dialogs/select-account-dialog/select-account-dialog.component';
import { AttachmentDialogComponent } from '../dialogs/attachment-dialog/attachment-dialog.component';
import { AddCustomReaderElementComponent } from '../dialogs/add-custom-reader-element/add-custom-reader-element.component';
import { ImportUserDialogComponent } from '../dialogs/import-user-dialog/import-user-dialog.component';
import { SelectPlanDialogComponent } from '../dialogs/select-plan-dialog/select-plan-dialog.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { AppsComponent } from './apps/apps.component';
import { TerminalComponent } from './terminal/terminal.component';
import { ImportCertsDialogComponent } from '../dialogs/import-certs-dialog/import-certs-dialog.component';
import { VersioningBannerComponent } from './versioning-banner/versioning-banner.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { PaginationComponent } from './pagination/pagination.component';
import { GenerateDescriptionComponent } from '../dialogs/generate-description/generate-description.component';

const components = [
	ShellComponent,
	HeaderComponent,
	FooterComponent,
	LoginComponent,
	CardComponent,
	ListComponent,
	SubnavComponent,
	FileUploadComponent,
	AddSubscriptionDialogComponent,
	SelectImageDialogComponent,
	LoadingComponent,
	UserDialogComponent,
	MediaObjectComponent,
	StarRatingComponent,
	UploadImageDialogComponent,
	SkeletonComponent,
	AddFolderDialogComponent,
	SelectProductDialogComponent,
	SelectAccountDialogComponent,
	AttachmentDialogComponent,
	AddCustomReaderElementComponent,
	ImportUserDialogComponent,
	SelectPlanDialogComponent,
	BreadcrumbComponent,
	AppsComponent,
	ImportCertsDialogComponent,
	TerminalComponent,
	VersioningBannerComponent,
	PaginationComponent,
	GenerateDescriptionComponent,
];

const modules = [
	CommonModule,
	FormsModule,
	RouterModule,
	MatMenuModule,
	MatTooltipModule,
	MatTableModule,
	MatSortModule,
	MatPaginatorModule,
	MatSnackBarModule,
	MatDialogModule,
	MatDatepickerModule,
	MatFormFieldModule,
	MatNativeDateModule,
	OverlayModule,
	FontAwesomeModule,
	SharedAllModule,
	TranslocoRootModule,
];

const pipes = [];

@NgModule({
	declarations: [...components, ...pipes],
	imports: [...modules],
	exports: [...components, ...modules],
	providers: [
		// {provide: OWL_DATE_TIME_LOCALE, useValue: 'de'},
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class SharedModule {}
