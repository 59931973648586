import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-delete-line-items-dialog',
	templateUrl: './cancel-documents-dialog.component.html',
	styleUrls: ['./cancel-documents-dialog.component.css'],
})
export class CancelDocumentsDialogComponent implements OnInit {
	public from: string;
	public until: string;

	constructor(
		public dialogRef: MatDialogRef<CancelDocumentsDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data
	) {}

	ngOnInit(): void {}

	onDelete(from: string, until: string): void {
		if (!from || !until) {
			return;
		}
		this.dialogRef.close({ from, until });
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
