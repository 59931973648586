import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'difficult',
})
export class DifficultPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		switch (value) {
			case 'beginner':
				return 'Anfänger';
			case 'expert':
				return 'Experte';
			default:
				return 'Fortgeschritten';
		}
	}
}
