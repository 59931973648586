import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { faArrowLeft, faArrowRight, faCheckCircle, faTimesCircle, faUser } from '@fortawesome/pro-solid-svg-icons';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { AssignSubscriptionDialogComponent } from '../asign-subscription-dialog/assign-subscription-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-select-plan-dialog',
	templateUrl: './select-plan-dialog.component.html',
	styleUrls: ['./select-plan-dialog.component.css'],
})
export class SelectPlanDialogComponent implements OnInit, OnDestroy, AfterViewInit {
	private subscription: Subscription;
	private subscriptionSearch: Subscription;

	public id: number;
	public title: string;

	public loading: boolean;
	public data: Array<any>;
	public meta: any;
	public links: any;

	faNext = faArrowRight;
	faPrevious = faArrowLeft;
	faUser = faUser;
	faInactive = faTimesCircle;
	faActive = faCheckCircle;

	@ViewChild('search') search: ElementRef;

	constructor(
		public dialogRef: MatDialogRef<SelectPlanDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data,
		private apiService: ApiService,
		private errorHandler: ErrorHandlerService,
		public dialog: MatDialog
	) {}

	ngOnInit(): void {
		this.initData(this.initDataParams());
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
		if (this.subscriptionSearch) {
			this.subscriptionSearch.unsubscribe();
		}
	}

	ngAfterViewInit(): void {
		this.searchData();
	}

	initDataParams(): string {
		return `?page=1&include=partner`;
	}

	// Get data
	initData(page: string): void {
		this.loading = true;

		this.subscription = this.apiService.getData(`subscription/plans${page}&include=partner`).subscribe(
			(res) => {
				this.data = res.data;
				this.meta = res.meta;
				this.links = res.links;
				this.loading = false;
			},
			(err) => {
				this.errorHandler.errorHandler(err, 'Get Subscription Plans');
				this.loading = false;
			},
			() => {}
		);
	}

	// Search data
	searchData(): void {
		this.subscriptionSearch = fromEvent(this.search.nativeElement, 'keyup')
			.pipe(
				filter(Boolean),
				debounceTime(250),
				distinctUntilChanged(),
				tap(() => {
					this.initData(`?page=1&search=${this.search.nativeElement.value}`);
				})
			)
			.subscribe();
	}

	// Get new data by page
	pagination(type: string): void {
		if ('prev' === type) {
			const newPage = this.meta.current_page - 1;
			if (newPage < 1) {
				return;
			}
			this.initData(`?page=${newPage}`);
		} else if ('next' === type) {
			const newPage = this.meta.current_page + 1;
			if (newPage >= this.meta.total) {
				return;
			}
			this.initData(`?page=${newPage}`);
		} else if ('start' === type) {
			const newPage = 1;
			this.initData(`?page=${newPage}`);
		} else {
			const newPage = this.meta.last_page;
			this.initData(`?page=${newPage}`);
		}
	}

	selectPlan(id: any): void {
		this.assignSubscription(id);
	}

	onSave(data: any): void {
		this.dialogRef.close(data);
	}

	onClose(): void {
		this.dialogRef.close();
	}

	// Performance improvement
	trackByFn(index, item): number {
		return index;
	}

	assignSubscription(id: number): void {
		const dialogRef = this.dialog.open(AssignSubscriptionDialogComponent, {
			minWidth: '280px',
			data: { id, type: 'Subscription zuweisen' },
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (!result) {
				return;
			}

			this.onSave({ result });
		});
	}
}
