import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { API_URL, API_URL_PUBLIC, FUNCTIONS_URL } from '../injection/environment';
import { Certificate } from '../interfaces/certificate';
import { environment } from '../../../environments/environment';
import { Post } from '../interfaces/post';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	constructor(
		@Inject(API_URL) private apiUrl: string,
		@Inject(API_URL_PUBLIC) private apiUrlPublic: string,
		@Inject(FUNCTIONS_URL) private functionsUrl: string,
		private http: HttpClient
	) {}

	// get data
	getData(path: string): Observable<any> {
		return this.http.get(`${this.apiUrl}${path}`).pipe(
			switchMap((result) => {
				return of(result);
			})
		);
	}

	// get data
	getDataPublic(path: string): Observable<any> {
		return this.http.get(`${this.apiUrlPublic}${path}`);
	}

	// post 2fa data
	post2FaData(path: string, data?: string): Observable<any> {
		const body = {};
		if (data) {
			// @ts-ignore
			body.twoFactorCode = data;
		}
		return this.http.post(`${this.apiUrlPublic}${path}`, body);
	}

	// get 2fa data
	get2FaData(path: string): Observable<any> {
		return this.http.get(`${this.apiUrlPublic}${path}`);
	}

	// get detail data
	getItem(path: string, id: number | string, params?: string): Observable<any> {
		if (params) {
			return this.http.get(`${this.apiUrl}${path}/${id}${params}`);
		} else {
			return this.http.get(`${this.apiUrl}${path}/${id}`);
		}
	}

	// add partner
	addPartner(data: any): Observable<any> {
		const partner = {
			name: data.name,
			logo: data.image,
		};

		if (data.certificate) {
			// @ts-ignore
			partner.configurations = {
				'settings.certificate.partners': data.certificate,
			};
		}

		return this.http.post(`${this.apiUrl}partners`, partner);
	}

	// update partner
	updatePartner(data, id): Observable<any> {
		const partner = {
			name: data.name,
			logo: data.image,
		};

		if (data.certificate) {
			// @ts-ignore
			partner.configurations = {
				'settings.certificate.partners': data.certificate,
			};
		}

		return this.http.put(`${this.apiUrl}partners/${id}`, partner);
	}

	// delete partner
	deletePartner(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}partners/${id}`);
	}

	// add role
	addRole(data: any): Observable<any> {
		console.log(data);

		const role = {
			name: data.name,
			permissions: data.permissions,
		};

		return this.http.post(`${this.apiUrl}roles`, role);
	}

	// update role
	updateRole(data: any, id: number): Observable<any> {
		const role = {
			name: data.name,
			permissions: data.permissions,
		};

		return this.http.put(`${this.apiUrl}roles/${id}`, role);
	}

	// delete role
	deleteRole(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}roles/${id}`);
	}

	// add permission
	addPermission(data): Observable<any> {
		const permission = {
			name: data,
		};

		return this.http.post(`${this.apiUrl}permissions`, permission);
	}

	// delete permission
	deletePermission(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}permissions/${id}`);
	}

	// add isbn
	addIsbn(data): Observable<any> {
		const isbns = {
			isbn: data,
		};

		return this.http.post(`${this.apiUrl}configuration/isbn`, isbns);
	}

	// delete isbn
	deleteIsbn(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}configuration/isbn/${id}`);
	}

	// add customer
	addCustomer(data): Observable<any> {
		const customer = {
			ccStatus: data.ccStatus,
			company: data.company,
			domains: data.domains ? data.domains.replace(/\s/g, '').split(',') : null,
			logo: data.logo ? data.logo : null,
			activeUsersLimit: data.maxUsers,
			tokenTTL: data.tokenTTL,
			type: data.type,
			autoPromote: data.autoPromote ? data.autoPromote : false,
			notes: data.notes ? data.notes : '',
		};

		if (data.managers && data.managers.length) {
			const uAttendees = Array.from(new Set(data.managers.map((a) => a.id))).map((id) => {
				return data.managers.find((a) => a.id === id);
			});

			if (data.managers && uAttendees) {
				const ids = [];
				uAttendees.forEach((el) => {
					ids.push(el.id);
				});
				// @ts-ignore
				customer.managers = ids;
			}
		} else {
			// @ts-ignore
			customer.managers = [];
		}

		if (data.subscriptions) {
			// @ts-ignore
			customer.subscriptions = data.subscriptions;
		}

		if (data.ssoStatus) {
			// @ts-ignore
			customer.ssoStatus = data.ssoStatus;
		}

		if (data.workosOrganisationId) {
			// @ts-ignore
			customer.workOsOrganisationId = data.workosOrganisationId;
		}

		if (data.ssoIdentifier) {
			// @ts-ignore
			customer.ssoIdentifier = data.ssoIdentifier;
		}

		if (data.customizationSlug) {
			// @ts-ignore
			customer.customizationSlug = data.customizationSlug;
		}

		const ints = [];
		if (data.autoPromote) {
			ints.push({
				integration: 'career',
				configurations: { autoPromote: data.autoPromote ? data.autoPromote : false },
			});
			// @ts-ignore
			customer.integrations = ints;
		}

		return this.http.post(`${this.apiUrl}customer/customers`, customer);
	}

	// update customer
	updateCustomer(data: any, id: number, partner?: number): Observable<any> {
		const customer = {
			ccStatus: data.ccStatus,
			company: data.company,
			domains: data.domains ? data.domains.replace(/\s/g, '').split(',') : null,
			logo: data.logo ? data.logo : null,
			activeUsersLimit: data.maxUsers,
			tokenTTL: data.tokenTTL,
			type: data.type,
			autoPromote: data.autoPromote ? data.autoPromote : false,
			notes: data.notes ? data.notes : '',
		};

		if (data.managers && data.managers.length) {
			const uAttendees = Array.from(new Set(data.managers.map((a) => a.id))).map((id) => {
				return data.managers.find((a) => a.id === id);
			});

			if (data.managers && uAttendees) {
				const ids = [];
				uAttendees.forEach((el) => {
					ids.push(el.id);
				});
				// @ts-ignore
				customer.managers = ids;
			}
		} else {
			// @ts-ignore
			customer.managers = [];
		}

		if (partner) {
			// @ts-ignore
			customer.partner = partner;
		}

		if (data.ipLoginAccount && data.ipLoginAccount[0]) {
			// @ts-ignore
			customer.ipLoginAccount = data.ipLoginAccount[0].id;
		}

		if (data.subscriptions) {
			// @ts-ignore
			customer.subscriptions = data.subscriptions;
		}

		if (data.ssoStatus) {
			// @ts-ignore
			customer.ssoStatus = data.ssoStatus;
		}

		if (data.workosOrganisationId) {
			// @ts-ignore
			customer.workOsOrganisationId = data.workosOrganisationId;
		}

		if (data.ssoIdentifier) {
			// @ts-ignore
			customer.ssoIdentifier = data.ssoIdentifier;
		}

		if (data.customizationSlug) {
			// @ts-ignore
			customer.customizationSlug = data.customizationSlug;
		}

		if (data.integrations) {
			const ints = [];
			data.integrations.forEach((el: any) => {
				if (el === 'customerSpecificContent') {
					ints.push({
						integration: el,
						configurations: { limit: data.limit ? data.limit : 3 },
					});
				} else if (el === 'career') {
					ints.push({
						integration: el,
						configurations: { autoPromote: data.autoPromote ? data.autoPromote : false },
					});
				} else if (el === 'aiChat') {
					ints.push({
						integration: el,
						configurations: { requestsPerMonth: data.requestsPerMonth ? data.requestsPerMonth : 0 },
					});
				} else if (el === 'diskSpace') {
					ints.push({
						integration: el,
						configurations: { diskSpace: data.diskSpace ? data.diskSpace : '10 GB' },
					});
				} else {
					ints.push({ integration: el });
				}
			});
			console.log(ints);
			// @ts-ignore
			customer.integrations = ints;
		}

		return this.http.put(`${this.apiUrl}customer/customers/${id}`, customer);
	}

	// delete customer
	deleteCustomer(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}customer/customers/${id}`);
	}

	// delete file
	deleteFile(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}attachments/${id}`);
	}

	// delete user
	deleteUser(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}accounts/${id}`);
	}

	// update user
	addUser(data: any): Observable<any> {
		const user = {
			email: data.email.toLowerCase(),
			status: data.status,
			givenName: data.givenName,
			username: data.username,
			familyName: data.familyName,
			isCompany: data.isCompany ? data.isCompany : false,
			company: data.company,
			addressLine1: data.addressLine1,
			addressLine2: data.addressLine2,
			postcode: data.postcode,
			city: data.city,
			language: data.language ? data.language.toLowerCase() : null,
			position: data.position,
			phone: data.phone,
			sapConnectionType: data.sapConnectionType,
			password: data.password ? data.password : null,
			password_confirmation: data.password_confirmation ? data.password_confirmation : null,
			image: data.image,
			origin: 'plm',
			notes: data.notes,
			configurations: {
				aiChatRequestsPerMonth: data.aiChatRequestsPerMonth ? data.aiChatRequestsPerMonth : 10,
			},
		};

		if (data.roles && data.roles.length) {
			// @ts-ignore
			user.roles = data.roles;
		}

		if (data.partner) {
			// @ts-ignore
			user.managedPartner = data.partner[0] ? data.partner[0] : null;
		}

		if (data.country && data.country[0]) {
			// @ts-ignore
			user.country = data.country[0] ? data.country[0] : null;
		}

		if (data.timezone && data.timezone[0]) {
			// @ts-ignore
			user.timezone = data.timezone[0] ? data.timezone[0] : null;
		}

		if (data.subscriptions) {
			// @ts-ignore
			user.subscriptions = data.subscriptions;
		}

		if (data.partners) {
			const ids = [];
			data.partners.forEach((el) => {
				ids.push(el);
			});
			// @ts-ignore
			user.partners = ids;
		}

		return this.http.post(`${this.apiUrl}accounts`, user);
	}

	// update user
	updateUser(data: any, id: any): Observable<any> {
		const user = {
			email: data.email.toLowerCase(),
			status: data.status,
			givenName: data.givenName,
			familyName: data.familyName,
			isCompany: data.isCompany ? data.isCompany : false,
			company: data.company,
			addressLine1: data.addressLine1,
			addressLine2: data.addressLine2,
			postcode: data.postcode,
			city: data.city,
			country: data.country[0] ? data.country[0] : null,
			language: data.language ? data.language.toLowerCase() : null,
			timezone: data.timezone[0] ? data.timezone[0] : null,
			position: data.position,
			phone: data.phone,
			sapConnectionType: data.sapConnectionType,
			image: data.image,
			origin: data.origin,
			username: data.username,
			notes: data.notes,
			configurations: {
				aiChatRequestsPerMonth: data.aiChatRequestsPerMonth ? data.aiChatRequestsPerMonth : 10,
			},
		};

		if (data.roles && data.roles.length) {
			// @ts-ignore
			user.roles = data.roles;
		}

		if (data.subscriptions) {
			// @ts-ignore
			user.subscriptions = data.subscriptions;
		}

		if (data.partners) {
			const ids = [];
			data.partners.forEach((el) => {
				ids.push(el);
			});
			// @ts-ignore
			user.partners = ids;
		}

		return this.http.put(`${this.apiUrl}accounts/${id}`, user);
	}

	// add conference
	addConference(data): Observable<any> {
		const conference = {
			status: data.status,
			title: data.title,
			description: data.description,
			startsAt: data.startsAt,
			endsAt: data.endsAt,
			image: data.image,
			language: data.language,
			privacyPolicy: data.privacyPolicy,
			withBreak: data.withBreak,
			timezone: data.timezone[0],
			chargeable: data.chargeable ? data.chargeable : 0,
		};

		if (data.partner) {
			// @ts-ignore
			conference.partner = data.partner[0];
		}

		if (data.paymentLink) {
			// @ts-ignore
			conference.paymentLink = data.paymentLink;
		}

		if (data.attendees) {
			const uAttendees = Array.from(new Set(data.attendees.map((a) => a.id))).map((id) => {
				return data.attendees.find((a) => a.id === id);
			});

			if (data.attendees && uAttendees) {
				const ids = [];
				uAttendees.forEach((el) => {
					ids.push(el.id);
				});
				// @ts-ignore
				conference.attendees = ids;
			}
		}

		if (data.info.title && data.info.description && data.info.url && data.info.label) {
			// @ts-ignore
			conference.info = data.info;
		}

		if (data.competitionTitle) {
			// @ts-ignore
			conference.competitionTitle = data.competitionTitle;
		}

		if (data.competitionDescription) {
			// @ts-ignore
			conference.competitionDescription = data.competitionDescription;
		}

		if (data.competitionExcerpt) {
			// @ts-ignore
			conference.competitionExcerpt = data.competitionExcerpt;
		}

		if (data.competitionClaim) {
			// @ts-ignore
			conference.competitionClaim = data.competitionClaim;
		}

		if (data.competitionImageBanner) {
			// @ts-ignore
			conference.competitionBanner = data.competitionImageBanner;
		}

		if (data.competitionImageOverlay) {
			// @ts-ignore
			conference.competitionOverlay = data.competitionImageOverlay;
		}

		if (data.excludeFromExport) {
			const domains = data.excludeFromExport.replace(/\s/g, '').split(',');
			// @ts-ignore
			conference.excludeFromExport = domains;
		}

		return this.http.post(`${this.apiUrl}conference/conferences`, conference);
	}

	// add session
	addSession(data: any): Observable<any> {
		const conference = {
			type: data.type,
			title: data.title,
			description: data.description,
			startsAt: data.startsAt,
			endsAt: data.endsAt,
			conference: data.conference[0],
			// speaker: data.speaker[0],
			timezone: data.timezone[0],
			attachments: data.downloads,
			withBreak: data.withBreak,
			room: data.room,
		};

		if (data.speakers) {
			const ids = [];
			data.speakers.forEach((el) => {
				ids.push(el);
			});
			// @ts-ignore
			conference.speakers = ids;
		}

		if (data.vimeoSource) {
			// @ts-ignore
			conference.vimeoProject = data.vimeoSource[0];
		}

		if (data.source) {
			// @ts-ignore
			conference.source = data.source;
		}

		if (data.crossSales) {
			// @ts-ignore
			conference.crossSales = data.crossSales ? data.crossSales : [];
		}

		return this.http.post(`${this.apiUrl}conference/sessions`, conference);
	}

	// update session
	updateSession(data: any, id: any): Observable<any> {
		const conference = {
			type: data.type,
			title: data.title,
			description: data.description,
			startsAt: data.startsAt,
			endsAt: data.endsAt,
			// conference: data.conference[0],
			// speaker: data.speaker[0],
			chatId: data.chatId,
			timezone: data.timezone[0],
			attachments: data.downloads,
			withBreak: data.withBreak,
			room: data.room,
		};

		if (data.speakers) {
			const ids = [];
			data.speakers.forEach((el) => {
				ids.push(el);
			});
			// @ts-ignore
			conference.speakers = ids;
		}

		if (data.vimeoSource) {
			// @ts-ignore
			conference.vimeoProject = data.vimeoSource[0];
		}

		if (data.crossSales) {
			// @ts-ignore
			conference.crossSales = data.crossSales ? data.crossSales : [];
		}

		if (data.source) {
			// @ts-ignore
			conference.source = data.source;
		}

		return this.http.put(`${this.apiUrl}conference/sessions/${id}`, conference);
	}

	// delete session
	deleteSession(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}conference/sessions/${id}`);
	}

	// delete conference
	deleteConference(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}conference/conferences/${id}`);
	}

	// copy conference
	copyConference(id: number): Observable<any> {
		return this.http.post(`${this.apiUrl}conference/conferences/${id}/copy`, '');
	}

	// export conference attandees
	getExport(id: number): Observable<any> {
		return this.http.get(`${this.apiUrl}conference/conferences/${id}/actions/export-statistics`, {
			responseType: 'blob',
		});
	}

	// get exports
	getExports(url): Observable<any> {
		return this.http.get(`${this.apiUrl}${url}`, {
			responseType: 'blob',
		});
	}

	// update partner
	updateConference(data: any, id: any): Observable<any> {
		const conference = {
			status: data.status,
			title: data.title,
			description: data.description,
			startsAt: data.startsAt,
			endsAt: data.endsAt,
			image: data.image,
			language: data.language,
			privacyPolicy: data.privacyPolicy,
			timezone: data.timezone[0],
			chargeable: data.chargeable ? data.chargeable : 0,
		};

		if (data.partner) {
			// @ts-ignore
			conference.partner = data.partner;
		}

		if (data.paymentLink) {
			// @ts-ignore
			conference.paymentLink = data.paymentLink;
		}

		if (data.attendees) {
			const uAttendees = Array.from(new Set(data.attendees.map((a) => a.id))).map((es) => {
				return data.attendees.find((a) => a.id === es);
			});

			if (data.attendees && uAttendees) {
				const ids = [];
				uAttendees.forEach((el) => {
					ids.push(el.id);
				});
				// @ts-ignore
				conference.attendees = ids;
			}
		}

		if (data.info.title && data.info.description && data.info.url && data.info.label) {
			// @ts-ignore
			conference.info = data.info;
		}

		if (data.competitionTitle) {
			// @ts-ignore
			conference.competitionTitle = data.competitionTitle;
		}

		if (data.competitionDescription) {
			// @ts-ignore
			conference.competitionDescription = data.competitionDescription;
		}

		if (data.competitionExcerpt) {
			// @ts-ignore
			conference.competitionExcerpt = data.competitionExcerpt;
		}

		if (data.competitionClaim) {
			// @ts-ignore
			conference.competitionClaim = data.competitionClaim;
		}

		if (data.competitionImageBanner) {
			// @ts-ignore
			conference.competitionBanner = data.competitionImageBanner;
		}

		if (data.competitionImageOverlay) {
			// @ts-ignore
			conference.competitionOverlay = data.competitionImageOverlay;
		}

		if (data.excludeFromExport) {
			const domains = data.excludeFromExport.replace(/\s/g, '').split(',');
			// @ts-ignore
			conference.excludeFromExport = domains;
		}

		return this.http.put(`${this.apiUrl}conference/conferences/${id}`, conference);
	}

	// add sponsor
	addSponsor(data: any): Observable<any> {
		const sponsor = {
			name: data.name,
			description: data.description,
			logo: data.logo,
			email: data.email,
			phone: data.phone,
			homepage: data.homepage,
			conference: data.conference[0],
			template: data.template,
		};

		if (data.vimeoSource) {
			// @ts-ignore
			sponsor.vimeoSource = data.vimeoSource;
		}

		if (data.teamsSource) {
			// @ts-ignore
			sponsor.teamsSource = data.teamsSource;
		}

		if (data.teamsLink) {
			// @ts-ignore
			sponsor.teamsLink = data.teamsLink;
		}
		if (data.slackLink) {
			// @ts-ignore
			sponsor.slackLink = data.slackLink;
		}
		if (data.zoomLink) {
			// @ts-ignore
			sponsor.zoomLink = data.zoomLink;
		}

		if (
			data.content_image ||
			data.content_image_link ||
			data.content_wysiwyg_1_title ||
			data.content_wysiwyg_1 ||
			data.content_wysiwyg_2_title ||
			data.content_wysiwyg_2 ||
			data.content_emebd
		) {
			// @ts-ignore
			sponsor.templateData = {};
		} else {
			// @ts-ignore
			sponsor.templateData = null;
		}

		if (data.content_image) {
			// @ts-ignore
			sponsor.templateData.image = data.content_image;
		}

		if (data.content_image_link) {
			// @ts-ignore
			sponsor.templateData.imageLink = data.content_image_link;
		}

		if (data.content_wysiwyg_1_title) {
			// @ts-ignore
			sponsor.templateData.contentTitle = data.content_wysiwyg_1_title;
		}

		if (data.content_wysiwyg_1) {
			// @ts-ignore
			sponsor.templateData.content = data.content_wysiwyg_1;
		}

		if (data.content_wysiwyg_2_title) {
			// @ts-ignore
			sponsor.templateData.content1Title = data.content_wysiwyg_2_title;
		}

		if (data.content_wysiwyg_2) {
			// @ts-ignore
			sponsor.templateData.content1 = data.content_wysiwyg_2;
		}

		if (data.content_embed) {
			// @ts-ignore
			sponsor.templateData.embed = data.content_embed;
		}

		if (data.contactPersons) {
			const ids = [];
			data.contactPersons.forEach((el) => {
				ids.push(el);
			});
			// @ts-ignore
			sponsor.contactPersons = ids;
		}

		if (data.competitionQuestion) {
			// @ts-ignore
			sponsor.competitionQuestion = data.competitionQuestion;
		}

		if (data.competitionAnswers && data.competitionAnswers.length > 0) {
			// @ts-ignore
			sponsor.competitionAnswers = data.competitionAnswers;
		}

		return this.http.post(`${this.apiUrl}conference/sponsors`, sponsor);
	}

	// update session
	updateSponsor(data, id): Observable<any> {
		const sponsor = {
			name: data.name,
			description: data.description,
			logo: data.logo,
			email: data.email,
			phone: data.phone,
			homepage: data.homepage,
			conference: data.conference[0],
			template: data.template,
		};

		if (data.vimeoSource) {
			// @ts-ignore
			sponsor.vimeoSource = data.vimeoSource;
		}

		if (data.teamsSource) {
			// @ts-ignore
			sponsor.teamsSource = data.teamsSource;
		}

		if (data.teamsLink) {
			// @ts-ignore
			sponsor.teamsLink = data.teamsLink;
		}
		if (data.slackLink) {
			// @ts-ignore
			sponsor.slackLink = data.slackLink;
		}
		if (data.zoomLink) {
			// @ts-ignore
			sponsor.zoomLink = data.zoomLink;
		}

		if (
			data.content_image ||
			data.content_image_link ||
			data.content_wysiwyg_1_title ||
			data.content_wysiwyg_1 ||
			data.content_wysiwyg_2_title ||
			data.content_wysiwyg_2 ||
			data.content_emebd
		) {
			// @ts-ignore
			sponsor.templateData = {};
		} else {
			// @ts-ignore
			sponsor.templateData = null;
		}

		if (data.content_image) {
			// @ts-ignore
			sponsor.templateData.image = data.content_image;
		}

		if (data.content_image_link) {
			// @ts-ignore
			sponsor.templateData.imageLink = data.content_image_link;
		}

		if (data.content_wysiwyg_1_title) {
			// @ts-ignore
			sponsor.templateData.contentTitle = data.content_wysiwyg_1_title;
		}

		if (data.content_wysiwyg_1) {
			// @ts-ignore
			sponsor.templateData.content = data.content_wysiwyg_1;
		}

		if (data.content_wysiwyg_2_title) {
			// @ts-ignore
			sponsor.templateData.content1Title = data.content_wysiwyg_2_title;
		}

		if (data.content_wysiwyg_2) {
			// @ts-ignore
			sponsor.templateData.content1 = data.content_wysiwyg_2;
		}

		if (data.content_embed) {
			// @ts-ignore
			sponsor.templateData.embed = data.content_embed;
		}

		if (data.competitionQuestion) {
			// @ts-ignore
			sponsor.competitionQuestion = data.competitionQuestion;
		}

		if (data.competitionAnswers && data.competitionAnswers.length > 0) {
			// @ts-ignore
			sponsor.competitionAnswers = data.competitionAnswers;
		}

		if (data.contactPersons) {
			const ids = [];
			data.contactPersons.forEach((el) => {
				ids.push(el);
			});
			// @ts-ignore
			sponsor.contactPersons = ids;
		}

		return this.http.put(`${this.apiUrl}conference/sponsors/${id}`, sponsor);
	}

	// delete session
	deleteSponsor(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}conference/sponsors/${id}`);
	}

	// add contact person
	addContactPerson(data): Observable<any> {
		const person = {
			familyName: data.familyName,
			givenName: data.givenName,
			image: data.image,
			email: data.email,
			phone: data.phone,
			position: data.position,
			additionalInformation: data.additionalInformation,
		};

		return this.http.post(`${this.apiUrl}conference/contact-persons`, person);
	}

	// update contact person
	updateContactPerson(data, id): Observable<any> {
		const person = {
			familyName: data.familyName,
			givenName: data.givenName,
			image: data.image,
			email: data.email,
			phone: data.phone,
			position: data.position,
			additionalInformation: data.additionalInformation,
		};

		return this.http.put(`${this.apiUrl}conference/contact-persons/${id}`, person);
	}

	// delete contact person
	deleteContactPerson(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}conference/contact-persons/${id}`);
	}

	// update configurations
	updateConfigurtations(data: any): Observable<any> {
		const config = {
			'settings.vimeo.clientId': data.vimeoClientId,
			'settings.vimeo.clientSecret': data.vimeoClientSecret,
			'settings.vimeo.accessToken': data.vimeoAccessToken,
			'settings.mail.companyLogo': data.mailCompanyLogo,
			'settings.mail.companyAddress': data.mailCompanyAddress,
			'settings.mail.companyName': data.mailCompanyName,
			'settings.mail.fromAddress': data.mailFromAddress,
			'settings.platform.libraryUrl': data.platformLibrary ? data.platformLibrary : null,
			'settings.mail.templateSlug': data.mailTemplateSlug ? data.mailTemplateSlug : null,
			'settings.menu.imprintUrl': data.imprintUrl ? data.imprintUrl : null,
			'settings.menu.dataPrivacyUrl': data.dataPrivacyUrl ? data.dataPrivacyUrl : null,
			'settings.paddle.publicKey': data.paddlePublicKey ? data.paddlePublicKey : null,
		};

		return this.http.put(`${this.apiUrl}configuration/configurations?keys=settings.*`, config);
	}

	// update mails
	updateMails(data: any, locale: string): Observable<any> {
		const noNewLinesMailReviewerInvitation = data.mail_reviewer_invitiation.replaceAll('<p><br></p>', '');

		const mail = {
			'email.conferenceConferenceReminder.subject': data.subject_conference_conference_reminder,
			'email.conferenceConferenceReminder.content': data.mail_conference_conference_reminder,
			'email.conferenceConferenceReminder.schedule': data.schedule_conference_conference_reminder,
			'email.conferenceConferenceAttendance.subject': data.subject_conference_sign_up,
			'email.conferenceConferenceAttendance.content': data.mail_conference_sign_up,
			'email.conferenceUserRegister.subject': data.subject_user_register,
			'email.conferenceUserRegister.content': data.mail_user_register,
			'email.customerAccountRegister.subject': data.subject_customer_account_register,
			'email.customerAccountRegister.content': data.mail_customer_account_register,
			'email.userFreeTrial.subject': data.subject_user_free_trial,
			'email.userFreeTrial.content': data.mail_user_free_trial,
			'email.ccManagerResetPassword.subject': data.subject_cc_manager_reset_password,
			'email.ccManagerResetPassword.content': data.mail_cc_manager_reset_password,
			'email.ccUserAdded.subject': data.subject_cc_user_added,
			'email.ccUserAdded.content': data.mail_cc_user_added,
			'email.ccUserResetPassword.subject': data.subject_cc_user_reset_password,
			'email.ccUserResetPassword.content': data.mail_cc_user_reset_password,
			'email.libraryForgotPassword.subject': data.subject_library_user_forgot_password,
			'email.libraryForgotPassword.content': data.mail_library_user_forgot_password,
			'email.libraryUserSubscriptionCreated.subject': data.subject_library_user_subscription_created,
			'email.libraryUserSubscriptionCreated.content': data.mail_library_user_subscription_created,
			'email.libraryUserSubscriptionUpdated.subject': data.subject_library_user_subscription_updated,
			'email.libraryUserSubscriptionUpdated.content': data.mail_library_user_subscription_updated,
			'email.libraryUserSubscriptionCancelled.subject': data.subject_library_user_subscription_cancelled,
			'email.libraryUserSubscriptionCancelled.content': data.mail_library_user_subscription_cancelled,
			'email.reviewerInvitation.subject': data.subject_reviewer_invitiation,
			'email.reviewerInvitation.content': noNewLinesMailReviewerInvitation,
			'email.forgotPassword.subject': data.subject_forgot_password,
			'email.forgotPassword.content': data.mail_forgot_password,
			'email.userWelcome.subject': data.subject_welcome,
			'email.userWelcome.content': data.mail_welcome,
			'email.supportMessageConfirmation.subject': data.subject_support_message_confirmation,
			'email.supportMessageConfirmation.content': data.mail_support_message_confirmation,
			'email.freeTrialReminderOne.subject': data.subject_freetrial_reminder_one,
			'email.freeTrialReminderOne.content': data.mail_freetrial_reminder_one,
			'email.freeTrialReminderTwo.subject': data.subject_freetrial_reminder_two,
			'email.freeTrialReminderTwo.content': data.mail_freetrial_reminder_two,
			'email.freeTrialReminderThree.subject': data.subject_freetrial_reminder_three,
			'email.freeTrialReminderThree.content': data.mail_freetrial_reminder_three,
			'email.freeTrialReminderFour.subject': data.subject_freetrial_reminder_four,
			'email.freeTrialReminderFour.content': data.mail_freetrial_reminder_four,
			'email.freeTrialReminderFive.subject': data.subject_freetrial_reminder_five,
			'email.freeTrialReminderFive.content': data.mail_freetrial_reminder_five,
			'email.authorAccountingCredit.subject': data.subject_author_accounting_credit,
			'email.authorAccountingCredit.content': data.mail_author_accounting_credit,
			'email.authorAccountingCreditCancelled.subject': data.subject_author_accounting_credit_cancelled,
			'email.authorAccountingCreditCancelled.content': data.mail_author_accounting_credit_cancelled,
			'email.accountVerifyChangeEmail.subject': data.subject_account_verify_change_email,
			'email.accountVerifyChangeEmail.content': data.mail_account_verify_change_email,
			'email.onlineTrainingInvitation.subject': data.subject_online_training_invitation,
			'email.onlineTrainingInvitation.content': data.mail_online_training_invitation,
			'email.onlineTrainingDateWelcome.subject': data.subject_online_training_date_welcome,
			'email.onlineTrainingDateWelcome.content': data.mail_online_training_date_welcome,
			'email.onlineTrainingDateCancellation.subject': data.subject_online_training_date_cancellation,
			'email.onlineTrainingDateCancellation.content': data.mail_online_training_date_cancellation,
			'email.onlineTrainingDateNewAttendees.subject': data.subject_online_training_new_attendees,
			'email.onlineTrainingDateNewAttendees.content': data.mail_online_training_new_attendees,
			'email.onlineTrainingDateFinished.subject': data.subject_online_training_date_finished,
			'email.onlineTrainingDateFinished.content': data.mail_online_training_date_finished,
			'email.forumSignUp.subject': data.subject_forum_signup,
			'email.forumSignUp.content': data.mail_forum_signup,
			'email.forumReport.subject': data.subject_forum_report,
			'email.forumReport.content': data.mail_forum_report,
			'email.forumNewComment.subject': data.subject_forum_new_comment,
			'email.forumNewComment.content': data.mail_forum_new_comment,
			'email.ccUserInvited.subject': data.subject_cc_user_invited,
			'email.ccUserInvited.content': data.mail_cc_user_invited,
			'email.shopOrderPayed.subject': data.subject_shop_order_payed,
			'email.shopOrderPayed.content': data.mail_shop_order_payed,
		};

		return this.http.put(`${this.apiUrl}configuration/configurations?locale=${locale}&keys=email.*`, mail);
	}

	// add criteria
	addCriteria(data: any): Observable<any> {
		const criteria = {
			type: data.type,
			fromRating: data.fromRating,
			toRating: data.toRating,
			text: {
				en: data.textEn,
			},
		};

		if (data.textDe) {
			// @ts-ignore
			criteria.text.de = data.textDe;
		}

		if (data.textFr) {
			// @ts-ignore
			criteria.text.fr = data.textFr;
		}

		return this.http.post(`${this.apiUrl}configuration/rating-criteria`, criteria);
	}

	// update criteria
	updateCriteria(data: any, id: number): Observable<any> {
		const criteria = {
			type: data.type,
			fromRating: data.fromRating,
			toRating: data.toRating,
			text: {
				en: data.textEn,
			},
		};

		if (data.textDe) {
			// @ts-ignore
			criteria.text.de = data.textDe;
		}

		if (data.textFr) {
			// @ts-ignore
			criteria.text.fr = data.textFr;
		}

		return this.http.put(`${this.apiUrl}configuration/rating-criteria/${id}`, criteria);
	}

	// delete criteria
	deleteCriteria(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}configuration/rating-criteria/${id}`);
	}

	// get stats
	getStats(path: string): Observable<any> {
		return this.http.get(`${this.apiUrl}${path}`);
	}

	// add category
	addCategory(data): Observable<any> {
		console.log(data);

		const category = {
			icon: data.icon,
			menuOrder: data.menuOrder,
			name: {
				en: data.nameEn,
			},
		};

		if (data.nameDe) {
			// @ts-ignore
			category.name.de = data.nameDe;
		}

		if (data.nameFr) {
			// @ts-ignore
			category.name.fr = data.nameFr;
		}

		if (data.nameEs) {
			// @ts-ignore
			category.name.es = data.nameEs;
		}

		if (data.partner) {
			// @ts-ignore
			category.partner = data.partner;
		}

		if (data.fioriIds) {
			const ids = data.fioriIds.replace(/\s/g, '').split(',');
			// @ts-ignore
			category.fioriIds = ids;
		}

		return this.http.post(`${this.apiUrl}product/product-categories`, category);
	}

	// add category
	updateCategory(data, id): Observable<any> {
		const category = {
			icon: data.icon,
			menuOrder: data.menuOrder,
			name: {
				en: data.nameEn,
			},
		};

		if (data.nameDe) {
			// @ts-ignore
			category.name.de = data.nameDe;
		}

		if (data.nameFr) {
			// @ts-ignore
			category.name.fr = data.nameFr;
		}

		if (data.nameEs) {
			// @ts-ignore
			category.name.es = data.nameEs;
		}

		if (data.fioriIds) {
			const ids = data.fioriIds.replace(/\s/g, '').split(',');
			// @ts-ignore
			category.fioriIds = ids;
		}

		return this.http.put(`${this.apiUrl}product/product-categories/${id}`, category);
	}

	// delete category
	deleteCategory(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}product/product-categories/${id}`);
	}

	// delete type
	deleteType(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}product/product-types/${id}`);
	}

	// add type
	addType(data): Observable<any> {
		const type = {
			name: data.name,
			slug: data.slug,
			internal: data.internal,
		};

		return this.http.post(`${this.apiUrl}product/product-types`, type);
	}

	// add type
	updateType(data, id): Observable<any> {
		const type = {
			name: data.name,
			slug: data.slug,
			internal: data.internal,
		};

		return this.http.put(`${this.apiUrl}product/product-types/${id}`, type);
	}

	// add ip
	addIp(data): Observable<any> {
		const ip = {
			description: data.description,
			start: data.start,
			end: data.end,
		};

		if (data.customer) {
			// @ts-ignore
			ip.customer = data.customer[0] ? data.customer[0] : null;
		}

		return this.http.post(`${this.apiUrl}customer/ip-ranges`, ip);
	}

	// update ip
	updateIp(data, id): Observable<any> {
		const ip = {
			description: data.description,
			start: data.start,
			end: data.end,
		};

		if (data.customer) {
			// @ts-ignore
			ip.customer = data.customer[0] ? data.customer[0].id : null;
		}

		return this.http.put(`${this.apiUrl}customer/ip-ranges/${id}`, ip);
	}

	// delete ip
	deleteIp(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}customer/ip-ranges/${id}`);
	}

	// delete author
	deleteAuthor(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}author/authors/${id}`);
	}

	// export authors
	exportAuthors(data: any): Observable<any> {
		return this.http.get(`${this.apiUrl}author/authors/exports/${data.exports}${data.sys ? '?systems=' + data.sys : ''}`, {
			responseType: 'blob',
		});
	}

	// update author
	updateAuthor(data, id): Observable<any> {
		const author = {
			company: data.company,
			birthday: data.birthday,
			gender: data.gender,
			salutation: data.salutation,
			gift: data.gift,
			giftStatus: data.giftStatus,
			description: {
				en: data.descriptionEn,
				de: data.descriptionDe ? data.descriptionDe : null,
				fr: data.descriptionFr ? data.descriptionFr : null,
				pt: data.descriptionPt ? data.descriptionPt : null,
			},
			facebook: data.facebook,
			linkedIn: data.linkedIn,
			twitter: data.twitter,
			xing: data.xing,
			paymentMethod: data.paymentMethod ? data.paymentMethod[0] : null,
			priority: data.priority,
			supplierNumber: data.supplierNumber,
			billingAddressLine1: data.billingAddressLine1,
			billingAddressLine2: data.billingAddressLine2,
			billingPostcode: data.billingPostcode,
			billingCity: data.billingCity,
			billingState: data.billingState,
			billingCountry: data.billingCountry ? data.billingCountry[0] : null,
			bank: data.bank,
			bankAdditionalField1: data.bankAdditionalField1,
			iban: data.iban,
			bic: data.bic,
			paypal: data.paypal,
			// bankCountry: data.bankCountry ? data.bankCountry[0] : null,
			taxNumber: data.taxNumber,
			taxable: parseFloat(data.taxable),
			printCosts: parseFloat(data.printCosts),
			companyState: data.companyState,
			useDeviatingBillingAddress: data.useDeviatingBillingAddress,
			notes: data.notes,
		};

		return this.http.put(`${this.apiUrl}author/authors/${id}`, author);
	}

	// delete payment method
	deletePaymentMethod(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}configuration/payment-methods/${id}`);
	}

	// add payment method
	addPaymentMethod(data): Observable<any> {
		const method = {
			name: {
				en: data.nameEn,
				de: data.nameDe,
			},
		};

		if (data.partner) {
			// @ts-ignore
			method.partner = data.partner[0];
		}

		return this.http.post(`${this.apiUrl}configuration/payment-methods`, method);
	}

	// add payment method
	updatePaymentMethod(data, id): Observable<any> {
		const method = {
			name: {
				en: data.nameEn,
				de: data.nameDe,
			},
		};

		if (data.partner) {
			// @ts-ignore
			method.partner = data.partner[0];
		}

		return this.http.put(`${this.apiUrl}configuration/payment-methods/${id}`, method);
	}

	// add product
	addProduct(data: any): Observable<any> {
		const product = {
			partner: data.partner ? data.partner[0] : null,
			status: data.status,
			language: data.language ? data.language : null,
			authors: data.authors ? data.authors : null,
			type: data.type,
			ownerType: data.ownerType,
			title: data.title,
			excerpt: data.excerpt ? data.excerpt : null,
			description: data.description ? data.description : null,
			level: data.level ? data.level : null,
			releaseDate: data.releaseDate ? data.releaseDate : null,
			licenseExpiresAt: data.licenseExpiresAt ? data.licenseExpiresAt : null,
			categories: data.categories ? data.categories : null,
			tags: data.tags ? data.tags : null,
			segments: data.segments ? data.segments : null,
			notes: data.notes ? data.notes : null,
		};

		if (data.planGroups && data.planGroups.length > 0) {
			// @ts-ignore
			product.planGroups = data.planGroups;
		}

		if (data.crossSales) {
			// @ts-ignore
			product.crossSales = data.crossSales ? data.crossSales : [];
		}

		if (data.certificates) {
			// @ts-ignore
			product.certificates = data.certificates ? data.certificates : [];
		}

		if (data.fioriIds) {
			const ids = data.fioriIds.replace(/\s/g, '').split(',');
			// @ts-ignore
			product.fioriIds = ids;
		}

		return this.http.post(`${this.apiUrl}product/products`, product);
	}

	// add product meta
	addProductMeta(data, id): Observable<any> {
		const meta = {
			edition: data.edition ? data.edition : null,
			asin: data.asin ? data.asin : null,
			pages: data.pages ? data.pages : null,
			appleId: data.appleId ? data.appleId : null,
			isbnPrint: data.isbnPrint ? data.isbnPrint : null,
			otherIsbn: data.otherIsbn ? data.otherIsbn : null,
			isbn: data.isbn ? data.isbn : null,
			amazonPrintBook: data.amazonPrintBook ? data.amazonPrintBook : null,
			webshopUrl: data.webshopUrl ? data.webshopUrl : null,
			koboId: data.koboId ? data.koboId : null,
			koboUrl: data.koboUrl ? data.koboUrl : null,
			thaliaUrl: data.thaliaUrl ? data.thaliaUrl : null,
			googleId: data.googleId ? data.googleId : null,
			googleUrl: data.googleUrl ? data.googleUrl : null,
			imageSource: data.imageSource ? data.imageSource : null,
			assistantId: data.assistantId ? data.assistantId : null,
			newEditionAvailable: data.newEditionAvailable ? data.newEditionAvailable : false,
		};

		if (data.parentEdition) {
			// @ts-ignore
			meta.parentEdition = data.parentEdition[0];
		}

		return this.http.put(`${this.apiUrl}product/products/${id}/meta`, meta);
	}

	// add product media
	addProductMedia(data, id): Observable<any> {
		const media = {
			cover: data.cover ? data.cover : null,
			thumbnail: data.thumbnail ? data.thumbnail : null,
			readingSample: data.readingSample ? data.readingSample : null,
			pdfFolder: data.pdfFolder ? data.pdfFolder : null,
			epub: data.ebook ? data.ebook : null,
			previewVideoUrl: data.previewVideoUrl ? data.previewVideoUrl : null,
			pdf: data.pdf ? data.pdf : null,
			embedUrl: data.embedUrl ? data.embedUrl : null,
		};

		if (data.vimeoProject) {
			// @ts-ignore
			media.vimeoProject = data.vimeoProject[0] ? data.vimeoProject[0] : null;
		}

		return this.http.put(`${this.apiUrl}product/products/${id}/media`, media);
	}

	// add product meta
	addProductCloudPrinter(data, id): Observable<any> {
		const options = [];

		if (data.bookPaper) {
			options.push({ oKey: data.bookPaper, key: 'bookPaper' });
		}

		if (data.coverPaper) {
			options.push({ oKey: data.coverPaper, key: 'coverPaper' });
		}

		if (data.coverFinish) {
			options.push({ oKey: data.coverFinish, key: 'coverFinish' });
		}

		if (data.wiring) {
			options.push({ oKey: data.wiring, key: 'wiring' });
		}

		const cloudPrintObj = {
			cloudPrinterStatus: data.status,
			cloudPrinterCover: data.pdfCover ? data.pdfCover : null,
			cloudPrinterBook: data.pdfBook ? data.pdfBook : null,
			cloudPrinterProduct: data.product ? data.product : null,
			cloudPrinterOptions: options,
		};

		return this.http.put(`${this.apiUrl}product/products/${id}/cloud-printer`, cloudPrintObj);
	}

	// add product meta
	addProductAttachments(data, id): Observable<any> {
		const attachments = [];
		if (data.attachments) {
			data.attachments.forEach((el) => {
				attachments.push(el.id);
			});
			data.attachments = attachments;
		}

		const attachment = {
			attachments: data.attachments ? data.attachments : null,
		};

		return this.http.put(`${this.apiUrl}product/products/${id}/attachments`, attachment);
	}

	// add product webshop
	addProductWebshop(data: any, id: number | string): Observable<any> {
		const prices = [];
		if (data.prices) {
			data.prices.forEach((el: any) => {
				el.price = {
					amount: el.amount,
					currency: el.currency.toString(),
				};
				el.country = el.country[0];
				prices.push(el);
			});
			data.prices = prices;
		}

		return this.http.put(`${this.apiUrl}products/${id}`, data);
	}

	// add product meta
	addProductBilling(data, id): Observable<any> {
		const billing = {
			extraChargeForOnleihe: data.extraChargeForOnleihe ? data.extraChargeForOnleihe : 0,
			printCosts: data.printCosts ? data.printCosts : 0,
			printInfos: data.printInfos ? data.printInfos : '',
			shareOfNetSales: data.shareOfNetSales ? data.shareOfNetSales : 0,
			billingRelevant: data.billingRelevant ? data.billingRelevant : false,
			authorShares: data.authorShares,
		};

		return this.http.put(`${this.apiUrl}product/products/${id}/billing-information`, billing);
	}

	// add product
	updateProduct(data, id): Observable<any> {
		const cats = [];

		const product = {
			status: data.status,
			language: data.language ? data.language : null,
			authors: data.authors ? data.authors : null,
			type: data.type,
			ownerType: data.ownerType,
			title: data.title,
			excerpt: data.excerpt ? data.excerpt : null,
			description: data.description ? data.description : null,
			level: data.level ? data.level : null,
			releaseDate: data.releaseDate ? data.releaseDate : null,
			licenseExpiresAt: data.licenseExpiresAt ? data.licenseExpiresAt : null,
			categories: data.categories ? data.categories : null,
			tags: data.tags ? data.tags : null,
			segments: data.segments ? data.segments : null,
			notes: data.notes ? data.notes : null,
		};

		if (data.crossSales) {
			// @ts-ignore
			product.crossSales = data.crossSales ? data.crossSales : [];
		}

		if (data.certificates) {
			// @ts-ignore
			product.certificates = data.certificates ? data.certificates : [];
		}

		if (data.fioriIds) {
			const ids = data.fioriIds.replace(/\s/g, '').split(',');
			// @ts-ignore
			product.fioriIds = ids;
		}

		return this.http.put(`${this.apiUrl}product/products/${id}`, product);
	}

	// delete segment
	deleteSegment(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}product/product-segments/${id}`);
	}

	// add segment
	addSegment(data): Observable<any> {
		const segment = {
			name: data.name,
		};

		return this.http.post(`${this.apiUrl}product/product-segments`, segment);
	}

	// add segment
	updateSegment(data, id): Observable<any> {
		const segment = {
			name: data.name,
		};

		return this.http.put(`${this.apiUrl}product/product-segments/${id}`, segment);
	}

	// delete product
	deleteProduct(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}product/products/${id}`);
	}

	// export landing page users
	getExportLandingUser(id: number): Observable<any> {
		return this.http.get(`${this.apiUrl}customer/landing-pages/${id}/export`, {
			responseType: 'blob',
		});
	}

	// add sap system
	addSapSystem(data): Observable<any> {
		const system = {
			name: data.name,
			partner: data.partner ? data.partner[0]?.id : null,
		};

		return this.http.post(`${this.apiUrl}configuration/sap-systems`, system);
	}

	// delete sap system
	deleteSapSystem(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}configuration/sap-systems/${id}`);
	}

	// export license users
	getIsbn(expect?: string): Observable<any> {
		let url = 'configuration/isbn/receive';
		if (expect) {
			url = `configuration/isbn/receive?except=${expect}`;
		}

		return this.http.get(`${this.apiUrl}${url}`);
	}

	// export products
	exportProducts(url: any): Observable<any> {
		return this.http.get(`${this.apiUrl}${url}`, {
			responseType: 'blob',
		});
	}

	// add criteria
	addPromter(data): Observable<any> {
		const question = {
			publishAt: data.publishAt,
			unpublishAt: data.unpublishAt,
			question: {
				en: data.textEn,
			},
		};

		if (data.textDe) {
			// @ts-ignore
			question.question.de = data.textDe;
		}

		if (data.textFr) {
			// @ts-ignore
			question.question.fr = data.textFr;
		}

		return this.http.post(`${this.apiUrl}net-promoter-score/net-promoter-scores`, question);
	}

	// update criteria
	updatePromoter(data: any, id: number): Observable<any> {
		const question = {
			publishAt: data.publishAt,
			unpublishAt: data.unpublishAt,
			question: {
				en: data.textEn,
			},
		};

		if (data.textDe) {
			// @ts-ignore
			question.question.de = data.textDe;
		}

		if (data.textFr) {
			// @ts-ignore
			question.question.fr = data.textFr;
		}

		return this.http.put(`${this.apiUrl}net-promoter-score/net-promoter-scores/${id}`, question);
	}

	// delete criteria
	deletePromoter(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}net-promoter-score/net-promoter-scores/${id}`);
	}

	// add redirect
	addRedirect(data): Observable<any> {
		const redirect = {
			path: data.path,
			statusCode: data.statusCode,
			note: data.notice ? data.notice : '',
		};

		return this.http.post(`${this.apiUrl}redirects`, redirect);
	}

	// update redirect
	updateRedirect(data, id): Observable<any> {
		const redirect = {
			path: data.path,
			statusCode: data.statusCode,
			note: data.notice ? data.notice : '',
		};

		return this.http.put(`${this.apiUrl}redirects/${id}`, redirect);
	}

	// add learning path
	addLearningPath(data): Observable<any> {
		const path = {
			partner: data.partner ? data.partner[0] : null,
			status: data.status,
			language: data.language ? data.language : null,
			title: data.title,
			description: data.desc ? data.desc : null,
			excerpt: data.excerpt ? data.excerpt : null,
			level: data.level ? data.level : null,
			cover: data.cover ? data.cover : null,
			thumbnail: data.thumbnail ? data.thumbnail : null,
			duration: data.duration ? data.duration : null,
		};

		if (data.certificate && data.certificate.length) {
			// @ts-ignore
			path.certificate = data.certificate ? data.certificate[0] : null;
		}

		const cats = [];
		if (data.categories) {
			data.categories.forEach((el) => {
				cats.push(el);
			});
			data.categoriesIds = cats;
			// @ts-ignore
			path.categories = data.categoriesIds ? data.categoriesIds : null;
		}

		if (data.levels) {
			// @ts-ignore
			path.steps = data.levels;
		}

		return this.http.post(`${this.apiUrl}learning/learnings`, path);
	}

	// add learning path
	updateLearningPath(data, id): Observable<any> {
		const path = {
			status: data.status,
			language: data.language ? data.language : null,
			title: data.title,
			description: data.desc ? data.desc : null,
			excerpt: data.excerpt ? data.excerpt : null,
			level: data.level ? data.level : null,
			cover: data.cover ? data.cover : null,
			thumbnail: data.thumbnail ? data.thumbnail : null,
			duration: data.duration ? data.duration : null,
		};

		if (data.certificate && data.certificate.length) {
			// @ts-ignore
			path.certificate = data.certificate ? data.certificate[0] : null;
		}

		const cats = [];
		if (data.categories) {
			data.categories.forEach((el) => {
				cats.push(el);
			});
			data.categoriesIds = cats;
			// @ts-ignore
			path.categories = data.categoriesIds ? data.categoriesIds : null;
		}

		if (data.levels) {
			// @ts-ignore
			path.steps = data.levels;
		}

		return this.http.put(`${this.apiUrl}learning/learnings/${id}`, path);
	}

	// delete product
	deleteLearningPath(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}learning/learnings/${id}`);
	}

	// add ip
	addLanding(data: any): Observable<any> {
		const page = {
			status: data.status,
			description: data.description,
			topClaim: data.topClaim,
			bottomClaim: data.bottomClaim,
			privacyPolicy: data.privacyPolicy,
			slug: data.slug,
			color: data.color ? data.color : '',
		};

		if (data.customer) {
			// @ts-ignore
			page.customer = data.customer[0] ? data.customer[0] : null;
		}

		if (data.product) {
			// @ts-ignore
			page.product = data.product[0] ? data.product[0] : null;
		}

		return this.http.post(`${this.apiUrl}customer/landing-pages`, page);
	}

	// update ip
	updateLanding(data: any, id: number): Observable<any> {
		const page = {
			status: data.status,
			description: data.description,
			topClaim: data.topClaim,
			bottomClaim: data.bottomClaim,
			privacyPolicy: data.privacyPolicy,
			color: data.color ? data.color : '',
		};

		return this.http.put(`${this.apiUrl}customer/landing-pages/${id}`, page);
	}

	// delete ip
	deleteLanding(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}customer/landing-pages/${id}`);
	}

	// delete plan
	deletePlan(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}subscription/plans/${id}`);
	}

	// add plan
	addPlan(data: any): Observable<any> {
		const group = {
			partner: data.partner ? data.partner[0] : null,
			products: data.products,
			learnings: data.learnings,
			name: data.name,
			interval: data.interval,
			paddlePlanId: data.paddlePlanId ? data.paddlePlanId : '',
			paymentProvider: data.paymentProvider ? data.paymentProvider : '',
			paymentProviderId: data.paymentProviderId ? data.paymentProviderId : '',
		};

		return this.http.post(`${this.apiUrl}subscription/plans`, group);
	}

	// update plan
	updatePlan(data: any, id: any): Observable<any> {
		const group = {
			products: data.products,
			learnings: data.learnings,
			name: data.name,
			interval: data.interval,
			paddlePlanId: data.paddlePlanId ? data.paddlePlanId : '',
			paymentProvider: data.paymentProvider ? data.paymentProvider : '',
			paymentProviderId: data.paymentProviderId ? data.paymentProviderId : '',
		};

		if (data.planGroups && data.planGroups.length > 0) {
			// @ts-ignore
			group.planGroups = data.planGroups.map((item) => item.id);
		}

		return this.http.put(`${this.apiUrl}subscription/plans/${id}`, group);
	}

	resetUserPassword(email: string, partner?: number): Observable<any> {
		const mail = {
			email: email,
			platform: 'library',
		};

		if (partner) {
			mail['partner'] = partner;
		}
		return this.http.post(`${this.apiUrlPublic}auth/forgot-password`, mail);
	}

	// Add arm instructions
	addInstruction(data): Observable<any> {
		const instruction = {
			title: {
				en: data.title_en,
				de: data.title_de,
			},
			description: {
				en: data.description_en,
				de: data.description_de,
			},
			attachmentLanguage: data.language ? data.language.toLowerCase() : 'en',
			attachment: data.file,
			priority: data.priority,
		};

		return this.http.post(`${this.apiUrl}configuration/arm-downloads`, instruction);
	}

	// Update arm instructions
	updateInstructions(data, id): Observable<any> {
		const instruction = {
			title: {
				en: data.title_en,
				de: data.title_de,
			},
			description: {
				en: data.description_en,
				de: data.description_de,
			},
			attachmentLanguage: data.language ? data.language.toLowerCase() : 'en',
			attachment: data.file,
			priority: data.priority,
		};

		return this.http.put(`${this.apiUrl}configuration/arm-downloads/${id}`, instruction);
	}

	// Delete arm instructions
	deleteInstructions(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}configuration/arm-downloads/${id}`);
	}

	// Add arm tutorials
	addTutorials(data): Observable<any> {
		const tutorials = {
			title: {
				en: data.title_en,
				de: data.title_de,
			},
			description: {
				en: data.description_en,
				de: data.description_de,
			},
			url: {
				en: data.url_en,
				de: data.url_de,
			},
			priority: data.priority,
		};

		return this.http.post(`${this.apiUrl}configuration/arm-tutorials`, tutorials);
	}

	// Update arm tutorials
	updateTutorials(data, id): Observable<any> {
		const tutorials = {
			title: {
				en: data.title_en,
				de: data.title_de,
			},
			description: {
				en: data.description_en,
				de: data.description_de,
			},
			url: {
				en: data.url_en,
				de: data.url_de,
			},
			priority: data.priority,
		};

		return this.http.put(`${this.apiUrl}configuration/arm-tutorials/${id}`, tutorials);
	}

	// Delete arm tutorials
	deleteTutorials(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}configuration/arm-tutorials/${id}`);
	}

	// add subscription plans group
	addPlansGroup(data: any): Observable<any> {
		const system = {
			name: data.name,
			plans: data.plans ? data.plans : null,
			partner: data.partner ? data.partner[0] : null,
		};

		return this.http.post(`${this.apiUrl}subscription/plan-groups`, system);
	}

	// edit subscription plans group
	editPlansGroup(data: any, id: any): Observable<any> {
		const system = {
			name: data.name,
			plans: data.plans ? data.plans : null,
		};

		return this.http.put(`${this.apiUrl}subscription/plan-groups/${id}`, system);
	}

	// delete subscription plans group
	deletePlansGroup(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}subscription/plan-groups/${id}`);
	}

	// Trigger reader html generation
	triggerReaderHtml(id: string): Observable<any> {
		return this.http.post(`${this.apiUrl}product/products/${id}/actions/convert-epub-to-html`, '');
	}

	// Update reader html
	updateReaderHtml(data, id): Observable<any> {
		const book = {
			content: data.content ? data.content : null,
			readingSampleUUID: data.readingSampleUUID ? data.readingSampleUUID : null,
		};

		return this.http.put(`${this.apiUrl}product/products/${id}/book`, book);
	}

	// Trigger reader html generation
	addAttandeesSubscription(id: number, plan: any): Observable<any> {
		return this.http.post(`${this.apiUrl}conference/conferences/${id}/actions/assign-subscription-plan`, { plan: plan.result });
	}

	// delete notification
	deleteNotification(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}conference/conferences/notification/${id}`);
	}

	// add call to action
	addCallToAction(data): Observable<any> {
		const id = data.conference[0];

		const actions = {
			actions: data.actions,
		};

		return this.http.put(`${this.apiUrl}conference/conferences/${id}/ctas`, actions);
	}

	// get detail data
	getNotification(path: string): Observable<any> {
		return this.http.get(`${this.apiUrl}${path}`);
	}

	// get winner data
	getWinner(path: string, total: number): Observable<any> {
		const body = {
			totalWinners: total,
		};

		return this.http.post(`${this.apiUrl}${path}`, body);
	}

	// add conf landing
	addConfLanding(data: any): Observable<any> {
		const page = {
			status: data.status,
			description: data.description,
			title: data.title,
			slug: data.slug,
		};

		if (data.conference) {
			// @ts-ignore
			page.conference = data.conference[0] ? data.conference[0] : null;
		}

		return this.http.post(`${this.apiUrl}conference/landing-pages`, page);
	}

	// update conf landing
	updateConfLanding(data: any, id: any, slug: string): Observable<any> {
		const page = {
			status: data.status,
			description: data.description,
			title: data.title,
			slug: slug,
		};

		return this.http.put(`${this.apiUrl}conference/landing-pages/${id}`, page);
	}

	// delete conf landing
	deleteConfLanding(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}conference/landing-pages/${id}`);
	}

	// delete push notifications
	deletePushNotification(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}configuration/push-notifications/${id}`);
	}

	// add push notification
	addPushNotification(
		title: string,
		content: string,
		status: string,
		sendAt: string,
		locale: string,
		titleDe: string,
		contentDe: string,
		titleFr: string,
		contentFr: string
	): Observable<any> {
		const date = sendAt.replace('T', ' ');

		const notification = {
			sendAt: date,
			status,
			content: {
				en: content,
				de: contentDe ? contentDe : '',
				fr: contentFr ? contentFr : '',
			},
			title: {
				en: title,
				de: titleDe ? titleDe : '',
				fr: titleFr ? titleFr : '',
			},
		};

		return this.http.post(`${this.apiUrl}configuration/push-notifications?locale=${locale}`, notification);
	}

	// update push notification
	updatePushNotification(
		id: any,
		title: string,
		content: string,
		status: string,
		sendAt: string,
		locale: string,
		titleDe: string,
		contentDe: string,
		titleFr: string,
		contentFr: string
	): Observable<any> {
		const date = sendAt.replace('T', ' ');

		const notification = {
			sendAt: date,
			status,
			content: {
				en: content,
				de: contentDe ? contentDe : '',
				fr: contentFr ? contentFr : '',
			},
			title: {
				en: title,
				de: titleDe ? titleDe : '',
				fr: titleFr ? titleFr : '',
			},
		};

		return this.http.put(`${this.apiUrl}configuration/push-notifications/${id}`, notification);
	}

	// Reset conference chat
	resetConferenceChat(id: number): Observable<any> {
		return this.http.post(`${this.apiUrl}conference/conferences/${id}/actions/clear-chat`, '');
	}

	// delete certificates
	deleteCertificates(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}certificate/certificates/${id}`);
	}

	// add certificate
	addCertificate(data): Observable<any> {
		const body = {
			status: data.status,
			partner: data.partner ? data.partner[0] : null,
			language: data.language ? data.language : null,
			title: data.title,
			description: data.description ? data.description : null,
			excerpt: data.excerpt ? data.excerpt : null,
			level: data.level ? data.level : null,
			badge: data.badge ? data.badge : null,
			thumbnail: data.thumbnail ? data.thumbnail : null,
			passingPercentage: data.passingPercentage ? data.passingPercentage : null,
			verificationQuestionsCount: data.verificationQuestionsCount ? data.verificationQuestionsCount : null,
			questions: data.questions ? data.questions : [],
			verificationMinutes: data.verificationMinutes ? data.verificationMinutes : 0,
			syncToWebShop: data.syncToWebShop ? data.syncToWebShop : false,
			paymentProvider: data.paymentProvider ? data.paymentProvider : '',
			paymentProviderId: data.paymentProviderId ? data.paymentProviderId : '',
		};

		const categories = [];
		if (data.categories) {
			data.categories.forEach((el) => {
				categories.push(el);
			});
			data.categories = categories;
			// @ts-ignore
			body.categories = data.categories ? data.categories : [];
		}

		if (data.paddleId) {
			// @ts-ignore
			body.paddleId = data.paddleId;
		}

		if (data.free) {
			// @ts-ignore
			body.free = data.free;
		}

		const children = [];
		if (data.children) {
			data.children.forEach((el) => {
				children.push(el);
			});
			data.children = children;
			// @ts-ignore
			body.children = data.children ? data.children : null;
		}

		return this.http.post(`${this.apiUrl}certificate/certificates`, body);
	}

	// update certificate
	updateCertificate(data: Certificate, id: any): Observable<any> {
		const body = {
			status: data.status,
			language: data.language ? data.language : null,
			title: data.title,
			description: data.description ? data.description : null,
			excerpt: data.excerpt ? data.excerpt : null,
			level: data.level ? data.level : null,
			badge: data.badge ? data.badge : null,
			thumbnail: data.thumbnail ? data.thumbnail : null,
			passingPercentage: data.passingPercentage ? data.passingPercentage : null,
			verificationQuestionsCount: data.verificationQuestionsCount ? data.verificationQuestionsCount : null,
			questions: data.questions ? data.questions : [],
			verificationMinutes: data.verificationMinutes ? data.verificationMinutes : 0,
			syncToWebShop: data.syncToWebShop ? data.syncToWebShop : false,
		};

		const categories = [];
		if (data.categories) {
			data.categories.forEach((el) => {
				categories.push(el);
			});
			data.categories = categories;
			// @ts-ignore
			body.categories = data.categories ? data.categories : [];
		}

		if (data.free) {
			// @ts-ignore
			body.free = data.free;
		}

		const children = [];
		if (data.children) {
			data.children.forEach((el) => {
				children.push(el);
			});
			data.children = children;
			// @ts-ignore
			body.children = data.children ? data.children : null;
		}

		return this.http.put(`${this.apiUrl}certificate/certificates/${id}`, body);
	}

	// delete commercial
	deleteCommercial(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}ad/ads/${id}`);
	}

	// add commercial
	addCommercial(data): Observable<any> {
		const body = {
			status: data.status,
			title: data.title,
			text: data.text ? data.text : null,
			label: data.label ? data.label : null,
			url: data.url ? data.url : null,
			position: data.position ? data.position : null,
			condition: data.condition ? data.condition : null,
			startsAt: data.startsAt ? data.startsAt : null,
			endsAt: data.endsAt ? data.endsAt : null,
			image: data.image ? data.image : null,
		};

		return this.http.post(`${this.apiUrl}ad/ads`, body);
	}

	// update commercial
	updateCommercial(data, id): Observable<any> {
		const body = {
			status: data.status,
			title: data.title,
			text: data.text ? data.text : null,
			label: data.label ? data.label : null,
			url: data.url ? data.url : null,
			position: data.position ? data.position : null,
			condition: data.condition ? data.condition : null,
			startsAt: data.startsAt ? data.startsAt : null,
			endsAt: data.endsAt ? data.endsAt : null,
			image: data.image ? data.image : null,
		};

		return this.http.put(`${this.apiUrl}ad/ads/${id}`, body);
	}

	/**
	 * Export data
	 * @param url type: string
	 * @param id type: number
	 */
	exportData(id: number, url: any): Observable<any> {
		return this.http.get(`${this.apiUrl}${url}`, {
			responseType: 'blob',
		});
	}

	// delete line item
	deleteLineItem(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}author-accounting/line-items/${id}`);
	}

	// delete line items
	deleteLineItems(date: string, origin: string): Observable<any> {
		const item = {
			date: date,
			origin: origin,
		};
		return this.http.delete(`${this.apiUrl}author-accounting/line-items/actions/delete-by-origin`, { body: item });
	}

	// add line item
	addLineItem(data: any): Observable<any> {
		const item = {
			currency: data.currency ? data.currency : null,
			amountForeignCurrency: data.amountForeignCurrency ? data.amountForeignCurrency : null,
			date: data.date ? data.date : null,
			quantity: data.quantity ? data.quantity : null,
			account: data.account ? data.account : null,
			origin: data.origin ? data.origin : null,
		};

		if (data.businessSegment && data.businessSegment.length) {
			// @ts-ignore
			item.businessSegment = data.businessSegment[0];
		}

		if (data.product && data.product.length) {
			// @ts-ignore
			item.product = data.product[0];
		}

		if (data.country && data.country.length) {
			// @ts-ignore
			item.country = data.country[0];
		}

		if (data.functional_area && data.functional_area.length) {
			// @ts-ignore
			item.functionalArea = data.functional_area[0];
		}

		if (data.agent && data.agent.length) {
			// @ts-ignore
			item.agent = data.agent[0];
		}

		if (data.categoryOfCost && data.categoryOfCost.length) {
			// @ts-ignore
			item.categoryOfCost = data.categoryOfCost[0];
		}

		if (data.distributionChannel && data.distributionChannel.length) {
			// @ts-ignore
			item.distributionChannel = data.distributionChannel[0];
		}

		if (data.lineItemCategory && data.lineItemCategory.length) {
			// @ts-ignore
			item.lineItemCategory = data.lineItemCategory[0];
		}

		return this.http.post(`${this.apiUrl}author-accounting/line-items`, item);
	}

	// add accounting data
	addAccountingData(data): Observable<any> {
		console.log('addAccountingData', data);

		const account = {
			from: `${data.fromY}-${data.fromM}`,
			until: `${data.untilY}-${data.untilM}`,
			libraryCosts: data.libraryCosts ? data.libraryCosts : '0.00',
		};

		if (data.authors) {
			const authors = [];
			data.authors.forEach((el) => {
				authors.push(el);
			});
			// @ts-ignore
			account.authors = authors;
		}

		if (data.products) {
			const products = [];
			data.products.forEach((el) => {
				products.push(el);
			});
			// @ts-ignore
			account.products = products;
		}

		return this.http.post(`${this.apiUrl}author-accounting/actions/generate-credit-lines`, account);
	}

	generateCredits(data): Observable<any> {
		console.log('generateCredits', data);
		const account = {
			from: `${data.fromY}-${data.fromM}`,
			until: `${data.untilY}-${data.untilM}`,
			creditDate: data.date,
		};

		if (data.authors) {
			const authors = [];
			data.authors.forEach((el) => {
				authors.push(el);
			});
			// @ts-ignore
			account.authors = authors;
		}

		return this.http.post(`${this.apiUrl}author-accounting/actions/generate-credits`, account);
	}

	// export credit as pdf
	getExportCredit(id: string): Observable<any> {
		return this.http.get(`${this.apiUrl}author-accounting/credits/${id}/actions/download-pdf`, {
			responseType: 'blob',
		});
	}

	// export credits as zip
	getExportCredits(from: string, until: string): Observable<any> {
		return this.http.get(`${this.apiUrl}author-accounting/credits/actions/send-pdfs-by-period?from=${from}&until=${until}`, {
			responseType: 'blob',
		});
	}

	// cancel credit
	cancelCredit(id: string): Observable<any> {
		return this.http.get(`${this.apiUrl}author-accounting/credits/${id}/actions/cancel`);
	}

	// send credit
	sendCredit(id: string): Observable<any> {
		return this.http.get(`${this.apiUrl}author-accounting/credits/${id}/actions/send-mail`);
	}

	// send credits
	sendCredits(): Observable<any> {
		return this.http.get(`${this.apiUrl}author-accounting/credits/actions/send-mails`);
	}

	// delete billing country
	deleteCountry(code: string): Observable<any> {
		return this.http.delete(`${this.apiUrl}configuration/countries/${code}`);
	}

	// delete line item category
	deleteLineItemCategory(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}author-accounting/line-item-categories/${id}`);
	}

	// delete distribution channel
	deleteDistributionChannel(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}author-accounting/distribution-channels/${id}`);
	}

	addOrUpdateCountry(data, isUpdate): Observable<any> {
		const country = {
			code: data.code[0],
			currency: data.currency[0],
			taxRate: data.taxRate,
			withholdingTaxRate: data.withholdingTaxRate,
			solidaritySurchargeOnWithholdingTaxRate: data.solidaritySurchargeOnWithholdingTaxRate,
			isEuCountry: data.isEuCountry,
		};

		if (isUpdate) {
			return this.http.put(`${this.apiUrl}configuration/countries/${data.code[0]}`, country);
		} else {
			return this.http.post(`${this.apiUrl}configuration/countries`, country);
		}
	}

	addOrUpdateDistributionChannel(data, isUpdate): Observable<any> {
		const country = {
			name: data.name,
			discount: data.discount,
			authorAccounting: data.authorAccounting,
			logisticCosts: data.logisticCosts,
			paymentCosts: data.paymentCosts,
		};

		if (isUpdate) {
			return this.http.put(`${this.apiUrl}author-accounting/distribution-channels/${isUpdate}`, country);
		} else {
			return this.http.post(`${this.apiUrl}author-accounting/distribution-channels`, country);
		}
	}

	addOrUpdateLineItemCategory(data, isUpdate): Observable<any> {
		const country = {
			name: data.name,
		};

		if (isUpdate) {
			return this.http.put(`${this.apiUrl}author-accounting/line-item-categories/${isUpdate}`, country);
		} else {
			return this.http.post(`${this.apiUrl}author-accounting/line-item-categories`, country);
		}
	}

	// delete functional area
	deleteFunctionalArea(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}author-accounting/functional-areas/${id}`);
	}

	// delete cost category
	deleteCostCategory(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}author-accounting/cost-categories/${id}`);
	}

	// delete agent
	deleteAgent(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}author-accounting/agents/${id}`);
	}

	addOrUpdateFunctionalArea(data, isUpdate): Observable<any> {
		const area = {
			name: data.name,
			number: data.number,
		};

		if (isUpdate) {
			return this.http.put(`${this.apiUrl}author-accounting/functional-areas/${isUpdate}`, area);
		} else {
			return this.http.post(`${this.apiUrl}author-accounting/functional-areas`, area);
		}
	}

	addOrUpdateAgent(data, isUpdate): Observable<any> {
		const agent = {
			name: data.name,
			number: data.number,
		};

		if (isUpdate) {
			return this.http.put(`${this.apiUrl}author-accounting/agents/${isUpdate}`, agent);
		} else {
			return this.http.post(`${this.apiUrl}author-accounting/agents`, agent);
		}
	}

	addOrUpdateCostCategories(data, isUpdate): Observable<any> {
		const agent = {
			name: data.name,
		};

		if (isUpdate) {
			return this.http.put(`${this.apiUrl}author-accounting/cost-categories/${isUpdate}`, agent);
		} else {
			return this.http.post(`${this.apiUrl}author-accounting/cost-categories`, agent);
		}
	}

	// export collmex data
	exportCollmex(data): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

		const exports = {
			from: `${data.fromY}-${data.fromM}`,
			until: `${data.untilY}-${data.untilM}`,
			date: data.date ? data.date : null,
		};

		return this.http
			.post(`${this.apiUrl}author-accounting/exports/collmex`, exports, {
				responseType: 'arraybuffer',
			})
			.pipe(
				map((file: ArrayBuffer) => {
					return file;
				})
			);
	}

	// export collmex data
	exportCollmex2(data): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

		const exports = {
			from: `${data.fromY}-${data.fromM}`,
			until: `${data.untilY}-${data.untilM}`,
			date: data.date ? data.date : null,
		};

		return this.http.post(`${this.apiUrl}author-accounting/exports/collmex-withholding-tax-rate`, exports, { responseType: 'arraybuffer' }).pipe(
			map((file: ArrayBuffer) => {
				return file;
			})
		);
	}

	// export elster data
	exportElster(data): Observable<any> {
		const exports = {
			from: `${data.fromY}-${data.fromM}`,
			until: `${data.untilY}-${data.untilM}`,
			date: data.date ? data.date : null,
		};

		return this.http
			.post(`${this.apiUrl}author-accounting/exports/elster`, exports, {
				responseType: 'arraybuffer',
			})
			.pipe(
				map((file: ArrayBuffer) => {
					return file;
				})
			);
	}

	// export transferwise data
	exportTransferWise(data): Observable<any> {
		const exports = {
			from: `${data.fromY}-${data.fromM}`,
			until: `${data.untilY}-${data.untilM}`,
		};

		return this.http
			.post(`${this.apiUrl}author-accounting/exports/transferwise`, exports, {
				responseType: 'arraybuffer',
			})
			.pipe(
				map((file: ArrayBuffer) => {
					return file;
				})
			);
	}

	// export transferwise data
	exportSac(data: any): Observable<any> {
		const exports = {
			year: data.year,
		};

		return this.http
			.post(`${this.apiUrl}author-accounting/exports/${data.type}`, exports, {
				responseType: 'arraybuffer',
			})
			.pipe(
				map((file: ArrayBuffer) => {
					return file;
				})
			);
	}

	// export ksk data
	exportKsk(data: any): Observable<any> {
		const exports = {
			year: data.year,
		};

		return this.http
			.post(`${this.apiUrl}author-accounting/exports/artists-social-security-fund`, exports, {
				responseType: 'arraybuffer',
			})
			.pipe(
				map((file: ArrayBuffer) => {
					return file;
				})
			);
	}

	// export yearly license fee data
	exportYear(data): Observable<any> {
		const exports = {
			reportingYear: data.year,
			period1From: `${data.from1Y}-${data.from1M}`,
			period1Until: `${data.until1Y}-${data.until1M}`,
			period1PaymentDate: data.date1,
			period2From: `${data.from2Y}-${data.from2M}`,
			period2Until: `${data.until2Y}-${data.until2M}`,
			period2PaymentDate: data.date2,
		};

		return this.http.post(`${this.apiUrl}author-accounting/exports/acr-license-fees`, exports, { responseType: 'arraybuffer' }).pipe(
			map((file: ArrayBuffer) => {
				return file;
			})
		);
	}

	// mass document cancel
	cancelDocuments(from: string, until: string): Observable<any> {
		const body = {
			from: from,
			until: until,
		};
		return this.http.post(`${this.apiUrl}author-accounting/credits/actions/cancel-by-period`, body);
	}

	// download documents
	downloadDocuments(from: string, until: string): Observable<any> {
		const body = {
			from: from,
			until: until,
		};
		return this.http.get(`${this.apiUrl}author-accounting/credits/actions/send-pdfs-by-period`, {
			params: body,
		});
	}

	/*
	 * Shortcut API
	 */

	// get data
	getShortcutData(path: string, id?: string): Observable<any> {
		return this.http.get(`${environment.shortcut}${path}`, {
			params: { id },
			headers: {
				'skip': 'true',
			},
		});
	}

	// add comment
	addShortcutComment(path: string, id: string, data: string, author?: string): Observable<any> {
		const comment = {
			id: id,
			author_id: author,
			text: data,
		};

		return this.http.post(`${environment.shortcut}${path}`, comment, {
			headers: {
				'skip': 'true',
			},
		});
	}

	// add story
	addShortcutStory(path: string, data: any): Observable<any> {
		const story = {
			epic_id: data.epic_id,
			owner_ids: [data.owner_ids],
			requested_by_id: data.requested_by_id,
			project_id: data.project_id,
			name: data.name,
			description: data.description,
			story_type: data.story_type,
			deadline: data.deadline ? new Date(data.deadline) : null,
			external_links: data.external_links ? data.external_links.split(',') : null,
		};

		return this.http.post(`${environment.shortcut}${path}`, story, {
			headers: {
				'skip': 'true',
			},
		});
	}

	// Get logistic costs
	getLogisticCosts(periode: any, costs: any): Observable<any> {
		const body = {
			period: periode,
			costs: costs,
		};

		return this.http.post(`${this.apiUrl}author-accounting/actions/calculate-logistic-costs`, body);
	}

	/**
	 * Get chat gpt
	 * @param messages type: any
	 */
	getChatGpt(messages: any): Observable<any> {
		const body = {
			messages: messages,
		};

		return this.http.post(`${this.apiUrlPublic}open-ai/chat`, body);
	}

	// delete course
	deleteCourse(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}online-trainings/${id}`);
	}

	// add course
	addCourse(data: any): Observable<any> {
		const course = {
			partner: data.partner ? data.partner[0] : null,
			dates: data.dates,
			product: data.product ? data.product[0] : null,
			learning: data.learning ? data.learning[0] : null,
			subscriptionPlan: data.subscriptionPlan ? data.subscriptionPlan[0] : null,
			status: data.status,
			minAttendees: data.minAttendees,
			maxAttendees: data.maxAttendees,
			price: data.price,
		};

		return this.http.post(`${this.apiUrl}online-trainings`, course);
	}

	// update course
	updateCourse(data: any, id: any): Observable<any> {
		const course = {
			dates: data.dates,
			subscriptionPlan: data.subscriptionPlan ? data.subscriptionPlan[0] : null,
			status: data.status,
			minAttendees: data.minAttendees,
			maxAttendees: data.maxAttendees,
			price: data.price,
			learning: data.learning ? data.learning[0] : null,
		};

		return this.http.put(`${this.apiUrl}online-trainings/${id}`, course);
	}

	// get course feedback
	getCourseFeedback(path: string): Observable<any> {
		return this.http.get(`${this.apiUrl}${path}`);
	}

	// get courses statistics
	getCoursesStats(periodStart: string, periodEnd: string, referencePeriodStart: string, referencePeriodEnd: string): Observable<any> {
		const periode = {
			periodStart: periodStart,
			periodEnd: periodEnd,
			referencePeriodStart: referencePeriodStart,
			referencePeriodEnd: referencePeriodEnd,
		};

		return this.http.post(`${this.apiUrl}online-trainings/statistics/compare-by-period`, periode);
	}

	// index videos
	indexVideos(id: number): Observable<any> {
		return this.http.post(`${this.apiUrl}products/${id}/actions/index-videos`, '');
	}

	// Generate Instagram image
	generateInstagramImage(title: string, cover: string, authors: string, type: string): Observable<any> {
		return this.http.get(`https://social-image-generator.netlify.app/api/generate-social-images?title=${title}&cover=${cover}&authors=${authors}&type=${type}`);
	}

	// Generate Instagram event image
	generateInstagramEventImage(title: string, date: string, excerpt: string, type: string): Observable<any> {
		return this.http.get(`https://social-image-generator.netlify.app/api/generate-social-images?title=${title}&eventDate=${date}&eventExcerpt=${excerpt}&type=${type}`);
	}

	// Get product description via openai
	getProductDescription(id: number, instructions: string, prompt: string): Observable<any> {
		const data = {
			instructions: instructions,
			prompt: prompt,
		};

		return this.http.post(`${this.apiUrlPublic}admin/product/products/${id}/actions/generate-description-from-texttracks`, data);
	}

	// Remove user from customer
	removeAccountFromCustomer(customer: number, id: number): Observable<any> {
		return this.http.post(`${this.apiUrl}customer/customers/${customer}/accounts/${id}/actions/detach`, '');
	}

	// Add user to customer
	addAccountToCustomer(customer: number, id: number, isInArray: boolean, role?: string): Observable<any> {
		const body = {
			role: role ? role : null,
		};

		let url = `customer/customers/${customer}/accounts/${id}/actions/attach`;
		if (isInArray) {
			url = `customer/customers/${customer}/accounts/${id}/actions/update`;
		}

		return this.http.post(`${this.apiUrl}${url}`, body);
	}

	duplicateContent(ulid: string): Observable<any> {
		return this.http.post(`${this.apiUrlPublic}admin/customer-specific-contents/${ulid}/actions/adopt`, '');
	}

	// delete product rating
	deleteProductRating(id: number, product: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}product/products/${product}/ratings/${id}`);
	}

	// delete feedback
	deleteFeedback(id: number): Observable<any> {
		return this.http.delete(`${this.apiUrl}feedback-forms/${id}`);
	}

	// add feedback
	addFeedback(data: { name: string; status: string; startsAt: string; endsAt: string }): Observable<any> {
		const body = {
			name: data.name,
			status: data.status,
			startsAt: data.startsAt,
			endsAt: data.endsAt,
		};

		return this.http.post(`${this.apiUrl}feedback-forms`, body);
	}

	// export feedback
	exportFeedback(id: string): Observable<any> {
		return this.http.get(`${this.apiUrl}feedback-forms/${id}/actions/export`, {
			responseType: 'blob',
		});
	}

	// delete user
	sendMailUser(id: number, platform: string): Observable<any> {
		const body = {
			platform: platform,
		};
		return this.http.post(`${this.apiUrl}accounts/${id}/actions/send-welcome-email`, body);
	}

	// Get product statistics
	getProductStatistics(id: number): Observable<any> {
		return this.http.get(`${this.apiUrl}product/products/${id}/statistics`);
	}

	addPost(data: Post): Observable<any> {
		const post = {
			title: data.title,
			status: data.status,
			contents: data.contents,
			image: data.image ? data.image : null,
			slug: data.slug,
			language: data.language,
		};

		return this.http.post(`${this.apiUrl}posts`, post);
	}

	updatePost(data: Post, id: string): Observable<any> {
		const post = {
			title: data.title,
			status: data.status,
			contents: data.contents,
			image: data.image ? data.image : null,
			slug: data.slug,
			language: data.language,
		};

		return this.http.put(`${this.apiUrl}posts/${id}`, post);
	}

	// delete post
	deletePost(id: string): Observable<any> {
		return this.http.delete(`${this.apiUrl}posts/${id}`);
	}

	// generate product slug
	generateProductSlug(id: string | number): Observable<any> {
		return this.http.get(`${this.apiUrl}products/${id}/actions/slugify`);
	}

	saveOrderNotes(id: number | string, notes: string): Observable<any> {
		return this.http.put(`${this.apiUrl}orders/${id}`, { notes });
	}

	orderPaid(id: number | string): Observable<any> {
		return this.http.put(`${this.apiUrl}orders/${id}/actions/pay`, '');
	}

	orderShipped(id: number | string, trackingNumber?: string): Observable<any> {
		return this.http.put(`${this.apiUrl}orders/${id}/actions/ship`, { trackingNumber: trackingNumber ? trackingNumber : '' });
	}

	orderRefund(id: number | string, reason: string, refundItems: Array<any>): Observable<any> {
		return this.http.put(`${this.apiUrl}orders/${id}/actions/refund`, { items: refundItems ? refundItems : [], reason: reason });
	}
}
