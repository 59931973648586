<div class="">
	<div class="border-b border-solid border-gray-650 px-4 py-2 text-center font-semibold text-gray-650">Apps</div>
	<div class="grid grid-cols-3 gap-2 p-4">
		<div *ngFor="let item of nav" appAccessControl [accessRole]="item.roles">
			<a
				class="group flex aspect-square flex-col justify-center rounded-lg text-center transition-colors duration-200 ease-in-out hover:bg-blue-600"
				[routerLink]="item.path"
				[routerLinkActive]="item.active"
			>
				<fa-icon class="mb-1 text-gray-650 group-hover:text-white" [icon]="item.icon"></fa-icon>
				<span class="block text-center text-xs leading-none text-gray-200 group-hover:text-white">{{ item.name }}</span>
			</a>
		</div>
	</div>
</div>
