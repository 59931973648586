import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/session/session-guard';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
	},
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule),
		title: 'Login - PLM – Espresso Tutorials GmbH',
	},
	{
		path: 'two-factor/:twoFactorIdentifier',
		loadChildren: () => import('./pages/two-factor/two-factor.module').then((m) => m.TwoFactorModule),
		title: 'Zwei-Faktor Authentifizierung - PLM – Espresso Tutorials GmbH',
	},
	{
		path: '2fa-recovery-code/:twoFactorIdentifier',
		loadChildren: () => import('./pages/two-factor-recovery/two-factor-recovery.module').then((m) => m.TwoFactorRecoveryModule),
		title: 'Zwei-Faktor Authentifizierung Wiederherstellung - PLM – Espresso Tutorials GmbH',
	},
	{
		path: 'password-forget',
		loadChildren: () => import('./pages/password-forget/password-forget.module').then((m) => m.PasswordForgetModule),
		title: 'Passwort vergessen - PLM – Espresso Tutorials GmbH',
	},
	{
		path: 'reset-password',
		loadChildren: () => import('./pages/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
	},
	{
		path: 'dashboard',
		loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
		canActivate: [AuthGuard],
		title: 'Dashboard - PLM – Espresso Tutorials GmbH',
	},
	{
		path: '**',
		loadChildren: () => import('./pages/page-not-found/page-not-found.module').then((m) => m.PageNotFoundModule),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
