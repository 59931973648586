import { Injectable } from '@angular/core';
import { Query, toBoolean } from '@datorama/akita';
import { SessionStore, SessionState } from './session.store';

@Injectable({
	providedIn: 'root',
})
export class SessionQuery extends Query<SessionState> {
	isLoggedIn$ = this.select((state) => toBoolean(state.accessToken));

	constructor(protected store: SessionStore) {
		super(store);
	}

	isLoggedIn(): any {
		return toBoolean(this.getValue().accessToken);
	}

	getToken(): any {
		return this.getValue().accessToken;
	}

	hasRoles(): any {
		return this.getValue().roles;
	}

	getImage(): any {
		return this.getValue().image ? this.getValue().image : null;
	}

	getGivenName(): string {
		return this.getValue().givenName ? this.getValue().givenName : '';
	}

	getFamilyName(): string {
		return this.getValue().familyName ? this.getValue().familyName : '';
	}

	getMail(): string {
		return this.getValue().mail ? this.getValue().mail : '';
	}

	getUserId(): any {
		return this.getValue().id;
	}

	getLanguage(): string {
		return this.getValue().language ? this.getValue().language : '';
	}
}
