import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'levels',
})
export class LevelsPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		switch (value) {
			case 'beginner':
				return 'Anfänger';
			case 'intermediate':
				return 'Fortgeschritten';
			case 'expert':
				return 'Experte';
			default:
				return 'Anfänger';
		}
	}
}
