<ng-container *transloco="let t">
	<div class="relative max-h-modal sm:w-full sm:max-w-lg">
		<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
			<div class="sticky top-0 z-10 w-full bg-white">
				<h3 class="mb-2 text-lg font-medium leading-6 text-gray-900" id="modal-title">
					{{ t('UI.select_user') }}
				</h3>
				<input
					#search
					type="text"
					class="mb-3 block w-full appearance-none rounded border border-gray-500 bg-gray-300 px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
					placeholder="Medien suchen"
				/>
				<div class="flex w-full flex-row items-center bg-gray-200">
					<div class="imge-content flex flex-row items-center">
						<div class="px-4 py-2 text-xs leading-tight text-gray-650">ID</div>
						<div class="p-2 text-xs leading-tight text-gray-650">Name</div>
					</div>
				</div>
			</div>
			<div class="relative max-h-modalInner overflow-y-auto overflow-x-hidden">
				<app-loading [loading]="loading"></app-loading>
				<div class="flex w-full flex-row flex-wrap" *ngIf="data">
					<cdk-virtual-scroll-viewport itemSize="35" class="user divide-y divide-gray-400 bg-white">
						@if (showRoles) {
							<div class="user__item user__item--large" *cdkVirtualFor="let element of data; trackBy: trackByFn; let i = index">
								<div class="whitespace-nowrap px-4 py-2 text-xs text-gray-800" title="{{ t('UI.add_user') }}" id="user-{{ i }}">
									<div class="flex flex-row">
                    <div class="w-8">
                      <button
                        class="cursor-pointer appearance-none text-blue-500 outline-none transition-colors duration-200 ease-in-out hover:text-orange-500"
                        (click)="selectAccount(element.id, element.givenName, element.familyName, element.email, i)"
                      >
                        <fa-icon [icon]="faAdd"></fa-icon>
                      </button>
                    </div>
										<div class="flex-1">
											<div class="flex flex-row items-center">{{ element.id }} - {{ element.givenName }} {{ element.familyName }} - {{ element.email }}</div>
											<ul class="mt-1 flex flex-row items-center">
												<li class="mr-4">
													<label for="manager-{{ i }}">
														<input class="outline-none ring-0 focus:outline-none focus:ring-0" id="manager-{{ i }}" type="radio" name="role-{{ i }}" value="MANAGER" />
														Manager
													</label>
												</li>
												<li>
													<label for="editor-{{ i }}">
														<input class="outline-none ring-0 focus:outline-none focus:ring-0" id="editor-{{ i }}" type="radio" name="role-{{ i }}" value="CSC_EDITOR" />
														Editor
													</label>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						} @else {
							<div class="user__item" *cdkVirtualFor="let element of data; trackBy: trackByFn">
								<div
									class="cursor-pointer whitespace-nowrap px-4 py-2 text-xs text-gray-800 hover:text-blue-500"
									title="{{ t('UI.add_user') }}"
									(click)="selectAccount(element.id, element.givenName, element.familyName, element.email)"
								>
									{{ element.id }} - {{ element.givenName }} {{ element.familyName }} - {{ element.email }}
								</div>
							</div>
						}
					</cdk-virtual-scroll-viewport>
					<div class="flex w-full flex-row flex-wrap items-center justify-between pb-4" *ngIf="meta">
						<div>
							<button
								(click)="pagination('prev')"
								*ngIf="meta.current_page > 1"
								class="flex h-8 w-8 appearance-none items-center justify-center"
								title="{{ t('UI.previous_page') }}"
							>
								<fa-icon class="w-4-h4" [icon]="faPrevious"></fa-icon>
							</button>
						</div>
						<div class="p-4">
							<p class="text-xs">
								{{ t('UI.user') }}: <span class="font-bold">{{ meta.from }}</span> - <span class="font-bold">{{ meta.to }}</span> | {{ t('UI.total') }}:
								<span class="font-bold">{{ meta.total }}</span>
							</p>
						</div>
						<div>
							<button
								(click)="pagination('next')"
								*ngIf="meta.current_page < meta.last_page"
								class="flex h-8 w-8 appearance-none items-center justify-center"
								title="{{ t('UI.next_page') }}"
							>
								<fa-icon class="w-4-h4" [icon]="faNext"></fa-icon>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="sticky bottom-0 bg-white px-4 pb-4 pt-3 sm:flex sm:flex-row-reverse sm:px-6 sm:pb-6">
			<button
				type="button"
				class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
				(click)="onClose()"
			>
				{{ t('UI.cancel') }}
			</button>
		</div>
	</div>
</ng-container>
