import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'app-send-mail-dialog',
	templateUrl: './send-mail-dialog.component.html',
	styleUrls: ['./send-mail-dialog.component.css'],
})
export class SendMailDialogComponent implements OnInit {
	public title: string;

	constructor(
		public dialogRef: MatDialogRef<SendMailDialogComponent>,
		@Inject(MAT_DIALOG_DATA) data: any
	) {
		this.title = data.type;
	}

	ngOnInit(): void {}

	onSend(platform: string): void {
		this.dialogRef.close({ platform: platform });
	}

	onClose(): void {
		this.dialogRef.close();
	}
}
