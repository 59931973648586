<ng-container *transloco="let t">
	<div class="relative max-h-modal w-full">
		<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
			<div class="sticky top-0 z-10 w-full bg-white">
				<h3 class="mb-2 text-lg font-medium leading-6 text-gray-900" id="modal-title">
					{{ t('UI.select_file') }}
				</h3>
				<input
					#search
					type="text"
					class="mb-3 block w-full appearance-none rounded border border-gray-500 bg-gray-300 px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
					placeholder="Medien suchen"
				/>
				<div class="flex w-full flex-row items-center bg-gray-200">
					<div class="imge p-2 text-xs leading-tight text-gray-650">
						{{ t('UI.preview') }}
					</div>
					<div class="imge-content flex flex-row items-center">
						<div class="w-1/5 px-4 py-2 text-xs leading-tight text-gray-650">Meta</div>
						<div class="w-2/5 px-4 py-2 text-xs leading-tight text-gray-650">
							{{ t('UI.type') }}
						</div>
						<div class="w-2/5 p-2 text-xs leading-tight text-gray-650">Name</div>
					</div>
				</div>
			</div>
			<div class="relative max-h-modalInner overflow-y-auto overflow-x-hidden">
				<app-loading [loading]="loading"></app-loading>
				<div class="flex w-full flex-row flex-wrap" *ngIf="data">
					<div class="w-full py-1" *ngFor="let img of data">
						<app-media-object [mediaObject]="img" [controls]="false" (select)="selectFile($event)"></app-media-object>
					</div>
				</div>
				<div class="flex w-full flex-row flex-wrap items-center justify-between pb-4" *ngIf="meta">
					<div>
						<button (click)="pagination('prev')" *ngIf="meta.current_page > 1" class="flex h-8 w-8 appearance-none items-center justify-center" title="{{ t('UI.previous_page') }}">
							<fa-icon class="w-4-h4" [icon]="faPrevious"></fa-icon>
						</button>
					</div>
					<div class="p-4">
						<p class="text-xs">
							{{ t('UI.media') }}: <span class="font-bold">{{ meta.from }}</span> - <span class="font-bold">{{ meta.to }}</span> | {{ t('UI.total') }}:
							<span class="font-bold">{{ meta.total }}</span>
						</p>
					</div>
					<div>
						<button
							(click)="pagination('next')"
							*ngIf="meta.current_page < meta.last_page"
							class="flex h-8 w-8 appearance-none items-center justify-center"
							title="{{ t('UI.next_page') }}"
						>
							<fa-icon class="w-4-h4" [icon]="faNext"></fa-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="sticky bottom-0 bg-white px-4 pb-4 pt-3 sm:flex sm:flex-row-reverse sm:px-6 sm:pb-6">
			<ng-container *ngIf="isFolder">
				<button
					type="button"
					class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
					(click)="goBack(meta.parent)"
				>
					{{ t('UI.back') }}
				</button>
				<ng-container *ngIf="allowFolderSelection">
					<button
						type="button"
						class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
						(click)="selectFolder()"
					>
						{{ t('UI.select_dir') }}
					</button>
				</ng-container>
			</ng-container>
			<button
				type="button"
				class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
				(click)="onClose()"
			>
				{{ t('UI.cancel') }}
			</button>
		</div>
	</div>
</ng-container>
