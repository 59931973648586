import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
// import * as Sentry from '@sentry/angular-ivy';

@Injectable({
	providedIn: 'root',
})
export class ErrorHandlerService {
	private message: string;

	constructor(
		private snackBar: MatSnackBar,
		private router: Router,
		private authService: AuthService
	) {}

	// Error Handler
	errorHandler(error: any, type?: string): void {
		switch (error.status) {
			case 401:
				this.message = `${error.status} - ${error.error?.message ? error.error?.message : ''} Please log in again.`;
				this.authService.logout();
				break;
			case 403:
				this.message = `${error.status} - ${error.error?.message ? error.error?.message : ''} Redirected to dashboard.`;
				this.router.navigateByUrl('/dashboard').then();
				break;
			default:
				this.message = `${error.status} - ${error.error?.message ? error.error?.message : ''}`;
		}

		// Sentry.captureException(error.error);
		this.openSnackBar(this.message);
	}

	// Display Snackbar
	openSnackBar(message: string): any {
		this.snackBar.open(message, 'OK', {
			duration: 5000,
			horizontalPosition: 'right',
			verticalPosition: 'bottom',
		});
	}
}
