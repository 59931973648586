import { UntypedFormControl } from '@angular/forms';

export function requiredFileType(types: Array<string>): any {
	return (control: UntypedFormControl) => {
		const file = control.value;

		if (file) {
			Array.from(file).forEach((item: any) => {
				const extension = item.name.split('.')[1].toLowerCase();

				if (!types.includes(extension.toLowerCase())) {
					return {
						requiredFileType: true,
					};
				}

				return null;
			});
		}

		return null;
	};
}
