<ng-container *transloco="let t">
	<router-outlet></router-outlet>
	<app-versioning-banner id="versioning-banner" class="hidden">
		<div class="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6">
			<div class="pointer-events-auto ml-auto max-w-xl rounded-xl bg-white p-6 shadow-lg">
				<p class="text-sm leading-6 text-gray-900">
					{{ t('UI.update_information') }}
				</p>
				<div class="mt-4 flex items-center gap-x-5">
					<button
						(click)="reloadPage()"
						type="button"
						class="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
					>
						{{ t('UI.reload_page') }}
					</button>
					<button (click)="closeVersionBanner()" type="button" class="text-sm font-semibold leading-6 text-gray-900">
						{{ t('UI.close') }}
					</button>
				</div>
			</div>
		</div>
	</app-versioning-banner>
</ng-container>
