import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Dirty } from '../../interfaces/dirty';
import { Injectable } from '@angular/core';

export interface DirtyState extends EntityState<Dirty> {}

const initialState = {
	dirty: false,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'dirty' })
export class DirtyStore extends EntityStore<DirtyState, Dirty> {
	constructor() {
		super(initialState);
	}
}
