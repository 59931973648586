<div class="min-h-screen w-full p-4">
	<div class="h-fullscreen w-full bg-white">
		<div class="flex w-full flex-row items-center justify-between border-b border-solid border-gray-600" *ngIf="attachment">
			<div class="flex w-full flex-row">
				<div class="border-r border-solid border-gray-600 px-4 py-3">
					<p class="pb-1 text-sm text-gray-900">Filename</p>
					<p class="text-sm leading-none text-gray-700">
						{{ attachment.name }}
					</p>
				</div>
				<div class="border-r border-solid border-gray-600 px-4 py-3">
					<p class="pb-1 text-sm text-gray-900">Größe</p>
					<p class="font-mono text-sm leading-none text-gray-700">
						{{ attachment.size | filesize }}
					</p>
				</div>
				<div class="border-r border-solid border-gray-600 px-4 py-3">
					<p class="pb-1 text-sm text-gray-900">Sichtbarkeit</p>
					<p class="font-mono text-sm leading-none text-gray-700">
						{{ attachment.visibility }}
					</p>
				</div>
			</div>
			<div class="flex w-full flex-row items-center justify-end">
				<a
					[href]="attachment.url"
					target="_blank"
					class="inline-flex cursor-pointer items-center justify-center border-l border-solid border-gray-600 px-4 py-3 text-2xl text-gray-700 transition-colors duration-200 ease-in-out hover:text-blue-500 focus:outline-none"
					title="In neuem Fenster öffnen"
				>
					<fa-icon [icon]="faTab"></fa-icon>
				</a>
				<a
					[href]="attachment.url"
					download
					target="_blank"
					class="inline-flex cursor-pointer items-center justify-center border-l border-solid border-gray-600 px-4 py-3 text-2xl text-gray-700 transition-colors duration-200 ease-in-out hover:text-blue-500 focus:outline-none"
					title="Datei herunterladen"
				>
					<fa-icon [icon]="faDownload"></fa-icon>
				</a>
				<div
					typeof="button"
					class="flex cursor-pointer items-center justify-center border-l border-solid border-gray-600 px-4 py-3 text-2xl text-gray-700 transition-colors duration-200 ease-in-out hover:text-blue-500 focus:outline-none"
					(click)="onClose()"
					title="Schließen"
				>
					<fa-icon [icon]="faClose"></fa-icon>
				</div>
			</div>
		</div>
		<div class="h-preview flex w-full flex-row">
			<div class="flex h-full w-2/3 flex-col bg-gray-800">
				<div class="preview-image">
					<div class="image-wrapper">
						<ng-container *ngIf="attachment">
							<ng-container
								*ngIf="
									attachment.mimeType === 'image/jpeg' ||
										attachment.mimeType === 'image/jpg' ||
										attachment.mimeType === 'image/png' ||
										attachment.mimeType === 'image/svg' ||
										attachment.mimeType === 'image/gif';
									else noImageType
								"
							>
								<img class="asset-image" [src]="attachment.url" onerror="this.src = './assets/images/placeholder/fallback.png'" />
							</ng-container>
							<ng-template #noImageType>
								<div class="flex h-full w-full items-center justify-center bg-transparent text-7xl text-gray-500">
									<fa-icon class="bg-transparent" [icon]="attachment.mimeType | mimeTypeFallback"></fa-icon>
								</div>
							</ng-template>
						</ng-container>
					</div>
				</div>
			</div>
			<div class="w-1/3">
				<div class="border-b border-solid border-gray-600 px-4 py-3">
					<p class="pb-1 text-sm text-gray-900">Dimensionen</p>
					<ng-container *ngIf="dimensions">
						<p class="font-mono text-sm leading-none text-gray-700">{{ dimensions.width }} x {{ dimensions.height }}</p>
					</ng-container>
				</div>
				<div class="border-b border-solid border-gray-600 px-4 py-3">
					<p class="pb-1 text-sm text-gray-900">Hinzugefügt am</p>
					<ng-container *ngIf="attachment">
						<p class="font-mono text-sm leading-none text-gray-700">
							{{ attachment.createdAt | date: 'dd.MM.yyyy' }}
						</p>
					</ng-container>
				</div>
				<div class="border-b border-solid border-gray-600 px-4 py-3">
					<p class="pb-1 text-sm text-gray-900">MimeType</p>
					<ng-container *ngIf="attachment">
						<p class="font-mono text-sm leading-none text-gray-700">
							<ng-container *ngIf="attachment.mimeType"><fa-icon class="mr-1 text-gray-700" [icon]="attachment.mimeType | mimeTypeFallback"></fa-icon></ng-container>
							<span class="text-gray-700">{{ attachment.mimeType }}</span>
						</p>
					</ng-container>
				</div>
				<ng-container *ngIf="attachment && attachment.partner && attachment.partner.name">
					<div class="border-b border-solid border-gray-600 px-4 py-3" appAccessControl [accessRole]="['Super Admin', 'Admin']">
						<p class="pb-1 text-sm text-gray-900">Partner</p>
						<ng-container *ngIf="attachment">
							<p class="font-mono text-sm leading-none text-gray-700">
								{{ attachment.partner.name }}
							</p>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>
