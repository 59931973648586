import { Injectable } from '@angular/core';
import slugify from 'slugify';

@Injectable({
	providedIn: 'root',
})
export class HelperService {
	constructor() {}

	// Generate slug from string
	slugify(str: string): string {
		return slugify(str);
	}

	// compare objects
	deepEqual(object1, object2): boolean {
		const keys1 = Object.keys(object1);
		const keys2 = Object.keys(object2);

		if (keys1.length !== keys2.length) {
			return false;
		}

		for (const key of keys1) {
			const val1 = object1[key];
			const val2 = object2[key];
			const areObjects = this.isObject(val1) && this.isObject(val2);
			if ((areObjects && !this.deepEqual(val1, val2)) || (!areObjects && val1 !== val2)) {
				return false;
			}
		}

		return true;
	}

	// check if object
	isObject(object): any {
		return object != null && typeof object === 'object';
	}

	dataURItoBlob(dataURI: string): Blob {
		// convert base64 to raw binary data held in a string
		// doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
		const byteString = atob(dataURI.split(',')[1]);

		// separate out the mime component
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

		// write the bytes of the string to an ArrayBuffer
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ab], { type: mimeString });
	}
}
