import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'types',
})
export class TypesPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		switch (value) {
			case 'book':
				return 'Buch';
			case 'demo':
				return 'Demo';
			case 'embed':
				return 'Embed';
			case 'keyboard':
				return 'Training und Beratung';
			case 'bibliothek':
				return 'Online Bibliothek';
			case 'fico-forum':
				return 'Fico Forum';
			case 'handelsware':
				return 'Handelsware';
			case 'virtuelle-events':
				return 'Virtuelle Events';
			case 'online-training':
				return 'Online-Training';
			case 'live-training':
				return 'Live-Training';
			case 'video':
				return 'video';
			case 'blended-learning':
				return 'Online-Training';
			default:
				return 'Sonstige';
		}
	}
}
