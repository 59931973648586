import { Component, Input, OnInit } from '@angular/core';
import { faCogs, faGrid2, faUser, faRecycle } from '@fortawesome/pro-solid-svg-icons';
import { AuthService } from '../../services/auth.service';
import { SessionQuery } from '../../session/state';
import { HotkeysService } from '../../services/hotkeys.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
	faCogs = faCogs;
	faPlm = faRecycle;
	faApps = faGrid2;
	faUser = faUser;

	@Input() title: string;

	public imageUrl: string | undefined;
	public name: string | undefined;
	public mail: string | undefined;

	constructor(
		private authService: AuthService,
		private sessionService: SessionQuery,
		private hotKeysService: HotkeysService
	) {}

	ngOnInit(): void {
		this.imageUrl = this.sessionService.getImage();
		this.name = `${this.sessionService.getGivenName() ? this.sessionService.getGivenName() + ' ' : ''}${
			this.sessionService.getFamilyName() ? this.sessionService.getFamilyName() : ''
		}`;
		this.mail = this.sessionService.getMail();
		this.hotKeysService.initHotkeys();
	}

	logout(): void {
		this.authService.logout();
	}
}
