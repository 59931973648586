import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'syncShop',
})
export class SyncShopPipe implements PipeTransform {
	transform(value: unknown, ...args: unknown[]): unknown {
		switch (value) {
			case 1:
				return 'Ja';
			default:
				return 'Nein';
		}
	}
}
