import { Injectable } from '@angular/core';
import hotkeys from 'hotkeys-js';
import { TerminalDialogComponent } from '../dialogs/terminal-dialog/terminal-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
	providedIn: 'root',
})
export class HotkeysService {
	public opened: boolean = false;

	constructor(public dialog: MatDialog) {}

	initHotkeys(): void {
		hotkeys('ctrl+o', (event, handler) => {
			event.preventDefault();
			switch (handler.key) {
				case 'ctrl+o':
					this.open();
			}
		});
	}

	open(): void {
		if (!this.opened) {
			this.opened = true;
			const dialogRef = this.dialog.open(TerminalDialogComponent, {
				maxWidth: '480px',
				width: '100%',
				panelClass: 'terminal-dialog',
			});

			dialogRef.afterClosed().subscribe(() => {
				this.opened = false;
			});
		}
	}
}
