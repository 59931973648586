<ng-container *transloco="let t">
	<footer class="bg-gray-300 pt-8">
		<div class="w-full border-b-4 border-solid border-blue-500">
			<div class="mx-auto w-full max-w-content px-4 md:px-12">
				<hr class="m-0 block h-px w-full bg-gray-600" />
				<div class="flex flex-row flex-wrap justify-start px-4 py-12">
					<div class="mb-4 w-full text-center sm:w-1/2 sm:text-left md:mb-0 md:w-1/5">
						<h2 class="mb-2 text-sm font-semibold uppercase text-gray-700">
							{{ t('UI.help') }}
						</h2>
						<nav>
							<ul class="list-none p-0 text-center sm:text-left">
								<li class="mb-2 block">
									<a class="text-sm text-gray-800 no-underline hover:text-blue-500" [href]="t('LINK.help_link')" target="_blank" rel="noopener">{{ t('UI.faq') }}</a>
								</li>
							</ul>
						</nav>
					</div>
					<div class="mb-4 w-full text-center sm:w-1/2 sm:text-left md:mb-0 md:w-1/5">
						<h2 class="mb-2 text-sm font-semibold uppercase text-gray-700">
							{{ t('UI.legals') }}
						</h2>
						<nav>
							<ul class="list-none p-0 text-center sm:text-left">
								<li class="mb-2 block">
									<a class="text-sm text-gray-800 no-underline hover:text-blue-500" routerLink="/imprint">{{ t('UI.imprint') }}</a>
								</li>
								<li class="mb-2 block">
									<a class="text-sm text-gray-800 no-underline hover:text-blue-500" routerLink="/privacy">{{ t('UI.privacy') }}</a>
								</li>
							</ul>
						</nav>
					</div>
					<div class="mb-4 w-full text-center sm:w-1/2 sm:text-left md:mb-0 md:w-1/5">
						<h2 class="mb-2 text-sm font-semibold uppercase text-gray-700">
							{{ t('UI.links') }}
						</h2>
						<nav>
							<ul class="list-none p-0 text-center sm:text-left">
								<li class="mb-2 block">
									<a class="text-sm text-gray-800 no-underline hover:text-blue-500" href="https://et.training/content-library" target="_blank" rel="noopener">{{
										t('UI.content_library')
									}}</a>
								</li>
								<li class="mb-2 block">
									<a class="text-sm text-gray-800 no-underline hover:text-blue-500" [href]="t('LINK.single_license')" target="_blank" rel="noopener">{{ t('UI.subscription') }}</a>
								</li>
								<li class="mb-2 block">
									<a class="text-sm text-gray-800 no-underline hover:text-blue-500" [href]="t('LINK.company_access')" target="_blank" rel="noopener">{{
										t('UI.company_access')
									}}</a>
								</li>
							</ul>
						</nav>
					</div>
					<div class="flex w-full flex-row flex-wrap justify-center sm:w-1/2 sm:justify-end md:w-2/5">
						<div class="flex w-full justify-center sm:justify-end">
							<app-logo></app-logo>
						</div>
						<p class="mt-2 w-full text-center text-xs text-gray-800 sm:text-right">© 2017 - {{ actualYear }} Espresso Tutorials GmbH</p>
					</div>
				</div>
				<div class="mb-8 flex w-full flex-row flex-wrap px-4">
					<a
						class="mr-4 w-full max-w-app no-underline opacity-50 hover:opacity-100"
						href="https://itunes.apple.com/app/ebook-library-2/id1456516427"
						target="_blank"
						rel="noopener"
					>
						<img class="mx-auto block h-auto w-full" [src]="'assets/images/ios.png'" alt="iOS App - et.training" />
					</a>
					<a
						class="w-full max-w-app no-underline opacity-50 hover:opacity-100"
						href="https://play.google.com/store/apps/details?id=com.ettraining.app"
						target="_blank"
						rel="noopener"
					>
						<img class="mx-auto block h-auto w-full" [src]="'assets/images/android.png'" alt="Android App - et.training" />
					</a>
				</div>
			</div>
		</div>
	</footer>
</ng-container>
