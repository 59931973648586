<ng-container *transloco="let t">
	<header class="sticky top-0 z-50 w-full border-b border-solid border-gray-700 bg-gray-1000 py-4 pl-6 pr-4 lg:px-8">
		<div class="flex flex-row flex-wrap items-center justify-between">
			<div class="flex flex-row items-center">
				<fa-icon class="mr-4 text-2xl text-blue-500" [icon]="faPlm"></fa-icon>
				<span class="hidden text-sm font-semibold leading-none text-white md:block">product lifecycle<br />management</span>
				<span class="block text-sm font-semibold leading-none text-white md:hidden">PLM</span>
			</div>
			<h2 class="font-bold text-white">{{ title }}</h2>
			<div class="flex flex-row flex-wrap items-center">
				<div appAccessControl [accessRole]="['Super Admin', 'Admin']">
					<app-terminal></app-terminal>
				</div>
				<button
					appAccessControl
					[accessRole]="['Super Admin', 'Admin']"
					[matMenuTriggerFor]="layoutMenu"
					title="Seiten-Schnell-Auswahl"
					class="flex h-9 w-9 appearance-none items-center justify-center rounded-lg border-0 bg-transparent leading-none text-white transition-colors duration-200 ease-in-out hover:bg-gray-700"
				>
					<fa-icon [icon]="faApps"></fa-icon>
				</button>
				<mat-menu #layoutMenu="matMenu" xPosition="before" class="settings-mat-menu">
					<app-apps></app-apps>
				</mat-menu>
				<a
					routerLink="/dashboard/settings"
					class="flex h-9 w-9 appearance-none items-center justify-center rounded-lg border-0 bg-transparent leading-none text-white transition-colors duration-200 ease-in-out hover:bg-gray-700"
					title="Einstellungen"
				>
					<fa-icon [icon]="faCogs"></fa-icon>
				</a>
				<button [matMenuTriggerFor]="metaMenu" title="Weitere Informationen" class="ml-1 flex appearance-none items-center text-gray-650 focus:outline-none">
					<div class="mat-menu-image flex h-7 w-7 items-end justify-center overflow-hidden rounded-full border-solid border-default border-transparent bg-gray-400 text-2xl">
						<ng-container *ngIf="imageUrl; else noImage">
							<img
								class="h-6 w-6 rounded-full object-cover object-center xl:h-8 xl:w-8"
								[src]="imageUrl"
								onerror="this.src = './assets/images/placeholder/fallback.png'"
								onerror="this.src = './assets/images/placeholder/fallback.png'"
							/>
						</ng-container>
						<ng-template #noImage>
							<fa-icon class="-mb-3 xl:-mb-2" [icon]="faUser"></fa-icon>
						</ng-template>
					</div>
				</button>
				<mat-menu #metaMenu="matMenu" xPosition="before" class="settings-mat-menu">
					<div class="">
						<div class="border-b border-solid border-gray-650 px-6 py-4">
							<p class="mb-2 text-sm font-semibold leading-none text-white" *ngIf="name">
								{{ name }}
							</p>
							<p class="text-xs font-medium leading-none text-gray-500" *ngIf="mail">
								{{ mail }}
							</p>
						</div>
						<nav class="pt-3">
							<ul>
								<li appAccessControl [accessRole]="['Super Admin', 'Admin']">
									<a
										routerLink="/dashboard/help"
										class="block w-full appearance-none border-0 bg-transparent px-6 py-3 text-left text-sm font-medium leading-none text-white transition-colors duration-200 ease-in-out hover:bg-blue-600"
										title="{{ t('UI.help') }}"
									>
										{{ t('UI.help') }}
									</a>
								</li>
								<li>
									<button
										class="w-full appearance-none border-0 bg-transparent px-6 py-3 text-left text-sm font-medium leading-none text-white transition-colors duration-200 ease-in-out hover:bg-blue-600"
										title="{{ t('UI.logout') }}"
										(click)="logout()"
									>
										{{ t('UI.logout') }}
									</button>
								</li>
							</ul>
						</nav>
					</div>
				</mat-menu>
			</div>
		</div>
	</header>
</ng-container>
