<div class="relative max-h-modal sm:w-full sm:max-w-lg">
	<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
		<div class="sticky top-0 z-10 w-full bg-white">
			<h3 class="mb-2 text-lg font-medium leading-6 text-gray-900" id="modal-title">Produkte wählen</h3>
			<input
				#search
				type="text"
				class="mb-3 block w-full appearance-none rounded border border-gray-500 bg-gray-300 px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
				placeholder="Medien suchen"
			/>
			<div class="flex w-full flex-row items-center bg-gray-200">
				<div class="imge-content flex flex-row items-center">
					<div class="w-4 text-xs leading-tight text-gray-650"></div>
					<div class="w-auto py-2 pl-1 pr-1 text-xs leading-tight text-gray-650">ID</div>
					<div class="w-auto p-2 text-xs leading-tight text-gray-650">Name</div>
				</div>
			</div>
		</div>
		<div class="relative max-h-modalInner overflow-y-auto overflow-x-hidden">
			<cdk-virtual-scroll-viewport itemSize="50" class="prods list-none" *ngIf="products">
				<li *cdkVirtualFor="let product of products | searchList: searchStr; let idx = index" class="prods__item my-2" #idx>
					<label class="mb-2 cursor-pointer whitespace-nowrap text-xs" [for]="product.id">
						<input
							class="focus:border-indigo-300 mr-2 rounded border-gray-600 text-blue-500 outline-none focus:outline-none focus:ring-0 focus:ring-transparent"
							type="checkbox"
							[(ngModel)]="product.checked"
							[value]="product.title"
							(change)="updateProds()"
							[id]="product.id"
						/>
						{{ product.title }}
					</label>
					<ng-container *ngIf="showSelection && product.checked">
						<div class="mt-1 flex flex-row items-center px-4">
							<input
								class="white block w-full appearance-none rounded border border-gray-500 px-2 py-2 text-xs leading-tight text-gray-700 placeholder-gray-600 focus:border-gray-500 focus:bg-white focus:outline-none"
								placeholder="Kapitel/Abschnitt eintragen"
								type="text"
								[(ngModel)]="product.bookmark"
								[value]="product.bookmark"
								[id]="'bookmark-' + product.id"
							/>
							<button
								class="appearance-none p-2 leading-none text-blue-500 transition-colors duration-200 ease-in-out hover:text-orange-500"
								title="Kapitel/Abschnitt speichern"
								(click)="addSection(product.bookmark, product.id)"
							>
								<fa-icon [icon]="faAdd"></fa-icon>
							</button>
						</div>
					</ng-container>
				</li>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>
	<div class="sticky bottom-0 bg-white px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
		<button
			type="button"
			class="inline-flex w-full justify-center rounded-md border border-transparent bg-red-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
			(click)="onSave()"
		>
			Produkte hinzufügen
		</button>
		<button
			type="button"
			class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
			(click)="onClose()"
		>
			Abbrechen
		</button>
	</div>
</div>
