<div class="relative max-h-modal sm:w-full sm:max-w-lg">
	<form *ngIf="form" [formGroup]="form" (ngSubmit)="submitForm()" novalidat>
		<div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
			<div *ngIf="error" class="absolute left-0 right-0 top-0 rounded border border-solid border-red-500 bg-red-400 px-3 py-2 text-xs font-semibold text-white">
				<div class="mb-1 flex flex-row flex-wrap items-center">
					<fa-icon class="mr-1" [icon]="faBug"></fa-icon>
					<span class="font-semibold">Fehler:</span>
				</div>
				<p>{{ error.message }}</p>
				<code class="font-normal text-gray-300" *ngIf="error.errors">
					{{ error.errors | json }}
				</code>
			</div>

			<div *ngIf="isValidFormSubmitted" class="border-red-600 absolute left-0 right-0 top-0 rounded border border-solid bg-green-500 px-3 py-2 text-xs font-semibold text-white">
				<div class="flex flex-row flex-wrap items-center">
					<fa-icon class="mr-1" [icon]="faSuccess"></fa-icon>
					<span class="font-semibold">Ordner erfolgreich angelegt!</span>
				</div>
			</div>

			<div class="sticky top-0 z-10 w-full bg-white">
				<h3 class="mb-2 text-lg font-medium leading-6 text-gray-900" id="modal-title">Ordner anlegen</h3>
			</div>
			<div class="relative max-h-modalInner overflow-y-auto overflow-x-hidden">
				<div class="mb-6 w-full">
					<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700" for="name"> Name<sup class="text-red-500">*</sup> </label>
					<input
						formControlName="name"
						class="mb-3 block w-full appearance-none rounded-lg border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 outline-none focus:outline-none"
						id="name"
						type="text"
						placeholder="Bildname eintragen"
					/>
					<div
						*ngIf="name.errors && isValidFormSubmitted != null && !isValidFormSubmitted"
						class="rounded border border-solid border-red-500 bg-red-400 px-2 py-1 text-xs font-semibold text-white"
					>
						Bitte überprüfen Sie Ihre Eingabe.
					</div>
				</div>
				<div class="mb-6 w-full">
					<div appAccessControl [accessRole]="['Super Admin', 'Admin']">
						<label class="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700" for="partner"> Partner </label>
						<select
							formControlName="partner"
							id="partner"
							class="mb-3 block w-full cursor-pointer appearance-none rounded-lg border border-solid border-gray-400 bg-white px-4 py-3 leading-tight text-gray-700 placeholder-gray-600 outline-none focus:outline-none"
						>
							<option value="" class="opacity-75" selected>Partner wählen</option>
							<ng-container *ngIf="partner">
								<option [value]="part.id" *ngFor="let part of partner">
									{{ part.name }}
								</option>
							</ng-container>
						</select>
					</div>
				</div>
			</div>
		</div>
		<div class="sticky bottom-0 bg-white px-4 pb-4 pt-3 sm:flex sm:flex-row-reverse sm:px-6 sm:pb-6">
			<button
				type="submit"
				class="inline-flex w-full items-center justify-center rounded-md border border-transparent bg-blue-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
			>
				Ordner anlegen
			</button>
			<div
				type="button"
				class="mt-3 inline-flex w-full cursor-pointer justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-500 focus:outline-none sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
				(click)="onClose()"
			>
				Abbrechen
			</div>
		</div>
	</form>
</div>
